import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next'
import StackGrid from "react-stack-grid";
import {withSize} from "react-sizeme";
import FsLightbox from "fslightbox-react";
import dots from "../../images/dots-icon.svg";
import {Button, Menu, MenuItem} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import documentPlaceholder from "../../images/document-placeholder.svg";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import spinner from "../../images/spinner.svg";
import {useMediaQuery} from "react-responsive";
import favouriteIcon from "../../images/favourite-icon.svg";
import Avatar from "@material-ui/core/Avatar";
import baseMaleAvatar from "../../images/male-icon.svg";
import baseFemaleAvatar from "../../images/female-icon.svg";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import DialogSmall from "../components/DialogSmall.js";
import baseCompanyAvatar from "../../images/company-avatar.svg";
import CropDialog from "../components/CropDialog.js";
import themeContext from "../components/ThemeContext.js";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const useStyles = makeStyles((theme) => ({
    backDrop: {
        backdropFilter: "blur(16px)",
        backgroundColor: themeContext.color.shadowBlack,
        opacity: 1
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    imageList: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    personAvatar: {
        width: "28px",
        height: "28px",
    },
}));

function PersonalDocumentsList({size}) {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        t,
        i18n
    } = useTranslation();
    const params = useParams();
    const documentGroupId = params.documentGroupId;
    const getGroupDocumentByIdUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group`;
    const attachDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/attachDocument`;
    const deleteDocumentFromGroupUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/removeDocument`;
    const setFavouriteDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/saveFavorite`;
    const removeFavouriteDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/removeFavorite`;
    const personInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/person`;
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [images, setImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);
    //const [toggler, setToggler] = useState(false);
    const [openMenu, setOpenMenu] = useState(null);
    const [imageIndex, setImageIndex] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState(-1);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [cropper, setCropper] = useState(null);
    const [serviceError, setServiceError] = useState(false);
    const [openPhotoLimitsDialog, setOpenPhotoLimitsDialog] = useState(false);
    const cropperRef = useRef(null);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'});
    const [isLoadingPerson, setIsLoadingPerson] = useState(false);
    const [person, setPerson] = useState([]);
    const [fav, setFav] = useState(-1);
    const [openSizeExceededDialog, setOpenSizeExceededDialog] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(index) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index
        });
    }

    function reload() {
        window.location.reload();
    }

    useEffect(() => {
        if (navigator.onLine) {
            getDocumentGroupById();
        }
    }, []);

    const turnBack = () => {
        return navigate(-1);
    }

    const getDocumentGroupById = () => {
        setIsLoadingData(true)
        setImages([])
        setImagesUrl([])
        fetch(`${getGroupDocumentByIdUrl}/${documentGroupId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let lengthDocs = Object.keys(data.documents).length;
            setDocuments(data)
            data.documents.map((doc, index) => {
                if (doc.isFavorite === true) {
                    setFav(index)
                }
            })
            getPerson(data.personId)
            if (lengthDocs !== 0) {
                for (let i = 0; i < lengthDocs; i++) {
                    setImages(current => [...current, data.documents[i]])
                    setImagesUrl(current => [...current, data.documents[i].document])
                }
            }
            setIsLoadingData(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const getLightDocumentGroupById = () => {
        fetch(`${getGroupDocumentByIdUrl}/${documentGroupId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setDocuments(data)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setOpenMenu(null);
    }

    const handleChangeImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    async function attachDocument(e) {
        if (e.target.files.length !== 0) {
            let file = e.target.files[0];
            const fileType = file.type;
            //const fileName = file.name;
            const randomName = Math.floor((Math.random() * 100000000) + 1)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setIsLoadingImg(true);
                let base64_fullString = reader.result.split(",");
                let base64_string = base64_fullString[1];
                fetch(attachDocumentUrl, {
                    method: 'POST',
                    body: JSON.stringify({
                        groupId: documentGroupId,
                        documents: [
                            {
                                name: randomName,
                                mimeType: fileType,
                                base64: base64_string,
                            }
                        ]
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'Access-Control-Allow-Origin': true
                        //'Access-Control-Allow-Origin': 'no-cors'
                    },
                }).then((res) => {
                    if (res.ok) {
                        //alert upload completato
                        return res.json();
                    }
                }).then((res) => {
                    setIsLoadingImg(false);
                    setOpenMenu(false);
                    getDocumentGroupById();
                }).catch(() => {
                    setServiceError(true)
                });
            };
            reader.onerror = error => {
                console.error(error)
            };
        }
    }

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCroppedCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            const randomName = Math.floor((Math.random() * 100000000) + 1)
            let split1 = cropImage.split(":");
            let split2 = split1[1].split(";");
            let mimetype = split2[0];
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            setIsLoadingImg(true);
            fetch(attachDocumentUrl, {
                method: 'POST',
                body: JSON.stringify({
                    groupId: documentGroupId,
                    documents: [
                        {
                            name: randomName,
                            mimeType: mimetype,
                            base64: base64_string,
                        }
                    ]
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': true
                    //'Access-Control-Allow-Origin': 'no-cors'
                },
            }).then((res) => {
                if (res.ok) {
                    //alert upload completato
                    return res.json();
                }
            }).then((res) => {
                setIsLoadingImg(false);
                setOpenCropDialog(false);
                getDocumentGroupById();
            }).catch(() => {
                setServiceError(true)
            });
        }
    }

    /*
     const onClickImage = (index) => {
        setImageIndex(index)
        setToggler(!toggler)
    }
     */

    /*
        const handleUpdateGroup = (event) => {
            setIsLoadingData(true)
            setImages([])
            let body = null;
            if(event === 'personId'){
                body = JSON.stringify({
                    id: documentGroupId,
                    personId: "",
                    type: "",
                })
            }
            if(event === 'type'){
                body = JSON.stringify({
                    id: documentGroupId,
                    personId: "",
                    type: "",
                })
            }
            fetch(`${getGroupDocumentByIdUrl}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: body,
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setOpenMenu(false);
                getDocumentGroupById();
                setIsLoadingData(false)
            });
        }

     */

    const handleDeleteGroup = () => {
        fetch(`${getGroupDocumentByIdUrl}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: documentGroupId,
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            navigate(-1)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const handleDeleteImage = (index) => {
        fetch(`${deleteDocumentFromGroupUrl}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                groupId: documentGroupId,
                documentIndex: index
            }),
        }).then((response) => {

            return response.json();
        }).then((data) => {
            if (fav === index) {
                setFav(-1)
            }
            getDocumentGroupById();
            setOpenDialog2(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const openDeleteImageDialog = (index) => {
        setIndexToDelete(index);
        setOpenDialog2(true)
    }

    const handleOnPendingImage = async (e) => {
        try {
            e.preventDefault();
            let file = e.target.files[0];
            if (file && file.size > 20000000) {
                throw t('Maximum upload size exceeded');
            }
            const imageResized = await resizeFile(file)
            setOnPendingImage(imageResized);
            setOpenCropDialog(true)
        } catch (err) {
            console.log(err)
            setOpenSizeExceededDialog(true)
        }
    };

    const attachImageActions = (documentType, imagesLength) => {
        if ((documentType === "1" || documentType === "2" || documents.type === "4") && imagesLength >= 2) {
            setOpenPhotoLimitsDialog(true);
        } else if ((documentType === "3" || documentType === "5") && imagesLength >= 8) {
            setOpenPhotoLimitsDialog(true);
        } else {
            handleChangeImage();
        }
    }

    const setFavouriteDocument = (document, index) => {
        fetch(`${setFavouriteDocumentUrl}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                personId: documents.personId,
                documentId: document.documentId
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            getLightDocumentGroupById();
            setFav(index)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const removeFavouriteDocument = (document, index) => {
        fetch(`${removeFavouriteDocumentUrl}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                personId: documents.personId,
                documentId: document.documentId
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            getLightDocumentGroupById();
            setFav(-1)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPerson(personId) {
        setIsLoadingPerson(true)
        fetch(`${personInfoUrl}/${personId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setPerson(data);
            }
            setIsLoadingPerson(false)
        }).catch(() => {
            setServiceError(true)
        })
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Row className="nav-top container" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2"></h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Row className="nav-top-shadow container col-list" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingBottom': '20px',
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col className="col-2 float-left">
                            <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                                 height="27px"
                                 alt="back"/>
                        </Col>
                        <Col className="col-2 float-left">
                            {isLoadingPerson && <img src={spinner} className="spinner-img" alt="spinner"/>}
                            {!isLoadingPerson && person.image === null && person.gender === 'M' &&
                                <Avatar className={classes.personAvatar}
                                        src={baseMaleAvatar}
                                        alt="avatar-image"/>}
                            {!isLoadingPerson && person.image === null && person.gender === 'F' &&
                                <Avatar className={classes.personAvatar}
                                        src={baseFemaleAvatar}
                                        alt="avatar-image"/>}
                            {!isLoadingPerson && person.image === null && person.gender === 'C' &&
                                <Avatar className={classes.personAvatar}
                                        src={baseCompanyAvatar}
                                        alt="avatar-image"/>}
                            {!isLoadingPerson && person.image === null && person.gender === null &&
                                <Avatar className={classes.personAvatar}
                                        src={baseMaleAvatar}
                                        alt="avatar-image"/>}
                            {!isLoadingPerson && person.image &&
                                <Avatar className={classes.personAvatar} src={person.image}
                                        alt="avatar-image"/>}
                        </Col>
                        <Col className="col-4">
                            <h6 className="header-title ellipsis-1-v2">
                                {isLoadingData &&
                                    <img src={spinner} className="spinner-img" alt="spinner"/>
                                }
                                {
                                    !isLoadingData && documents.type === "1" &&
                                    <span>{t('documentsList.title-document-1')}</span>
                                }
                                {
                                    !isLoadingData && documents.type === "2" &&
                                    <span>{t('documentsList.title-document-2')}</span>
                                }
                                {
                                    !isLoadingData && documents.type === "3" &&
                                    <span>{t('documentsList.title-document-3')}</span>
                                }
                                {
                                    !isLoadingData && documents.type === "4" &&
                                    <span>{t('documentsList.title-document-4')}</span>
                                }
                                {
                                    !isLoadingData && documents.type === "5" &&
                                    <span>{t('documentsList.title-document-5')}</span>
                                }
                            </h6>
                        </Col>
                        <Col className="col-2"></Col>
                        <Col className="col-2">
                            <img src={dots} onClick={handleOpenMenu}
                                 className="nav-top-logo float-right cursor-pointer" alt="dots" height="27px"/>
                            <Menu
                                id="simple-menu"
                                anchorEl={openMenu}
                                keepMounted
                                open={Boolean(openMenu)}
                                onClose={handleCloseMenu}
                                className="menu">
                                <MenuItem className="menu-item" style={{'color': themeContext.color.red}}
                                          onClick={() => setOpenDialog(true)}>
                                    {t('documentsList.menu-delete-document')}
                                </MenuItem>
                            </Menu>
                        </Col>
                    </Row>
                    <Container className="top-of-the-preferences"></Container>
                    {
                        isLoadingData &&
                        <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                    }
                    {
                        !isLoadingData && images.length === 0 &&
                        <Container>
                            <Row style={{
                                'marginTop': '43.5px',
                                'marginBottom': '44.5px'
                            }}>
                                <Col className="pl-5 pr-5">
                        <span style={{
                            'fontSize': '18px',
                            'color': themeContext.color.mainActive,
                            'fontWeight': 'bold'
                        }}>{t('personalDocuments.placeholder-text')}</span>
                                </Col>
                            </Row>
                            <Row>
                                <img src={documentPlaceholder} alt="document-placeholder" width="100%"
                                     style={{'maxHeight': '500px'}}/>
                            </Row>
                        </Container>
                    }
                    {
                        !isLoadingData &&
                        <div className="mt-4 flex-wrap justify-content-between" style={{
                            display: "flex",
                            gap: "24px",
                            paddingBottom: "120px"
                        }}>
                            {
                                images.map((item, index) => (
                                    <div key={index}
                                         className={isDesktop ? "div-grid-full-desktop" : "div-grid-full-mobile"}>
                                        <img src={item.document} className={'image-grid-full'} alt={`image-${index}`}
                                             onClick={() => {
                                                 openLightboxOnSlide(index);
                                             }}/>
                                        <button
                                            className={fav !== index ? "not-favourite-button" : "favourite-button"}
                                            onClick={() => fav !== index ? setFavouriteDocument(item, index) : removeFavouriteDocument(item, index)}>
                                            <img src={favouriteIcon} alt="favourite-icon"
                                                 width="20px"/>
                                        </button>
                                        <button className="delete-small delete-small-absolute"
                                                onClick={() => openDeleteImageDialog(index)}>{t('personalDocuments.delete-button')}</button>
                                    </div>
                                ))
                            }
                            <FsLightbox
                                toggler={lightboxController.toggler}
                                sources={imagesUrl}
                                type="image"
                                sourceIndex={lightboxController.slide}
                                key={0}
                            />
                        </div>
                    }
                </Container>
                <Container className="fixed-bottom"
                           style={{'paddingBottom': "40px"}}>
                    <Row>
                        <Col>
                            <Button id="attachImage"
                                    className="btn-custom"
                                    variant="outlined"
                                    size="large"
                                    style={{color: themeContext.color.white, textTransform: 'none'}} type="submit"
                                    onClick={() => attachImageActions(documents.type, images.length)}>
                                {t('documentsList.button')}
                            </Button>
                            <input hidden id="image-file" type="file" onChange={handleOnPendingImage} accept="image/*"/>
                        </Col>
                    </Row>
                </Container>
                <DialogDoubleAction open={openDialog}
                                    title={t('documentsList.modal-title')}
                                    description={t('documentsList.modal-description')}
                                    handleClose={() => setOpenDialog(false)}
                                    handleNo={() => setOpenDialog(false)}
                                    handleYes={handleDeleteGroup}
                                    buttonYesText={t('documentsList.modal-confirm-button')}
                                    buttonNoText={t('documentsList.modal-refuse-button')}/>
                <DialogSmall open={openPhotoLimitsDialog}
                             title={t('documentsList.limit-photo-modal-title')}
                             description={t('documentsList.limit-photo-modal-description')}
                             handleClose={() => setOpenPhotoLimitsDialog(false)}
                             buttonText={t('documentsList.limit-photo-modal-button')}/>
                <DialogDoubleAction open={openDialog2}
                                    title={t('documentsList.modal-title-2')}
                                    description={t('documentsList.modal-description-2')}
                                    handleClose={() => setOpenDialog2(false)} handleNo={() => setOpenDialog2(false)}
                                    handleYes={() => handleDeleteImage(indexToDelete)}
                                    buttonYesText={t('documentsList.modal-confirm-button')}
                                    buttonNoText={t('documentsList.modal-refuse-button')}/>
                <CropDialog open={openCropDialog}
                            handleAttach={attachCroppedImage}
                            handleClose={() => {
                                setOpenCropDialog(false)
                                setIsLoadingImg(false)
                            }}
                            isLoading={isLoadingImg}
                            onPendingImage={onPendingImage}
                            cropperRef={cropperRef}
                            setCropper={(data) => setCropper(data)}/>
                <DialogSmall open={openSizeExceededDialog} title={t('Maximum upload size exceeded')}
                             buttonText={t('dialog.button-ok')}
                             handleClose={() => {
                                 setOpenSizeExceededDialog(false)
                             }} description={t('The maximum image upload size is 20mb')}/>
            </Fragment>
    )
}

export default withSize()(PersonalDocumentsList)