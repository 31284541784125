import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useContext} from "react";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";

export default function SPIDAuthorize() {
    const [searchParam, setSearchParam] = useSearchParams();
    const sessionId = searchParam.get("sessionid");
    const sessionKey = searchParam.get("sessionkey");
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();

    const url = `${process.env.REACT_APP_BASE_URL}/management/spid/namirialAuthorize`;
    const firstStepUrl = `${process.env.REACT_APP_BASE_URL}/management/spid/namirialAuthorize`;
    const getUsersByFiscalCodeUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/login/verify/cf`;

    React.useEffect(
        () => {
            fetch(firstStepUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sessionKey: sessionKey,
                    sessionId: sessionId,
                }),
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        sessionStorage.setItem('spid-not-enable', true)
                        navigate('/login');
                    } else if (response.status === 400) {
                        return response.text().then(text => {
                            throw new Error(text)
                        })
                    } else if (response.status === 500) {
                        sessionStorage.setItem('spid-not-available', true)
                        navigate('/login');
                    }
                }
            }).catch((err) => {
                sessionStorage.setItem('spid-bad-request', err.message);
                navigate('/login');
            }).then((res) => {
                if (res) {
                    if (res.isLegacy === true) {
                        postRedirect(`${process.env.REACT_APP_MY_WIDE_URL}`, {
                            accessToken: res.accessToken,
                            expiresIn: res.expiresIn,
                            isLegacy: res.isLegacy,
                            pinCode: res.pinCode,
                            refreshExpiresIn: res.pinCode,
                            refreshToken: res.refreshToken,
                            userId: res.userId
                        })
                    } else {
                        const mail = res.mail
                        const cf = res.cf
                        // find users with fiscal code
                        sessionStorage.setItem('spid-or-cie-token', res.token)
                        fetch(`${getUsersByFiscalCodeUrl}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                cf: cf,
                                mail: mail
                            }),
                        }).then((response) => {
                            if (response.ok) {
                                return response.json();
                            }
                        }).then((res) => {
                            if(res.userList && res.userList.length === 0){
                                if(res.exist === true) {
                                    postRedirect(`${process.env.REACT_APP_MY_WIDE_URL}`, {
                                        accessToken: res.token,
                                        expiresIn:'',
                                        isLegacy:'',
                                        pinCode: '',
                                        refreshExpiresIn: '',
                                        refreshToken: '',
                                        userId: '',
                                        mail: mail,
                                        cf: cf,
                                    })
                                } else {
                                    // sessionStorage.setItem('spid-bad-request', 'generic-error');
                                    navigate('/login');
                                }
                            }else{
                                sessionStorage.setItem('spid-usersList', JSON.stringify(res.userList))
                                navigate('/login');
                            }
                        }).catch(err => {
                            sessionStorage.setItem('spid-bad-request', err.message);
                            navigate('/login');
                        });
                    }
                }
            });

        }, []
    );

    function postRedirect(path, params, method = 'post') {
        const form = document.createElement('form');
        form.method = method;
        form.action = path;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];
                form.appendChild(hiddenField);
            }
        }
        console.log(params)
        document.body.appendChild(form);
        form.submit();
    }

    return null;
}