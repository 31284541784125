import styled, {keyframes} from 'styled-components';

const ItemContent = styled.div`
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
  border-style: solid;
  border-color: #ffffff;
  border-width: 12px;
  border-top-width: 6px;
  border-bottom-width: 6px;
  color: #494B54;
  user-select: none;
`;

/*padding: 8px;*/
const ActionContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 11px;
  font-weight: 500;
  box-sizing: border-box;
  color: #eee;
  user-select: none;
`;

const Avatar = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-right: 8px;
  user-drag: none;
  user-select: none;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  padding: 0 8px;
`;

const ItemColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const ItemColumnCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ItemNameLine = styled.span`
  width: auto;
  text-align: left;
`;

const ItemInfoLine = styled.span`
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  padding-top: 8px;
`;

const Header = styled.header`
  position: fixed;
`;

function blinkingEffect() {
    return keyframes`
    50% {
      opacity: 0;
    }
  `;
}

export {
    ActionContent,
    Avatar,
    ItemColumn,
    ItemColumnCentered,
    ItemContent,
    ItemInfoLine,
    ItemNameLine,
    ItemRow,
    Header,
    blinkingEffect
};
