import React, {useContext} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom'
import AuthContextMiddleware from '../middleware/AuthContextMiddleware.js';
import jwt_decode from "jwt-decode";

export default function LoginProducer() {

    const navigate = useNavigate();
    const authCtx = useContext(AuthContextMiddleware);
    const refreshTokenUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/refresh`;
    const changeStatusUrl = `${process.env.REACT_APP_BASE_URL}/management/profile/changeAcceptedStatus`;
    const [searchParam, setSearchParam] = useSearchParams();

    React.useEffect(
        () => {
            fetch(refreshTokenUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    refreshToken: searchParam.get("token")
                }),
            }).then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    if (res.status === 401) {
                        return res.json().then((data) => {
                            let errorMessage = '401';
                            if (data && data.error && data.error.message) {
                                errorMessage = data.error.message;
                            }
                            throw new Error(errorMessage);
                        });
                    } else {
                        return res.json().then((data) => {
                            let errorMessage = '500';
                            if (data && data.error && data.error.message) {
                                errorMessage = data.error.message;
                            }
                            throw new Error(errorMessage);
                        });
                    }
                }
            }).then((data) => {
                if (data.isLegacy === true) {
                    postRedirect(`${process.env.REACT_APP_MY_WIDE_URL}`, {
                        accessToken: data.accessToken,
                        expiresIn: data.expiresIn,
                        isLegacy: data.isLegacy,
                        pinCode: data.pinCode,
                        refreshExpiresIn: data.pinCode,
                        refreshToken: data.refreshToken,
                        userId: data.userId
                    })
                } else {
                    let decoded = jwt_decode(data.accessToken);
                    if (localStorage.getItem('username-wide')) {
                        let prevUsername = localStorage.getItem('username-wide');
                        if (prevUsername !== decoded.email) {
                            sessionStorage.clear();
                            localStorage.removeItem('webauthn')
                        }
                    }
                    localStorage.setItem('username-wide', decoded.email);
                    sessionStorage.removeItem('login_error');
                    authCtx.login(data.accessToken,data.refreshToken, data.pinCode);
                    fetch(changeStatusUrl, {
                        method: 'PATCH',
                        headers: {
                            'Authorization': `Bearer ${data.accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => {
                        if (localStorage.getItem('people')) {
                            navigate('/settings/people');
                            localStorage.removeItem('people')
                        } else {
                            navigate('/dashboard');
                        }
                    })
                }
            }).catch((e) => {
                navigate('/login')
            });

        }, []
    );

    function postRedirect(path, params, method = 'post') {
        const form = document.createElement('form');
        form.method = method;
        form.action = path;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];
                form.appendChild(hiddenField);
            }
        }
        console.log(params)
        document.body.appendChild(form);
        form.submit();
    }

    return null;
}
