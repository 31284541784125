import * as idb from "idb";

export default function idbDev() {
    idb.openDB('appDB', 1, {
        upgrade(upgradeDb) {
            if (!upgradeDb.objectStoreNames.contains('token')) {
                upgradeDb.createObjectStore('token', {keyPath: 'key'});
            }
            if (!upgradeDb.objectStoreNames.contains('pin')) {
                upgradeDb.createObjectStore('pin', {keyPath: 'key'});
            }
            if (!upgradeDb.objectStoreNames.contains('profileInfo')) {
                upgradeDb.createObjectStore('profileInfo', {keyPath: 'key'});
            }
            if (!upgradeDb.objectStoreNames.contains('people')) {
                upgradeDb.createObjectStore('people', {keyPath: 'key'});
            }
            if (!upgradeDb.objectStoreNames.contains('notifications')) {
                upgradeDb.createObjectStore('notifications', {keyPath: 'key'});
            }
            if (!upgradeDb.objectStoreNames.contains('notificationsArchived')) {
                upgradeDb.createObjectStore('notificationsArchived', {keyPath: 'key'});
            }
            if (!upgradeDb.objectStoreNames.contains('notificationDetails')) {
                upgradeDb.createObjectStore('notificationDetails', {keyPath: 'key'});
            }
            if (!upgradeDb.objectStoreNames.contains('personalPolicies')) {
                upgradeDb.createObjectStore('personalPolicies', {keyPath: 'key'});
            }
            if (!upgradeDb.objectStoreNames.contains('peoplePolicies')) {
                upgradeDb.createObjectStore('peoplePolicies', {keyPath: 'key'});
            }
            if (!upgradeDb.objectStoreNames.contains('personalClaims')) {
                upgradeDb.createObjectStore('personalClaims', {keyPath: 'key'});
            }
            if (!upgradeDb.objectStoreNames.contains('peopleClaims')) {
                upgradeDb.createObjectStore('peopleClaims', {keyPath: 'key'});
            }
            if (!upgradeDb.objectStoreNames.contains('policyDetails')) {
                upgradeDb.createObjectStore('policyDetails', {keyPath: 'key'});
            }
            if (!upgradeDb.objectStoreNames.contains('accountExec')) {
                upgradeDb.createObjectStore('accountExec', {keyPath: 'key'});
            }
        }
    }).then();
}