import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import themeContext from "./ThemeContext.js";

const useStyles = makeStyles((theme) => ({
    backDrop: {
        backdropFilter: "blur(16px)",
        backgroundColor: themeContext.color.shadowBlack,
        opacity: 1
    },
    // unused class
    /*dialog: {
        color: theme.palette.primary.neutral600,
        padding: '24px'
    },*/
    dialogTitle: {
        textAlign: 'left',
        font: 'normal normal 700 20px/28px Inter',
        paddingBottom: '24px'
    },
    dialogDescription: {
        textAlign: 'left',
        font: 'normal normal normal 14px/140% Inter',
        paddingBottom: '24px'
    },
    dialogAction: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    dialogButton: {
        textTransform: 'none',
        fontSize: '16px'
    },
}));

const DialogDoubleAction = ({
                                open,
                                handleClose,
                                title,
                                description,
                                buttonYesText,
                                handleYes,
                                buttonNoText,
                                handleNo,
                                deleteAction
                            }) => {
    const classes = useStyles();
    return (
        <Dialog
            BackdropProps={{classes: {root: classes.backDrop}}}
            PaperProps={{style: {color: themeContext.color.neutral600, padding: '24px'}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <h6 className={classes.dialogTitle}>{title}</h6>
            <h6 className={classes.dialogDescription}>{description}</h6>
            <div className={classes.dialogAction}>
                <Button onClick={handleNo} className={classes.dialogButton} style={{color: themeContext.color.neutral200}}>
                    {buttonNoText}
                </Button>
                {deleteAction ?
                    <Button onClick={handleYes} className={classes.dialogButton} style={{color: themeContext.color.red}}>
                        {buttonYesText}
                    </Button> :
                    <Button onClick={handleYes} className={classes.dialogButton} color="primary">
                        {buttonYesText}
                    </Button>
                }
            </div>
        </Dialog>
    )
}
export default DialogDoubleAction;