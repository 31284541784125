import React, {Fragment, useContext, useEffect, useState} from 'react';
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import Container from 'react-bootstrap/Container';
import {Col, Form, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import back from "../../images/back-icon.svg";
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {Button, Dialog, MenuItem, Select, Slide, TextField} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import exitIcon from "../../images/exit-icon.svg";
import DialogContent from "@material-ui/core/DialogContent";
import {useMediaQuery} from "react-responsive";
import editIcon from "../../images/edit-icon.svg";
import currencyCodes from 'currency-codes';
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BankReferences() {
    const root = 'bankReferences';
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    let defaultValues = {
        accountHolder: "",
        iban: "",
        swift: "",
        currency: "EUR"
    };
    const [formValues, setFormValues] = useState(defaultValues)
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const [errors, setErrors] = useState({});
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getPerson(personId);
        }
    }, []);


    const useStyles = makeStyles((theme) => ({
        divList: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            padding: "0 0 0 0"
        },
        list: {
            paddingTop: '0',
            paddingBottom: '30px',
            color: themeContext.color.neutral600,
        },
        listItem: {
            color: themeContext.color.neutral600,
            padding: '16px 0 0 0',
            fontSize: '18px'
        },
        listFullItem: {
            color: themeContext.color.neutral400,
            paddingTop: '18px',
            paddingBottom: '18px',
            fontSize: '18px'
        },
        avatar: {
            margin: '0 auto',
            width: '94px',
            height: '94px',
        },
        dialogTitle: {
            'textAlign': 'center',
            'paddingTop': '10px',
            'paddingBottom': '2px',
        },
        dialogDescription: {
            'textAlign': 'center',
            'paddingTop': '0px',
            'paddingBottom': '15px',
        }
    }));

    const token = sessionStorage.getItem('token');
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const getBankRefsByUserUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/getBankRefsByUser`;
    const getBankRefsByPersonIdUrl = `${process.env.REACT_APP_BASE_URL}/media/refundPremium/getBankRefByPersonId`;
    const checkIbanUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/checkIban`;
    const updateBankRefUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/updateBankRef`;
    const getBankRefsByNameUrl = `${process.env.REACT_APP_BASE_URL}/media/refundPremium/getBankRefByName`;
    const checkSwiftUrl = `${process.env.REACT_APP_BASE_URL}/media/refundPremium/checkSwift`;
    const personInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/person`;
    const deleteIbanUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/deleteIban`;
    const [serviceError, setServiceError] = useState(false);
    const classes = useStyles();
    const [person, setPerson] = useState([]);
    const params = useParams();
    const personId = params.personId;
    const [holders, setHolders] = useState([]);
    const [initialForm, setInitialForm] = useState(defaultValues);
    const [openDeleteIbanDialog, setOpenDeleteIbanDialog] = useState(false);
    const [personName, setPersonName] = useState('');


    const getAllBankRefs = (selectedPersonId, selectedPersonName) => {
        fetch(getBankRefsByUserUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setHolders(data)
            getBankRefsByPersonId(selectedPersonId, selectedPersonName)
        }).catch(() => {
            setServiceError(true)
        })
    }

    const getBankRefsByPersonId = (personId, selectedPersonName) => {
        fetch(`${getBankRefsByPersonIdUrl}/${personId}`, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json',},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPerson(data)
            console.log(data.accountHolder)
            setFormValues({
                ...formValues,
                iban: data.iban,
                currency: data.currency ? data.currency : 'EUR',
                swift: data.swift,
                accountHolder: data.accountHolder || selectedPersonName
            });
            setIsLoadingData(false)
            setIsLoadingForm(false)
        }).catch(() => {
            setServiceError(true)
        })
    }

    const getBankRefByName = (name) => {
        fetch(getBankRefsByNameUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setFormValues({
                ...formValues,
                accountHolder: name,
                iban: data.iban || '',
                currency: data.currency || 'EUR',
                swift: data.swift || '',
            });
            if (data.iban) {
                setErrors({
                    ...errors,
                    ['iban']: null
                })
            }
            if (data.swift) {
                setErrors({
                    ...errors,
                    ['swift']: null
                })
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    const checkSwift = () => {
        fetch(checkSwiftUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                swiftCode: formValues.swift,
                currency: formValues.currency
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                authCtx.storeBankRefsToUpdate(personId, formValues.accountHolder, formValues.iban.toUpperCase().split(" ").join(""), formValues.swift, formValues.currency)
                return navigate(`/settings/people/${personId}/bank-references/upload`);
            } else {
                const newErrors = {};
                if (data.message.includes('Swift')) {
                    newErrors.swift = t(data.message);
                }
                if (Object.keys(newErrors).length > 0) {
                    setErrors(newErrors);
                }
            }
        }).catch(() => {
            setServiceError(true)
        })
    }


    function turnBack() {
        return navigate(`/settings/people/${personId}`);
    }

    function reload() {
        window.location.reload();
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        switch (name) {
            case 'accountHolder':
                getBankRefByName(value);
                break;
            case 'iban':
                setFormValues({
                    ...formValues,
                    [`${name}`]: value,
                });
                checkIban(value).then(response => {
                    return response.json();
                }).then((data) => {
                    if (data.exist) {
                        getBankRefByName(data.accountHolder);
                    }
                });
                break;
            case 'currency':
                setFormValues({
                    ...formValues,
                    [`${name}`]: value,
                });
                if (value === 'EUR' && errors.swift) {
                    setErrors({
                        ...errors,
                        ['swift']: null
                    })
                }
                break;
            default:
                setFormValues({
                    ...formValues,
                    [`${name}`]: value,
                });
                break;
        }
    }

    const checkIban = (iban) => {
        return fetch(checkIbanUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                iban: iban.toUpperCase().split(" ").join(""),
            }),
        });
    }

    const submitForm = async (event) => {
        event.preventDefault();
        const newErrors = {};
        if (!formValues.iban) {
            newErrors.iban = t('IBAN not valid');
        } else {
            try {
                const response = await checkIban(formValues.iban);
                const data = await response.json();
                if (data.message === "IBAN not valid" || data.message === "IBAN not usable") {
                    newErrors.iban = t('IBAN not valid');
                }
            } catch (e) {
                newErrors.iban = t('IBAN validation error');
            }
        }
        if (!formValues.accountHolder) {
            newErrors.accountHolder = t('Required field');
        }
        if (formValues.currency !== 'EUR' && !formValues.swift) {
            newErrors.swift = t('This field is required for currency not equal to EUR');
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        if (JSON.stringify(formValues) === JSON.stringify(initialForm)) {
            setErrors({})
            setOpenDetailsDialog(false)
            return;
        }
        checkSwift();
    }

    const deleteBankRef = () => {
        fetch(deleteIbanUrl, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                personId: personId,
                bankRefId: person.bankRefId,
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                setErrors({})
                getPerson(personId);
                setOpenDeleteIbanDialog(false)
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    function getPerson(personId) {
        setIsLoadingData(true)
        setIsLoadingForm(true)
        fetch(`${personInfoUrl}/${personId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            /*if (data.firstName === null && data.lastName === null) {
                setPersonName(data.name)
            } else {
                setPersonName(`${data.firstName} ${data.lastName}`)
            }*/
            setPersonName(data.name)
            getAllBankRefs(personId, data.name)
        }).catch(() => {
            setServiceError(true)
        })
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2">{t(`${root}.title`)}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'background': themeContext.color.white}}>
                    <Row className="nav-top container" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingBottom': '20px',
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Row>
                                <Col className='col-2 float-left'>
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px"
                                         alt="back"/>
                                </Col>
                                <Col className="col-8">
                                    <h6 className="header-title ellipsis-1-v2">{t(`${root}.title`)}</h6>
                                </Col>
                                <Col className="col-2 float-right">
                                    <img src={editIcon} alt="edit icon" className={'cursor-pointer float-right'}
                                         height={24}
                                         onClick={() => {
                                             setOpenDetailsDialog(true)
                                         }}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Container className="top-of-the-profile"></Container>
                    {
                        isLoadingData ?
                            <Row style={{'height': '90vh'}} className="background-loading-spinner"></Row>
                            :
                            <Fragment>
                                <Container style={{
                                    'paddingLeft': '0',
                                    'paddingRight': '0',
                                }}>
                                    <Row>
                                        <Col style={{
                                            'background': themeContext.color.white,
                                            'paddingRight': '0px',
                                            'paddingLeft': '19.5px'
                                        }}>
                                            <div className={classes.divList}>
                                                <List className={classes.list}>
                                                    <ListItem className={classes.listItem}>
                                                        <TextField
                                                            label={t(`${root}.holder`)}
                                                            id="name"
                                                            fullWidth
                                                            className="custom-textField-baseline-disable"
                                                            value={person.accountHolder}
                                                            InputProps={{
                                                                style: {
                                                                    'color': themeContext.color.neutral600
                                                                },
                                                            }}
                                                        />
                                                    </ListItem>
                                                    <ListItem className={classes.listItem}>
                                                        <TextField
                                                            label={t(`${root}.iban`)}
                                                            id="iban"
                                                            fullWidth
                                                            className="custom-textField-baseline-disable"
                                                            value={person.iban}
                                                            InputProps={{
                                                                style: {
                                                                    'color': themeContext.color.neutral600
                                                                },
                                                            }}
                                                        />
                                                    </ListItem>
                                                    <ListItem className={classes.listItem}>
                                                        <TextField
                                                            label={t(`${root}.swift`)}
                                                            id="swift"
                                                            fullWidth
                                                            className="custom-textField-baseline-disable"
                                                            value={person.swift || ''}
                                                            InputProps={{
                                                                style: {
                                                                    'color': themeContext.color.neutral600
                                                                },
                                                            }}
                                                        />
                                                    </ListItem>
                                                    <ListItem className={classes.listItem}>
                                                        <TextField
                                                            label={t(`${root}.currency`)}
                                                            id="currency"
                                                            fullWidth
                                                            className="custom-textField-baseline-disable"
                                                            value={person.currency}
                                                            InputProps={{
                                                                style: {
                                                                    'color': themeContext.color.neutral600
                                                                },
                                                            }}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className={'w-100'} style={{position: 'absolute', bottom: 40, left: '50%', transform: 'translate(-50%,-50%)'}}>
                                        <Button
                                            className={person.bankRefId === null || isLoadingData ? "outline-disable-button" : "outline-delete-button"}
                                            variant="outlined"
                                            size="large"
                                            style={{
                                                width: '288px',
                                                textTransform: 'none'
                                            }}
                                            type="button"
                                            disabled={person.bankRefId === null || isLoadingData}
                                            onClick={() => {
                                                setOpenDeleteIbanDialog(true)
                                            }}
                                        >{t(`${root}.delete`)}</Button>
                                    </div>
                                </Container>
                            </Fragment>
                    }
                </Container>
                <Dialog
                    onClose={() => {
                        /*setFormValues({})
                        setErrors({})*/
                        setOpenDetailsDialog(false)
                    }}
                    fullScreen={!isDesktop}
                    fullWidth={isDesktop}
                    maxWidth={'sm'}
                    PaperProps={{
                        style: {
                            bottom: isDesktop ? '5%' : '0',
                            color: themeContext.color.neutral600,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            borderRadius: isDesktop ? '20px' : 'none',
                            height: isDesktop ? '90%' : '100%',
                            marginTop: '10%'
                        }
                    }}
                    open={openDetailsDialog}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title" className="dialog-title dialog-title-div"
                                 style={{textAlign: 'center'}}>
                        <Row style={{justifyContent: 'end'}}>
                            <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                                setOpenDetailsDialog(false)
                            }} style={{float: 'inline-end'}}/>
                        </Row>
                        <Row style={{marginTop: '-12px'}}>
                            <Col className="col-10">
                                <h6 className="dialog-form-title-1 float-left">{t(`${root}.title`)}</h6>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent className="no-scrollbar">
                        <Container className="dialog-main-container"
                                   style={{paddingBottom: "96px"}}>
                            <Row style={{marginTop: '0px'}}>
                                <Col>
                                    <h6 className="dialog-form-subtitle">{t(`${root}.dialog-subtitle`)}</h6>
                                </Col>
                            </Row>
                            {
                                isLoadingForm &&
                                <Row style={{'height': '200px'}} className="background-loading-spinner"></Row>
                            }
                            {
                                !isLoadingForm && <Form onSubmit={submitForm} style={{marginBottom: '60px'}}>
                                    <Row>
                                        <Col>
                                            <Row className="mt-1">
                                                <Col>
                                                    <h6 className="dialog-parameter mt-4 text-capitalize">
                                                        {t(`${root}.holder`)}
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Select
                                                        variant={'outlined'}
                                                        labelId="accountHolder-label"
                                                        id="accountHolder"
                                                        name="accountHolder"
                                                        value={formValues.accountHolder}
                                                        onChange={handleInputChange}
                                                        fullWidth
                                                        required
                                                        style={{'backgroundColor': themeContext.color.white, 'textAlign': 'left'}}
                                                        SelectDisplayProps={{
                                                            style: {
                                                                'textAlign': 'left',
                                                                'borderRadius': '4px',
                                                                'color': themeContext.color.neutral600,
                                                                'width': '100%',
                                                            }
                                                        }}>
                                                        {
                                                            holders.map((value, index) => (
                                                                <MenuItem key={index + 1} value={value.name}>
                                                                    {value.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <Row className="mt-1">
                                                <Col>
                                                    <h6 className="dialog-parameter mt-4 text-capitalize">
                                                        {t(`${root}.iban`)}
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <TextField
                                                        error={!!errors.iban}
                                                        helperText={errors.iban}
                                                        required
                                                        onChange={handleInputChange}
                                                        id={`iban`}
                                                        name={`iban`}
                                                        value={formValues.iban}
                                                        fullWidth
                                                        placeholder=""
                                                        variant="outlined"
                                                        color="primary"
                                                        InputProps={{
                                                            inputProps: {min: 0},
                                                            style: {
                                                                'borderRadius': '4px',
                                                                'color': themeContext.color.neutral600
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mt-1">
                                                <Col>
                                                    <h6 className="dialog-parameter mt-4 text-capitalize">
                                                        {t(`${root}.swift`)}
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <TextField
                                                        onChange={handleInputChange}
                                                        id={`swift`}
                                                        autoComplete={'off'}
                                                        name={`swift`}
                                                        fullWidth
                                                        placeholder=""
                                                        variant="outlined"
                                                        value={formValues.swift}
                                                        color="primary"
                                                        error={!!errors.swift}
                                                        helperText={errors.swift}
                                                        InputProps={{
                                                            inputProps: {min: 0},
                                                            style: {
                                                                'borderRadius': '4px',
                                                                'color': themeContext.color.neutral600
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mt-1">
                                                <Col>
                                                    <h6 className="dialog-parameter mt-4 text-capitalize">
                                                        {t(`${root}.currency`)}
                                                    </h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Select
                                                        id={`currency`}
                                                        name={'currency'}
                                                        value={formValues.currency}
                                                        variant={'outlined'}
                                                        onChange={handleInputChange}
                                                        fullWidth
                                                        style={{'backgroundColor': themeContext.color.white, 'textAlign': 'left'}}
                                                        SelectDisplayProps={{
                                                            style: {
                                                                'textAlign': 'left',
                                                                'borderRadius': '4px',
                                                                'color': themeContext.color.neutral600,
                                                                'width': '100%',
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            currencyCodes.data.map((currency, index) => (
                                                                <MenuItem key={index} value={currency.code}>
                                                                    {currency.code} - {t(currency.currency)}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Container className="position-absolute"
                                               style={
                                                   isDesktop ? {
                                                       background: 'transparent',
                                                       opacity: '1',
                                                       paddingBottom: "20px",
                                                       left: 0,
                                                       bottom: 0
                                                   } : {
                                                       background: 'transparent',
                                                       opacity: '1',
                                                       paddingBottom: "68px",
                                                       left: 0,
                                                       bottom: 0
                                                   }
                                               }>
                                        <Row style={{textAlign: 'center'}}>
                                            <Col>
                                                <Button
                                                    className={"close-button boxShadow"}
                                                    variant="outlined"
                                                    size="large"
                                                    style={{
                                                        color: themeContext.color.white,
                                                        width: '288px',
                                                        textTransform: 'none'
                                                    }}
                                                    type="submit"
                                                >{t(`${root}.save`)}</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form>
                            }
                        </Container>
                    </DialogContent>
                </Dialog>
                <DialogDoubleAction
                    open={openDeleteIbanDialog}
                    handleClose={() => setOpenDeleteIbanDialog(false)}
                    title={t(`${root}.dialog-delete-iban-title`)}
                    description={`${t(`${root}.dialog-delete-iban-description-1`)} ${personName}${t(`${root}.dialog-delete-iban-description-2`)}`}
                    buttonYesText={t('dialog.button-delete')}
                    handleYes={() => {
                        deleteBankRef();
                    }}
                    buttonNoText={t('dialog.button-cancel')}
                    handleNo={() => setOpenDeleteIbanDialog(false)}
                    deleteAction={true}
                ></DialogDoubleAction>
            </Fragment>
    )
}