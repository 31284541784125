import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import themeContext from "./ThemeContext.js";

const useStyles = makeStyles((theme) => ({
    backDrop: {
        backdropFilter: "blur(16px)",
        backgroundColor: themeContext.color.shadowBlack,
        opacity: 1
    },
    dialog: {
        color: theme.palette.primary.neutral600,
        padding: '24px'
    },
    dialogTitle: {
        textAlign: 'left',
        font: 'normal normal 700 20px/28px Inter',
        paddingBottom: '24px'
    },
    dialogDescription: {
        textAlign: 'left',
        font: 'normal normal normal 14px/140% Inter',
        paddingBottom: '24px'
    },
    dialogAction: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    dialogButton: {
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'bold',
    }
}));

const DialogSmall = ({open, handleClose, title, description, buttonText}) => {
    const classes = useStyles();
    return (
        <Dialog
            BackdropProps={{classes: {root: classes.backDrop}}}
            PaperProps={{style: {color: themeContext.color.neutral600, padding: '24px'}}}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <h6 className={classes.dialogTitle}>{title}</h6>
            <h6 className={classes.dialogDescription}>{description}</h6>
            <div className={classes.dialogAction}>
                <Button onClick={handleClose} className={classes.dialogButton} color="primary">
                    {buttonText}
                </Button>
            </div>
        </Dialog>
    )
}
export default DialogSmall;