import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Col, Row} from "react-bootstrap";
import themeContext from "./ThemeContext.js";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        flexWrap: "nowrap",
        overflowX: "auto",
    },
    chip: {
        display: "flex",
        textWrap: "nowrap",
        alignItems: "center",
        borderRadius: "120px",
        height: "35px",
        fontSize: "16px",
        padding: "3px 12px 4px 12px",
        fontWeight: "600",
        cursor: "pointer",
        color: themeContext.color.neutral400,
        background: themeContext.color.neutral100,
    },
    selectedChip: {
        display: "flex",
        textWrap: "nowrap",
        alignItems: "center",
        borderRadius: "120px",
        height: "35px",
        fontSize: "16px",
        padding: "3px 12px 4px 12px",
        fontWeight: "600",
        cursor: "pointer",
        color: themeContext.color.main,
        background: themeContext.color.main10,
    }
}));

const Chips = ({chips, onChange, selected}) => {
    const classes = useStyles();
    const [selectedChip, setSelectedChip] = useState(selected ? parseInt(selected) : chips[0].value);

    const handleChip = (item) => {
        console.log('item', item)
        setSelectedChip(item)
        onChange(item)
    }

    return (
        <Row className={'container px-0'} style={{
            'backgroundColor': themeContext.color.white,
            'paddingTop': '24px',
            'paddingBottom': '24px',
        }}>
            <Col className={'chip-container no-scrollbar px-0'}>
                <div className={'chip-row'}>
                    {chips.map((chip, index) => (
                        <div key={index} className={selectedChip === chip.value ? classes.selectedChip : classes.chip}
                             onClick={() => handleChip(chip.value)}>
                            {chip.label}
                        </div>
                    ))}
                </div>
            </Col>
        </Row>
    );
};

export default Chips;