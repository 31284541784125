import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next'
import {Button, Slide} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import personalDocument from "../../images/personal-documents-alt-icon.svg";
import favouriteIcon from "../../images/favourite-icon.svg";
import documentPlaceholder from '../../images/document-placeholder.svg';
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import baseMaleAvatar from "../../images/male-icon.svg";
import baseFemaleAvatar from "../../images/female-icon.svg";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import goTo from "../../images/goto-icon.svg";
import List from "@material-ui/core/List";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Collapse from "@material-ui/core/Collapse";
import Resizer from "react-image-file-resizer";
import spinner from "../../images/spinner.svg";
import exitIcon from "../../images/exit-icon.svg";
import {useMediaQuery} from "react-responsive";
import documentPersonIcon from '../../images/documentPerson.svg'
import documentTypeIcon from '../../images/documentType.svg'
import documentTypeActiveIcon from '../../images/documentTypeActive.svg'
import checkboxUnselectedIcon from "../../images/checkbox-unselected.svg";
import checkboxSelectedIcon from "../../images/checkbox-selected.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import exit from "../../images/exit-icon.svg";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import DialogSmall from "../components/DialogSmall.js";
import baseCompanyAvatar from "../../images/company-avatar.svg";
import CropDialog from "../components/CropDialog.js";
import themeContext from "../components/ThemeContext.js";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    backDrop: {
        backdropFilter: "blur(16px)",
        backgroundColor: themeContext.color.shadowBlack,
        opacity: 1
    },
    rootList: {
        width: '100%',
        backgroundColor: themeContext.color.neutral10,
        padding: "0 0 0 0",
    },
    listItem: {
        fontSize: "14px",
        color: themeContext.color.neutral400,
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    innerListItem: {
        fontSize: "14px",
        color: themeContext.color.neutral400,
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    listItemName: {
        paddingTop: '18px',
        paddingBottom: '18px',
    },
    listItemIcon: {
        marginLeft: '0',
        marginRight: '-14px',
    },
    listTextSelected: {
        color: themeContext.color.neutral600,
        fontSize: '16px !important'
    },
    listText: {
        fontSize: '16px !important'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    primary: {
        color: themeContext.color.main,
    },
    peopleName: {
        textTransform: "uppercase",
        textAlign: "left",
        font: "normal normal bold 14px/29px Inter",
        letterSpacing: "0px",
        color: themeContext.color.neutral400,
        opacity: "1",
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    avatar: {
        margin: '0 auto',
        width: '74px',
        height: '74px',
    },
    personAvatar: {
        width: "30px",
        height: "30px",
        marginBottom: "5px",
        marginLeft: "5px",
        marginRight: "10px"
    },
    avatarList: {
        width: "32px",
        height: "32px",
        marginRight: "16px"
    },
    listTextRight: {
        textAlign: "right",
        font: "normal normal bold 12px/22px Inter",
        letterSpacing: "0px",
        color: themeContext.color.neutral400,
        opacity: "1",
        justifyContent: "end"
    },
}));

export default function PersonalDocuments() {
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/peopleLite`;
    const getAllGroupDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group`;
    const attachDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/attachDocument`;
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const params = useParams();
    const personId = params.personId;
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const documentId = query.get('documentId');
    const titleId = query.get('titleId');
    const policyId = query.get('policyId');
    const [openDialog, setOpenDialog] = useState(false);
    const [openMenu, setOpenMenu] = useState(null);
    const [documentType, setDocumentType] = useState(0);
    const [selectedPeople, setSelectedPeople] = useState({id: null});
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingPeopleData, setIsLoadingPeopleData] = useState(false);
    const [user, setUser] = useState("");
    const [people, setPeople] = useState([]);
    const [peopleList, setPeopleList] = useState([]);
    const [groupDocument, setGroupDocument] = useState([]);
    const [isDocument, setIsDocument] = useState(false);
    const [userHasDocument, setUserHasDocument] = useState(false);
    const [documentTypeError, setDocumentTypeError] = useState(false);
    const [selectedPeopleError, setSelectedPeopleError] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const [openListNotSignedArray, setOpenListNotSignedArray] = useState([]);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const cropperRef = useRef(null);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    const [cropper, setCropper] = useState(null);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [pendingDocument, setPendingDocument] = useState({url: '', base64: '', name: '', mimeType: ''});
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'});
    const [peopleDivVisibility, setPeopleDivVisibility] = useState(false);
    const [typeDivVisibility, setTypeDivVisibility] = useState(false);
    const [isFavourite, setIsFavourite] = useState(false);
    const [isLoadingCreate, setIsLoadingCreate] = useState(false)
    const [openExitDialog, setOpenExitDialog] = useState(false);
    const authCtx = useContext(AuthContextMiddleware);
    const [openFallbackDialog, setOpenFallbackDialog] = useState(false);
    const [openSizeExceededDialog, setOpenSizeExceededDialog] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getInfo();
            getPeople();
            getAllGroupDocument();

            if (window.location.href.includes('refunds') || window.location.href.includes('bank-references')) {
                if (authCtx.bankRefsToUpdate.iban === '' && authCtx.bankRefsToUpdate.personId === '' && authCtx.bankRefsToUpdate.accountHolder === '') {
                    console.log('dati bancari non presenti')
                    setOpenFallbackDialog(true)
                }
            }
        }
    }, []);

    function reload() {
        window.location.reload();
    }

    const turnBack = () => {
        if (window.location.href.includes('/signing')) {
            return navigate(`/signing/${personId}/upload?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`);
        } else {
            return navigate(-1);
        }
    }

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleChangeImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    const handleClose = () => {
        setOpenDialog(false);
    };


    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setOpenMenu(null);
    }

    const handleSelectDocumentTypeChange = (event) => {
        setDocumentType(event.target.value);
    }

    const handleSelectPeopleChange = (event) => {
        setSelectedPeople(event.target.value);
        handleSelectedImage(event.target.value)
    }

    function getInfo() {
        setUser([])
        fetch(userInfoUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setUser(data)
                //setSelectedPeople(data.policyPersonId)
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    const getPeople = () => {
        setIsLoadingPeopleData(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            /*data.map((item, index) => {
                if (item.status !== -1) {
                    setPeople(current => [...current, item])
                }
            })*/
            setPeople(data)
            setIsLoadingPeopleData(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const getAllGroupDocument = () => {
        setIsLoadingData(true)
        setPeopleList([])
        setGroupDocument([])
        fetch(getAllGroupDocumentUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length !== 0) {
                setIsDocument(true)
                setGroupDocument(data)
                setUser([])
                fetch(userInfoUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                }).then((response) => {
                    return response.json();
                }).then((resUser) => {
                    if (resUser.status !== -1) {
                        setUser(resUser)
                    }
                    data.map((value, index) => {
                        if (value.personId === resUser.policyPersonId) {
                            setUserHasDocument(true)
                        }
                    })
                    fetch(getPeopleUrl, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                    }).then((response) => {
                        return response.json();
                    }).then((resultPeople) => {
                        resultPeople.map((item, index) => {
                            let passed = false;
                            data.map((value, index) => {
                                if (value.personId === item.id) {
                                    passed = true;
                                }
                            })
                            if (passed === true) {
                                setPeopleList(current => [...current, item])
                            }
                        })
                        setIsLoadingData(false)
                    }).catch(() => {
                        setServiceError(true)
                    });
                }).catch(() => {
                    setServiceError(true)
                })
            } else {
                setIsLoadingData(false)
            }
        }).catch(() => {
            setServiceError(true)
        });
    }

    const handleSelectedImage = (e) => {
        if (e === user.policyPersonId) {
            if (user.image === null) {
                if (user.gender === 'F') {
                    document.getElementById("user-image").children[0].src = baseFemaleAvatar;
                } else if (user.gender === 'C') {
                    document.getElementById("user-image").children[0].src = baseCompanyAvatar;
                } else {
                    document.getElementById("user-image").children[0].src = baseMaleAvatar;
                }
            } else {
                document.getElementById("user-image").children[0].src = user.image;
            }
        } else {
            for (let i = 0; i < people.length; i++) {
                if (people[i].id === e) {
                    if (people[i].image === null) {
                        if (people[i].gender === 'F') {
                            document.getElementById("user-image").children[0].src = baseFemaleAvatar;
                        } else if (user.gender === 'C') {
                            document.getElementById("user-image").children[0].src = baseCompanyAvatar;
                        } else {
                            document.getElementById("user-image").children[0].src = baseMaleAvatar;
                        }
                    } else {
                        document.getElementById("user-image").children[0].src = people[i].image;
                    }
                }
            }
        }
    }

    const linkToGroupDocument = (id, hasFavorite, documents) => {
        if (window.location.href.includes('signing')) {
            if (hasFavorite === true) {
                documents.map(document => {
                    if (document.isFavorite === true) {
                        authCtx.storeReqImage(document.document)
                        authCtx.storeReqImageBase64(document.base64)
                        return navigate(`/signing/${personId}/upload/personalDocuments/${id}/identify?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
                    }
                })
            } else {
                return navigate(`/signing/${personId}/upload/personalDocuments/${id}?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
            }
        } else if (window.location.href.includes('refunds')) {
            if (hasFavorite === true) {
                documents.map(document => {
                    if (document.isFavorite === true) {
                        authCtx.storeReqImage(document.document)
                        authCtx.storeReqImageBase64(document.base64)
                        return navigate(`/refunds/${personId}/upload/personalDocuments/${id}/identify`)
                    }
                })
            } else {
                return navigate(`/refunds/${personId}/upload/personalDocuments/${id}`)
            }
        } else if (window.location.href.includes('bank-references')) {
            if (hasFavorite === true) {
                documents.map(document => {
                    if (document.isFavorite === true) {
                        authCtx.storeReqImage(document.document)
                        authCtx.storeReqImageBase64(document.base64)
                        return navigate(`/settings/people/${personId}/bank-references/upload/personalDocuments/${id}/identify`)
                    }
                })
            } else {
                return navigate(`/settings/people/${personId}/bank-references/upload/personalDocuments/${id}`)
            }
        } else if (window.location.href.includes('invitation')) {
            if (hasFavorite === true) {
                documents.map(document => {
                    if (document.isFavorite === true) {
                        authCtx.storeReqImage(document.document)
                        authCtx.storeReqImageBase64(document.base64)
                        return navigate(`/settings/people/${personId}/invitation/personalDocuments/${id}/identify`)
                    }
                })
            } else {
                return navigate(`/settings/people/${personId}/invitation/personalDocuments/${id}`)
            }
        } else if (window.location.href.includes('settings')) {
            return navigate(`/settings/personal-documents/${id}`)
        }
    }

    const createGroup = () => {
        if (selectedPeople.id !== null && documentType !== 0) {
            fetch(getAllGroupDocumentUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    personId: `${selectedPeople.id}`,
                    type: documentType,
                }),
            }).then((res) => {
                handleClose();
                getAllGroupDocument();
                getInfo();
                return res.json();
            }).then((data) => {
                navigate(`/settings/personal-documents/${data.id}`)
            }).catch(() => {
                setServiceError(true)
            });
        } else {
            if (selectedPeople.id === null) {
                setSelectedPeopleError(true)
            } else {
                setSelectedPeopleError(false)
            }
            if (documentType === 0) {
                setDocumentTypeError(true)
            } else {
                setDocumentTypeError(false)
            }
        }
    }

    const createGroupWithAttachedDocument = () => {
        if (selectedPeople.id !== null && documentType !== 0) {
            setIsLoadingCreate(true)
            fetch(getAllGroupDocumentUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    personId: `${selectedPeople.id}`,
                    type: documentType,
                    documents: [
                        {
                            name: pendingDocument.name,
                            mimeType: pendingDocument.mimeType,
                            base64: pendingDocument.base64,
                            isFavorite: isFavourite
                        }
                    ]
                }),
            }).then((res) => {
                //handleClose();
                setOpenUploadDialog(false)
                getAllGroupDocument();
                getInfo();
                return res.json();
            }).then((data) => {
                navigate(`/settings/personal-documents/${data.id}`)
                setIsLoadingCreate(false)
            }).catch(() => {
                setServiceError(true)
            });
        } else {
            if (selectedPeople.id === null) {
                setSelectedPeopleError(true)
            } else {
                setSelectedPeopleError(false)
            }
            if (documentType === 0) {
                setDocumentTypeError(true)
            } else {
                setDocumentTypeError(false)
            }
            setIsLoadingCreate(false)
        }
    }

    const handleClick = (index) => {
        if (!openListNotSignedArray.includes(index)) {
            setOpenListNotSignedArray(current => [...current, index])
        } else {
            setOpenListNotSignedArray(openListNotSignedArray.filter(item => item !== index))
        }
    }

    const handleOnPendingImage = async (e) => {
        try {
            e.preventDefault();
            let file = e.target.files[0];
            if (file && file.size > 20000000) {
                throw t('Maximum upload size exceeded');
            }
            const imageResized = await resizeFile(file)
            setOnPendingImage(imageResized);
            setOpenCropDialog(true)
        } catch (err) {
            console.log(err)
            setOpenSizeExceededDialog(true)
        }
    };

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCroppedCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            const randomName = Math.floor((Math.random() * 100000000) + 1)
            let split1 = cropImage.split(":");
            let split2 = split1[1].split(";");
            let mimetype = split2[0];
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            setIsLoadingImg(true);
            setPendingDocument({url: cropImage, base64: base64_string, name: randomName, mimeType: mimetype})
            setOpenCropDialog(false)
            setIsLoadingImg(false)
            if (window.location.href.includes('signing')) {
                authCtx.addPendingDocument(cropImage, base64_string, randomName, mimetype)
                return navigate(`/signing/${personId}/upload/personalDocuments/new?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`)
            } else if (window.location.href.includes('refunds')) {
                authCtx.addPendingDocument(cropImage, base64_string, randomName, mimetype)
                return navigate(`/refunds/${personId}/upload/personalDocuments/new`)
            } else if (window.location.href.includes('bank-references')) {
                authCtx.addPendingDocument(cropImage, base64_string, randomName, mimetype)
                return navigate(`/settings/people/${personId}/bank-references/upload/personalDocuments/new`)
            } else if (window.location.href.includes('invitation')) {
                authCtx.addPendingDocument(cropImage, base64_string, randomName, mimetype)
                return navigate(`/settings/people/${personId}/invitation/personalDocuments/new`)
            } else {
                setOpenUploadDialog(true)
            }
        }
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Row className="nav-top container" style={{
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2">{t('personalDocuments.title')}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Row className="nav-top-shadow container"
                     style={{
                         'backgroundColor': themeContext.color.white,
                         'paddingTop': '19.5px',
                         'paddingBottom': '20px',
                         'paddingLeft': '0',
                         'paddingRight': '0',
                     }}>
                    <Col className="col-2 float-left">
                        <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                             height="27px"
                             alt="back"/>
                    </Col>
                    <Col className="col-8">
                        <h6 className="header-title ellipsis-1-v2">{t('personalDocuments.title')}</h6>
                    </Col>
                    {
                        (window.location.href.includes('signing') || window.location.href.includes('refunds') || window.location.href.includes('bank-references') || window.location.href.includes('invitation')) ?
                            <Col className="col-2">
                                <img src={exit} onClick={() => setOpenExitDialog(true)}
                                     className="nav-top-logo float-right cursor-pointer" alt="exit-icon" height="27px"/>
                            </Col>
                            :
                            <Col className="col-2">
                            </Col>
                    }
                </Row>
                <Container className="top-of-the-preferences"></Container>
                <Row className="pr-0 pl-0">
                    <Col className="col-list">
                        {
                            isLoadingData &&
                            <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                        }
                        {
                            !isLoadingData && !isDocument &&
                            <Container>
                                <Row style={{
                                    'marginTop': '43.5px',
                                    'marginBottom': '44.5px'
                                }}>
                                    <Col className="pl-5 pr-5">
                                        <span style={{
                                            'fontSize': '18px',
                                            'color': themeContext.color.mainActive,
                                            'fontWeight': 'bold'
                                        }}>{t('personalDocuments.placeholder-text')}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <img src={documentPlaceholder} alt="document-placeholder" width="100%"
                                         style={{'maxHeight': '500px'}}/>
                                </Row>
                            </Container>
                        }
                        <List component="nav" className={classes.rootList} aria-labelledby="nested-list-subheader">
                            {!isLoadingData && isDocument &&
                                peopleList.map((value, index) => (
                                    <Fragment key={index}>
                                        <ListItem button onClick={() => handleClick(index)}
                                                  className={classes.listItem}>
                                            <ListItemIcon>
                                                {value.image === null && value.gender === 'M' &&
                                                    <Avatar className={classes.personAvatar}
                                                            src={baseMaleAvatar}
                                                            alt="avatar-image"/>}
                                                {value.image === null && value.gender === 'F' &&
                                                    <Avatar className={classes.personAvatar}
                                                            src={baseFemaleAvatar}
                                                            alt="avatar-image"/>}
                                                {value.image === null && value.gender === 'C' &&
                                                    <Avatar className={classes.personAvatar}
                                                            src={baseCompanyAvatar}
                                                            alt="avatar-image"/>}
                                                {value.image === null && value.gender === null &&
                                                    <Avatar className={classes.personAvatar}
                                                            src={baseMaleAvatar}
                                                            alt="avatar-image"/>}
                                                {value.image &&
                                                    <Avatar className={classes.personAvatar} src={value.image}
                                                            alt="avatar-image"/>}
                                            </ListItemIcon>
                                            {((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                                <ListItemText className="list-label"
                                                              secondary={value.name.toLowerCase()}
                                                              style={{
                                                                  textTransform: 'capitalize',
                                                                  color: themeContext.color.neutral600
                                                              }}/> :
                                                <ListItemText className="list-label"
                                                              secondary={`${value.firstName.toLowerCase()} ${value.lastName.toLowerCase()}`}
                                                              style={{textTransform: 'capitalize', color: themeContext.color.neutral600}}/>}
                                            {openListNotSignedArray.includes(index) ?
                                                <ExpandLess style={{color: themeContext.color.neutral600}}/> :
                                                <ExpandMore style={{color: themeContext.color.neutral600}}/>}
                                        </ListItem>
                                        <Collapse in={!openListNotSignedArray.includes(index)} timeout="auto"
                                                  unmountOnExit>
                                            <List component="div" className="col pb-2 p-0">
                                                {
                                                    groupDocument.map((document, index) => (
                                                        value.id === document.personId &&
                                                        <Fragment key={index}>
                                                            <ListItem className={classes.innerListItem} button
                                                                      onClick={() => linkToGroupDocument(document.id, document.hasFavorite, document.documents)}>
                                                                {document.hasFavorite === true ?
                                                                    <ListItemIcon className={classes.listItemIcon}>
                                                                        <div className="favourite-icon-div">
                                                                            <img src={favouriteIcon} alt="image"
                                                                                 className="favourite-icon"
                                                                                 width="16px"/>
                                                                        </div>
                                                                    </ListItemIcon> :
                                                                    <ListItemIcon className={classes.listItemIcon}>
                                                                        <div hidden className="favourite-icon-div">
                                                                            <img src={favouriteIcon} alt="image"
                                                                                 className="favourite-icon"
                                                                                 width="16px"/>
                                                                        </div>
                                                                    </ListItemIcon>}
                                                                <ListItemIcon className={classes.listItemIcon}>
                                                                    <img src={personalDocument} alt="image"
                                                                         width="28px"/>
                                                                </ListItemIcon>
                                                                <ListItemText>
                                                                    <span className="ellipsis-1">
                                                                        <span className={classes.listText}>
                                                                        {
                                                                            document.type === "1" &&
                                                                            <span>{t('documentsList.title-document-1')}</span>
                                                                        }
                                                                            {
                                                                                document.type === "2" &&
                                                                                <span>{t('documentsList.title-document-2')}</span>
                                                                            }
                                                                            {
                                                                                document.type === "3" &&
                                                                                <span>{t('documentsList.title-document-3')}</span>
                                                                            }
                                                                            {
                                                                                document.type === "4" &&
                                                                                <span>{t('documentsList.title-document-4')}</span>
                                                                            }
                                                                            {
                                                                                document.type === "5" &&
                                                                                <span>{t('documentsList.title-document-5')}</span>
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </ListItemText>
                                                                <ListItemIcon className="justify-content-end">
                                                                    <span className={classes.listTextRight}>
                                                                        {t('personalDocuments.nr-photos-label')} {document.photosNr}
                                                                    </span>
                                                                    <ChevronRight/>
                                                                </ListItemIcon>
                                                            </ListItem>
                                                        </Fragment>
                                                    ))
                                                }
                                            </List>
                                        </Collapse>
                                    </Fragment>
                                ))
                            }
                        </List>
                    </Col>
                </Row>
                {
                    !isLoadingData && isDocument &&
                    <Container style={{
                        'paddingBottom': "100px"
                    }}></Container>
                }
                <Container className="fixed-bottom"
                           style={{
                               'paddingBottom': "40px"
                           }}>
                    <Row>
                        <Col>
                            <Button className="btn-custom"
                                    variant="outlined"
                                    size="large"
                                    style={{color: themeContext.color.white, textTransform: 'none'}} type="submit"
                                    onClick={handleChangeImage}
                            >{t('personalDocuments.button')}</Button>
                            <input hidden id="image-file" type="file" onChange={handleOnPendingImage} accept="image/*"/>
                        </Col>
                    </Row>
                </Container>
                <CropDialog open={openCropDialog}
                            handleAttach={attachCroppedImage}
                            handleClose={() => {
                                setOpenCropDialog(false)
                                setIsLoadingImg(false)
                            }}
                            isLoading={isLoadingImg}
                            onPendingImage={onPendingImage}
                            cropperRef={cropperRef}
                            setCropper={(data) => setCropper(data)}/>
                <Dialog
                    fullScreen={!isDesktop}
                    fullWidth={isDesktop}
                    maxWidth={'sm'}
                    PaperProps={{
                        style: {
                            bottom: isDesktop ? '5%' : '0',
                            color: themeContext.color.neutral600,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            borderRadius: isDesktop ? '20px' : 'none',
                            height: isDesktop ? '90%' : '100%',
                            marginTop: '10%'
                        }
                    }}
                    open={openUploadDialog}
                    TransitionComponent={Transition}
                    onClose={() => {
                        setOpenUploadDialog(false)
                    }}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                                 style={{textAlign: 'center'}}>
                        <Row style={{justifyContent: 'end'}}>
                            <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                                setOpenUploadDialog(false)
                            }} style={{float: 'inline-end'}}/>
                        </Row>
                        <Row style={{marginTop: '-12px'}}>
                            <Col className="col">
                                <h6 className="dialog-form-title-1 float-left">Salva documento</h6>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent className="no-scrollbar">
                        <div className="dialog-main-container"
                             style={{paddingBottom: "96px"}}>
                            {isLoadingData &&
                                <div style={{'height': '30vh'}} className="background-loading-spinner"></div>
                            }
                            {
                                !isLoadingData &&
                                <div>
                                    <div className="mt-4 flex-wrap justify-content-between" style={{
                                        display: "flex",
                                        gap: "16px",
                                        paddingBottom: "16px"
                                    }}>
                                        <div style={{width: '100%'}}>
                                            <img src={pendingDocument.url} className={'image-grid-full'}
                                                 alt={`new-image`}/>
                                        </div>
                                        <ClickAwayListener
                                            onClickAway={() => {
                                                setPeopleDivVisibility(false)
                                                setTypeDivVisibility(false)
                                            }}>

                                            <div style={{display: "flex", gap: "16px", width: '100%'}}
                                                 className={'flex-row justify-content-between position-relative'}>
                                                <div className={'personal-documents-select cursor-pointer'}
                                                     onClick={() => setPeopleDivVisibility(true)}>
                                                    {
                                                        selectedPeople.id === null &&
                                                        <img height={'80px'}
                                                             src={documentPersonIcon}
                                                             alt={'select-user'}/>
                                                    }
                                                    {
                                                        selectedPeople.id !== null &&
                                                        <Fragment>
                                                            {
                                                                selectedPeople.image === null && selectedPeople.gender === 'M' &&
                                                                <Avatar className={classes.avatar}
                                                                        src={baseMaleAvatar}
                                                                        id="user-image"
                                                                        alt="avatar-image"/>
                                                            }
                                                            {
                                                                selectedPeople.image === null && selectedPeople.gender === 'F' &&
                                                                <Avatar className={classes.avatar}
                                                                        src={baseFemaleAvatar}
                                                                        id="user-image"
                                                                        alt="avatar-image"/>
                                                            }
                                                            {
                                                                selectedPeople.image === null && selectedPeople.gender === 'C' &&
                                                                <Avatar className={classes.avatar}
                                                                        src={baseCompanyAvatar}
                                                                        id="user-image"
                                                                        alt="avatar-image"/>
                                                            }
                                                            {
                                                                selectedPeople.image === null && selectedPeople.gender === null &&
                                                                <Avatar className={classes.avatar}
                                                                        src={baseMaleAvatar}
                                                                        id="user-image"
                                                                        alt="avatar-image"/>
                                                            }
                                                            {
                                                                selectedPeople.image &&
                                                                <Avatar className={classes.avatar}
                                                                        src={selectedPeople.image}
                                                                        id="user-image"
                                                                        alt="avatar-image"/>
                                                            }
                                                        </Fragment>
                                                    }
                                                    <span
                                                        className={'align-center personal-documents-select-title'}>
                                                        {selectedPeople.id !== null ?
                                                            <Fragment>
                                                                {selectedPeople.firstName === null && selectedPeople.lastName === null ?
                                                                    <span>{selectedPeople.name}</span> :
                                                                    <span>{selectedPeople.firstName} {selectedPeople.lastName}</span>}
                                                            </Fragment> :
                                                            selectedPeopleError === true ?
                                                                <span className={'red'}>
                                                        {t('personalDocuments.modal-select-people')}
                                                            </span>
                                                                :
                                                                <span>
                                                        {t('personalDocuments.modal-select-people')}
                                                            </span>
                                                        }
                                                    </span>
                                                </div>
                                                <div className={'personal-documents-select cursor-pointer'}
                                                     onClick={() => setTypeDivVisibility(true)}>
                                                    <img height={'80px'}
                                                         src={documentType === 0 ? documentTypeIcon : documentTypeActiveIcon}
                                                         alt={'select-user'}/>
                                                    <span
                                                        className={'align-center personal-documents-select-title'}>
                                                        {
                                                            documentTypeError ?
                                                                <span className={'red'}>
                                                                    {t('personalDocuments.modal-select-document-type')}
                                                                </span> :
                                                                <Fragment>
                                                                    {documentType === 0 &&
                                                                        <span className={'darkgrey'}>
                                                                            {t('personalDocuments.modal-select-document-type')}
                                                                        </span>
                                                                    }
                                                                    {documentType === 1 &&
                                                                        <span className={'darkgrey'}>
                                                                            {t('personalDocuments.select-document-1')}
                                                                        </span>
                                                                    }
                                                                    {documentType === 2 &&
                                                                        <span className={'darkgrey'}>
                                                                            {t('personalDocuments.select-document-2')}
                                                                        </span>
                                                                    }
                                                                    {documentType === 3 &&
                                                                        <span className={'darkgrey'}>
                                                                            {t('personalDocuments.select-document-3')}
                                                                        </span>
                                                                    }
                                                                    {documentType === 4 &&
                                                                        <span className={'darkgrey'}>
                                                                            {t('personalDocuments.select-document-4')}
                                                                        </span>
                                                                    }
                                                                    {documentType === 5 &&
                                                                        <span className={'darkgrey'}>
                                                                            {t('personalDocuments.select-document-5')}
                                                                        </span>
                                                                    }
                                                                </Fragment>
                                                        }
                                                    </span>
                                                </div>
                                                <div hidden={!peopleDivVisibility} className={'select-div-list'}>
                                                    <List component="nav">
                                                        {
                                                            people.map((value, index) => (
                                                                <Fragment key={index}>
                                                                    <ListItem button style={{padding: '16px'}}
                                                                              onClick={() => {
                                                                                  if (selectedPeople.id === value.id) {
                                                                                      setSelectedPeople({id: null})
                                                                                  } else {
                                                                                      setSelectedPeople(value)
                                                                                  }
                                                                                  setSelectedPeopleError(false)
                                                                                  setPeopleDivVisibility(false)
                                                                              }}>
                                                                        {
                                                                            value.image === null && value.gender === 'M' &&
                                                                            <Avatar className={classes.avatarList}
                                                                                    src={baseMaleAvatar}
                                                                                    id="user-image"
                                                                                    alt="avatar-image"/>
                                                                        }
                                                                        {
                                                                            value.image === null && value.gender === 'F' &&
                                                                            <Avatar className={classes.avatarList}
                                                                                    src={baseFemaleAvatar}
                                                                                    id="user-image"
                                                                                    alt="avatar-image"/>
                                                                        }
                                                                        {
                                                                            value.image === null && value.gender === 'C' &&
                                                                            <Avatar className={classes.avatarList}
                                                                                    src={baseCompanyAvatar}
                                                                                    id="user-image"
                                                                                    alt="avatar-image"/>
                                                                        }
                                                                        {
                                                                            value.image === null && value.gender === null &&
                                                                            <Avatar className={classes.avatarList}
                                                                                    src={baseMaleAvatar}
                                                                                    id="user-image"
                                                                                    alt="avatar-image"/>
                                                                        }
                                                                        {
                                                                            value.image &&
                                                                            <Avatar className={classes.avatarList}
                                                                                    src={value.image}
                                                                                    id="user-image"
                                                                                    alt="avatar-image"/>
                                                                        }
                                                                        <Fragment>
                                                                            {((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                                                                <span
                                                                                    className={'darkgrey'}>{value.name}</span> :
                                                                                <span
                                                                                    className={'darkgrey'}>{value.firstName} {value.lastName}</span>}
                                                                        </Fragment>
                                                                    </ListItem>
                                                                    {index + 1 !== people.length && <Divider style={{
                                                                        marginLeft: '16px',
                                                                        marginRight: '16px'
                                                                    }}/>}
                                                                </Fragment>
                                                            ))
                                                        }
                                                    </List>
                                                </div>
                                                <div hidden={!typeDivVisibility} className={'select-div-list'}>
                                                    <List component="nav">
                                                        <ListItem button style={{padding: '16px'}} value={1}
                                                                  className={'personal-documents-select-title'}
                                                                  onClick={() => {
                                                                      if (documentType === 1) {
                                                                          setDocumentType(0)
                                                                      } else {
                                                                          setDocumentType(1)
                                                                      }
                                                                      setDocumentTypeError(false)
                                                                      setTypeDivVisibility(false)
                                                                  }}>
                                                            <ListItemIcon>
                                                                <img src={personalDocument}
                                                                     alt={'personal-document-icon'}/>
                                                            </ListItemIcon>
                                                            {t('personalDocuments.select-document-1')}
                                                        </ListItem>
                                                        <Divider style={{marginLeft: '16px', marginRight: '16px'}}/>
                                                        <ListItem button style={{padding: '16px'}} value={2}
                                                                  className={'personal-documents-select-title'}
                                                                  onClick={() => {
                                                                      if (documentType === 2) {
                                                                          setDocumentType(0)
                                                                      } else {
                                                                          setDocumentType(2)
                                                                      }
                                                                      setDocumentTypeError(false)
                                                                      setTypeDivVisibility(false)
                                                                  }}>
                                                            <ListItemIcon>
                                                                <img src={personalDocument}
                                                                     alt={'personal-document-icon'}/>
                                                            </ListItemIcon>
                                                            {t('personalDocuments.select-document-2')}
                                                        </ListItem>
                                                        <Divider style={{marginLeft: '16px', marginRight: '16px'}}/>
                                                        <ListItem button style={{padding: '16px'}} value={3}
                                                                  className={'personal-documents-select-title'}
                                                                  onClick={() => {
                                                                      if (documentType === 3) {
                                                                          setDocumentType(0)
                                                                      } else {
                                                                          setDocumentType(3)
                                                                      }
                                                                      setDocumentTypeError(false)
                                                                      setTypeDivVisibility(false)
                                                                  }}>
                                                            <ListItemIcon>
                                                                <img src={personalDocument}
                                                                     alt={'personal-document-icon'}/>
                                                            </ListItemIcon>
                                                            {t('personalDocuments.select-document-3')}
                                                        </ListItem>
                                                        <Divider style={{marginLeft: '16px', marginRight: '16px'}}/>
                                                        <ListItem button style={{padding: '16px'}} value={4}
                                                                  className={'personal-documents-select-title'}
                                                                  onClick={() => {
                                                                      if (documentType === 4) {
                                                                          setDocumentType(0)
                                                                      } else {
                                                                          setDocumentType(4)
                                                                      }
                                                                      setDocumentTypeError(false)
                                                                      setTypeDivVisibility(false)
                                                                  }}>
                                                            <ListItemIcon>
                                                                <img src={personalDocument}
                                                                     alt={'personal-document-icon'}/>
                                                            </ListItemIcon>
                                                            {t('personalDocuments.select-document-4')}
                                                        </ListItem>
                                                        <Divider style={{marginLeft: '16px', marginRight: '16px'}}/>
                                                        <ListItem button style={{padding: '16px'}} value={5}
                                                                  className={'personal-documents-select-title'}
                                                                  onClick={() => {
                                                                      if (documentType === 5) {
                                                                          setDocumentType(0)
                                                                      } else {
                                                                          setDocumentType(5)
                                                                      }
                                                                      setDocumentTypeError(false)
                                                                      setTypeDivVisibility(false)
                                                                  }}>
                                                            <ListItemIcon>
                                                                <img src={personalDocument}
                                                                     alt={'personal-document-icon'}/>
                                                            </ListItemIcon>
                                                            {t('personalDocuments.select-document-5')}
                                                        </ListItem>
                                                    </List>
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    </div>
                                    <div className={'flex-wrap flex-row'}
                                         style={{display: 'flex', marginTop: '60px', gap: '16px'}}>
                                        <div onClick={() => setIsFavourite(current => !current)}>
                                            <img
                                                src={isFavourite ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                                alt="checkbox" height="20px"/>
                                        </div>
                                        <div onClick={() => setIsFavourite(current => !current)}>
                                            <h6 className={'darkgrey'}
                                                style={{textDecoration: 'underline'}}>{t('personalDocuments.save-favourite')}</h6>
                                        </div>
                                    </div>
                                </div>
                            }
                            <Container className="position-absolute"
                                       style={
                                           isDesktop ? {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 48,
                                               right: 0
                                           } : {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 68,
                                               right: 0
                                           }
                                       }>
                                <Row style={{textAlign: 'center'}}>
                                    <Col>
                                        <Button
                                            className={"primary-button"}
                                            variant="outlined"
                                            size="large"
                                            style={{
                                                color: themeContext.color.white,
                                                width: '288px',
                                                textTransform: 'none'
                                            }}
                                            type="submit"
                                            onClick={() => {
                                                createGroupWithAttachedDocument()
                                            }}>
                                            {
                                                isLoadingCreate ?
                                                    <img src={spinner} className="spinner-img" alt="spinner"/>
                                                    :
                                                    t('personalDocuments.save-button')
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </DialogContent>
                </Dialog>
                <DialogDoubleAction
                    open={openExitDialog}
                    handleClose={() => setOpenExitDialog(false)}
                    title={t('dialog.interruption-title')}
                    description={t('dialog.interruption-description')}
                    buttonYesText={t('dialog.button-yes')}
                    handleYes={() => {
                        if (window.location.href.includes('signing')) {
                            navigate('/signing');
                        } else if (window.location.href.includes('refunds')) {
                            navigate(`/refunds`);
                        } else if (window.location.href.includes('invitation')) {
                            navigate(`/settings/people/${personId}`);
                        } else if (window.location.href.includes('bank-references')) {
                            navigate(`/settings/people/${personId}/bank-references`);
                        }
                    }}
                    buttonNoText={t('dialog.button-no')}
                    handleNo={() => setOpenExitDialog(false)}
                ></DialogDoubleAction>
                <DialogSmall buttonText={t('uploadDocumentToVerify.fallback-dialog-button')}
                             title={t('uploadDocumentToVerify.fallback-dialog-title')}
                             description={t('uploadDocumentToVerify.fallback-dialog-description')}
                             handleClose={() => {
                                 if (window.location.href.includes('refunds')) {
                                     navigate(`/refunds`);
                                 } else if (window.location.href.includes('bank-references')) {
                                     navigate(`/settings/people/${personId}/bank-references`);
                                 }
                             }}
                             open={openFallbackDialog}/>
                <DialogSmall open={openSizeExceededDialog} title={t('Maximum upload size exceeded')}
                             buttonText={t('dialog.button-ok')}
                             handleClose={() => {
                                 setOpenSizeExceededDialog(false)
                             }} description={t('The maximum image upload size is 20mb')}/>
            </Container>
    )
}