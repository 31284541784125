import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import wideLogoLight from '../../images/wideLogo-icon.svg';
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import CryptoJS from 'crypto-js';
import * as idb from "idb";
import back from "../../images/back-icon.svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";

export default function ConfirmedPin() {
    useEffect(() => {
        setPinInputFocus(true);

        const dbPromise = idb.openDB('appDB', 1, {
            upgrade(upgradeDb) {
                if (!upgradeDb.objectStoreNames.contains('pin')) {
                    upgradeDb.createObjectStore('pin', {keyPath: 'key'});
                }
            }
        });
        dbPromise.then((db) => {
            let tx = db.transaction('pin', 'readwrite').objectStore('pin');
            return tx.get("pin")
        }).then((data) => {
            if (!!data) {
                setFirstPin(data.value);
            }
            setIsFirstPin(!!data);
        });
    }, []);

    //const [input, setInput] = useState("");
    const authCtx = useContext(AuthContextMiddleware);
    const [arrayPin, setArrayPin] = useState([null, null, null, null, null, null]);
    const [selectedCell, setSelectedCell] = useState(0);
    const [isFirstPin, setIsFirstPin] = useState(!!authCtx.isSetfirstPin);
    const [firstPin, setFirstPin] = useState();
    //console.log('first', firstPin)
    const [error, setError] = useState(authCtx.error);
    //const error = authCtx.error;
    //const token = authCtx.token;
    const token = sessionStorage.getItem('token')
    const editPin = sessionStorage.getItem('pin')
    const pinCodeUrl = `${process.env.REACT_APP_BASE_URL}/profile/pinCode`;
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [serviceError, setServiceError] = useState(false);



    const onSubmitHandler = event => {
        let strPin = "";
        for (let i = 0; i < arrayPin.length; i++) {
            strPin = strPin + arrayPin[i];
        }
        //decripto il firstPin dopo il secondo inserimento, e se i due pin sono uguali inserisco
        //il pin a db
        let bytes = CryptoJS.AES.decrypt(firstPin, process.env.REACT_APP_SECRET_KEY);
        let firstPinDecrypted = bytes.toString(CryptoJS.enc.Utf8);
        if (firstPinDecrypted === strPin) {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({pinCode: firstPin})
            };
            fetch(pinCodeUrl, requestOptions)
                .then(response => {
                    localStorage.setItem('pinIsActive', true)
                    authCtx.loginConfirmedPin(firstPin);
                    return navigate('/settings/profile')
                });
        } else {
            authCtx.wrongPin()
            console.log('wrong')
            return navigate('/settings/profile/code')
        }
    };

    const handlePin = index => {
        setPinInputFocus(true);
        return null;
    }

    const setPinInputFocus = event => {
        const input = document.getElementById("pin-input");
        if (event && input) {
            input.focus();
        } else if (input) {
            input.blur();
        }
    }

    const handleInput = event => {
        if (!isNaN(event.key)) {
            if (selectedCell < 6) {
                arrayPin[selectedCell] = parseInt(event.key) // non scrive nella casella finale
                document.getElementById(`dot-${selectedCell}`).classList.remove('hidden');
            }
            if (selectedCell < 5) {
                setSelectedCell(selectedCell + 1);
            } else if (selectedCell >= 5) {
                setPinInputFocus(false)
                if (selectedCell === 5) {
                    setSelectedCell(selectedCell + 1);
                    onSubmitHandler();
                }
            }
        } else if (event.key === 'Backspace') {
            let actual = selectedCell;
            if (selectedCell > 0) {
                setSelectedCell(selectedCell - 1);
                actual--;
            }
            arrayPin[actual] = null;
            document.getElementById(`dot-${actual}`).classList.add('hidden');
        } else {
        }
    }

    const handleBack = () => {
        /*
        if (activePin) {
            authCtx.undoSetPin();
            navigate(-1)
        } else if (editPin) {
            //
        } else{
            authCtx.undoChangePin();
            navigate('/login');
        }
        */
        authCtx.undoSetPin();
        navigate('/settings/profile')
    }

    return (
        <div className="container-fluid" style={{'backgroundColor': themeContext.color.white}}>
            <div className="row no-gutter">
                <div className="col-md-6 d-none d-md-flex bg-image"></div>
                <div className="col-md-6 ">
                    <Row className="nav-top container" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col className="col-4 float-left">
                            <img src={back} onClick={handleBack} className="nav-top-logo float-left cursor-pointer"
                                 height="27px"
                                 alt="back"/>
                        </Col>
                    </Row>
                    <div className="login d-flex align-items-center">
                        <div className="container">
                            <Row className="d-flex justify-content-center align-items-center">
                                <Container className="d-flex justify-content-center align-items-center">
                                    <Row className="w-100">
                                        <img className="wide-logo" src={wideLogoLight} height="50px" alt="wide-logo"/>
                                    </Row>
                                </Container>
                                <Container>
                                    <Row className="mt-4 align-center">
                                        <Col>
                                            <h6 className="pin-text-top font-weight-bold text-uppercase">{t('confirmed-pin.text-top')}</h6>
                                            {!isFirstPin && error &&
                                                <h6 className="pin-text-top font-weight-bold red text-uppercase">{t('confirmed-pin.text-top-error')}</h6>}
                                        </Col>
                                    </Row>
                                    <Row className="mt-1 align-center">
                                        <Col>
                                            <h6 className="pin-text-top">{t('confirmed-pin.text-mid')}</h6>
                                            {!isFirstPin && error &&
                                                <h6 className="pin-text-top">{t('confirmed-pin.text-mid-error')}</h6>}
                                        </Col>
                                    </Row>
                                    <Row className="mt-1 align-center">
                                        <Col>
                                            <h6 className="pin-text-top">{t('pin.text-criterias')}</h6>
                                        </Col>
                                    </Row>
                                    {!error &&
                                        <Row className="mt-4 align-center row-pin" onClick={handlePin}>

                                            <Col className="pin m-2">
                                                <span className="dot hidden" id="dot-0"></span>
                                            </Col>
                                            <Col className="pin m-2">
                                                <span className="dot hidden" id="dot-1"></span>
                                            </Col>
                                            <Col className="pin m-2">
                                                <span className="dot hidden" id="dot-2"></span>
                                            </Col>
                                            <Col className="pin m-2">
                                                <span className="dot hidden" id="dot-3"></span>
                                            </Col>
                                            <Col className="pin m-2">
                                                <span className="dot hidden" id="dot-4"></span>
                                            </Col>
                                            <Col className="pin m-2">
                                                <span className="dot hidden" id="dot-5"></span>
                                            </Col>
                                        </Row>
                                    }
                                    {error &&
                                        <Row className="mt-4 align-center row-pin" onClick={handlePin}>

                                            <Col className="pin m-2 pin-red">
                                                <span className="dot dot-red hidden" id="dot-0"></span>
                                            </Col>
                                            <Col className="pin m-2 pin-red">
                                                <span className="dot dot-red hidden" id="dot-1"></span>
                                            </Col>
                                            <Col className="pin m-2 pin-red">
                                                <span className="dot dot-red hidden" id="dot-2"></span>
                                            </Col>
                                            <Col className="pin m-2 pin-red">
                                                <span className="dot dot-red hidden" id="dot-3"></span>
                                            </Col>
                                            <Col className="pin m-2 pin-red">
                                                <span className="dot dot-red hidden" id="dot-4"></span>
                                            </Col>
                                            <Col className="pin m-2 pin-red">
                                                <span className="dot dot-red hidden" id="dot-5"></span>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className="div-pin mb-4">
                                        <Col>
                                            <input type="number" inputMode="numeric" id="pin-input"
                                                   onKeyDown={handleInput}/>
                                        </Col>
                                    </Row>
                                    <Container className="end-of-the-page"></Container>
                                    <Row className="mt-4 align-center">
                                        <Col>
                                            <h6 className="pin-text-bottom">{t('confirmed-pin.info')}</h6>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container className="end-of-the-page"></Container>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

