import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next'
import StackGrid from "react-stack-grid";
import {withSize} from "react-sizeme";
import FsLightbox from "fslightbox-react";
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import documentPlaceholder from "../../images/document-placeholder.svg";
import trashIcon from "../../images/trash-icon.svg";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import CropDialog from "../components/CropDialog.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import DialogSmall from "../components/DialogSmall.js";
import themeContext from "../components/ThemeContext.js";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const useStyles = makeStyles((theme) => ({
    backDrop: {
        backdropFilter: "blur(16px)",
        backgroundColor: themeContext.color.shadowBlack,
        opacity: 1
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    imageList: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

function AttachImagesToClaimForm({size}) {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const params = useParams();
    const claimId = params.claimId;
    const getClaimAttachmentUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide`;
    const addAttachmentToClaimUrl = `${process.env.REACT_APP_BASE_URL}/media/claims`;
    const removeAttachmentFromClaimUrl = `${process.env.REACT_APP_BASE_URL}/media/claims`;
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState(-1);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [cropper, setCropper] = useState(null);
    const [serviceError, setServiceError] = useState(false);
    const [openPhotoLimitsDialog, setOpenPhotoLimitsDialog] = useState(false);
    const cropperRef = useRef(null);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    const ids = useRef([]);
    const [images, setImages] = useState([]);


    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(index) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index
        });
    }

    function reload() {
        window.location.reload();
    }

    useEffect(() => {
        if (navigator.onLine) {
            let claimImages = window.location.href.includes('attachAccidentReportImages') ? JSON.parse(sessionStorage.getItem(`${claimId}_accident_report_images`)) : JSON.parse(sessionStorage.getItem(`${claimId}_images`));
            if (claimImages && claimImages.length > 0) {
                ids.current = claimImages
                for (let i = 0; i < claimImages.length; i++) {
                    getClaimAttachment(claimImages[i])
                }
            }
        }
    }, []);

    const turnBack = () => {
        if (window.location.href.includes('attachAccidentReportImages')) {
            sessionStorage.setItem(`${claimId}_accident_report_images`, JSON.stringify(ids.current))
        } else {
            sessionStorage.setItem(`${claimId}_images`, JSON.stringify(ids.current))
        }
        if (window.location.href.includes('car')) {
            return navigate(`/claims/open/carClaim/${claimId}`);
        } else {
            return navigate(`/claims/open/genericClaim/${claimId}`);
        }

    }

    const getClaimAttachment = (attachmentId) => {
        setIsLoadingData(true)
        fetch(`${getClaimAttachmentUrl}/${claimId}/attachment/${attachmentId}/download`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.blob();
        }).then((data) => {
            let urlCreator = window.URL || window.webkitURL;
            let imageUrl = urlCreator.createObjectURL(data);
            setImages(current => [...current, imageUrl])
            setIsLoadingData(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const handleChangeImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCroppedCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            const randomName = Math.floor((Math.random() * 100000000) + 1)
            let split1 = cropImage.split(":");
            let split2 = split1[1].split(";");
            let mimetype = split2[0];
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            setIsLoadingImg(true);
            fetch(`${addAttachmentToClaimUrl}/${claimId}/attachments/new`, {
                method: 'POST',
                body: JSON.stringify({
                    name: randomName,
                    mimeType: mimetype,
                    base64: base64_string,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': true
                },
            }).then((res) => {
                if (res.ok) {
                    //alert upload completato
                    return res.json();
                }
            }).then((res) => {
                setIsLoadingImg(false);
                setOpenCropDialog(false);
                ids.current.push(res.id)
                if (window.location.href.includes('attachAccidentReportImages')) {
                    sessionStorage.setItem(`${claimId}_accident_report_images`, JSON.stringify(ids.current))
                } else {
                    sessionStorage.setItem(`${claimId}_images`, JSON.stringify(ids.current))
                }
                return res.id;
            }).then((attachment) => {
                getClaimAttachment(attachment);
            }).catch(() => {
                setServiceError(true)
            });
        }
    }

    const handleDeleteImage = (index) => {
        const attachmentId = ids.current[index];
        fetch(`${removeAttachmentFromClaimUrl}/${claimId}/attachments/${attachmentId}/remove`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                ids.current = ids.current.filter(id => {
                    return id !== attachmentId;
                })
                setImages([])
                if (window.location.href.includes('attachAccidentReportImages')) {
                    sessionStorage.setItem(`${claimId}_accident_report_images`, JSON.stringify(ids.current))
                } else {
                    sessionStorage.setItem(`${claimId}_images`, JSON.stringify(ids.current))
                }
                if (ids.current.length > 0) {
                    for (let i = 0; i < ids.current.length; i++) {
                        if (ids.current[i] !== attachmentId) {
                            getClaimAttachment(ids.current[i]);
                        }
                    }
                }
            }
            setOpenDialog2(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const openDeleteImageDialog = (index) => {
        setIndexToDelete(index);
        setOpenDialog2(true)
    }

    const handleOnPendingImage = async (e) => {
        try {
            e.preventDefault();
            let file = e.target.files[0];
            const imageResized = await resizeFile(file)
            setOnPendingImage(imageResized);
            setOpenCropDialog(true)
        } catch (err) {
            console.log(err)
        }

    };

    const attachImageActions = (documentType, imagesLength) => {
        if (imagesLength >= 15) {
            setOpenPhotoLimitsDialog(true);
        } else {
            handleChangeImage();
        }
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2"></h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container col-list border-bottom" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingBottom': '20px',
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col className="col-2 float-left">
                            <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                                 height="27px"
                                 alt="back"/>
                        </Col>
                        <Col className="col-8">
                            <h6 className="header-title ellipsis-1-v2">
                                <span>{t('attachImagesToClaimForm.title')}</span>
                            </h6>
                        </Col>
                    </Row>
                    <Container className="top-of-the-preferences"></Container>
                    {
                        isLoadingData &&
                        <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                    }
                    {
                        !isLoadingData && images.length === 0 &&
                        <Container>
                            <Row style={{
                                'marginTop': '43.5px',
                                'marginBottom': '44.5px'
                            }}>
                                <Col className="pl-5 pr-5">
                        <span style={{
                            'fontSize': '18px',
                            'color': themeContext.color.main,
                            'fontWeight': 'bold'
                        }}>{window.location.href.includes('attachAccidentReportImages') ? t('attachImagesToClaimForm.placeholder-text-accident-report') : t('attachImagesToClaimForm.placeholder-text')}</span>
                                </Col>
                            </Row>
                            <Row>
                                <img src={documentPlaceholder} alt="document-placeholder" width="100%"
                                     style={{'maxHeight': '500px'}}/>
                            </Row>
                        </Container>
                    }
                    {
                        !isLoadingData &&
                        <Row className="mt-4">
                            <Col>
                                <StackGrid
                                    columnWidth={size.width <= 768 ? '45%' : '30%'}
                                    style={{'zIndex': '0'}}
                                    monitorImagesLoaded={true}
                                >
                                    {
                                        images.map((item, index) => (
                                            <div key={index} className="image-div">
                                                <img src={item} alt={`image-${index}`} style={{
                                                    'maxWidth': '100%',
                                                    'borderRadius': '8px',
                                                    'border': `${themeContext.spacing.borders.border1} ${themeContext.color.main}`
                                                }}
                                                     onClick={() => {
                                                         openLightboxOnSlide(index);
                                                     }}
                                                />
                                                <img className="trash-button" src={trashIcon} alt="trash-icon"
                                                     onClick={() => openDeleteImageDialog(index)}/>
                                            </div>
                                        ))
                                    }
                                </StackGrid>
                                <FsLightbox
                                    toggler={lightboxController.toggler}
                                    sources={images}
                                    type="image"
                                    sourceIndex={lightboxController.slide}
                                    key={0}
                                />
                            </Col>
                        </Row>
                    }
                </Container>
                {
                    !isLoadingData && images.length !== 0 &&
                    <Container style={{
                        'background': themeContext.color.white,
                        'paddingBottom': "80px"
                    }}></Container>
                }
                <Container className="fixed-bottom"
                           style={{'paddingBottom': "40px"}}>
                    <Row>
                        <Col>
                            <Button id="attachImage"
                                    className="btn-custom text-uppercase"
                                    variant="outlined"
                                    size="large"
                                    style={{'color': themeContext.color.white}} type="submit"
                                    onClick={() => attachImageActions(documents.type, images.length)}
                            >{t('attachImagesToClaimForm.button')}</Button>
                            <input hidden id="image-file" type="file" onChange={handleOnPendingImage} accept="image/*"/>
                        </Col>
                    </Row>
                </Container>
                <DialogSmall open={openPhotoLimitsDialog}
                             title={t('documentsList.limit-photo-modal-title')}
                             description={t('documentsList.limit-photo-modal-description')}
                             handleClose={() => setOpenPhotoLimitsDialog(false)}
                             buttonText={t('documentsList.limit-photo-modal-button')}/>
                <DialogDoubleAction open={openDialog2}
                                    title={t('documentsList.modal-title-2')}
                                    description={t('documentsList.modal-description-2')}
                                    handleClose={() => setOpenDialog2(false)} handleNo={() => setOpenDialog2(false)}
                                    handleYes={() => handleDeleteImage(indexToDelete)}
                                    buttonYesText={t('documentsList.modal-confirm-button')}
                                    buttonNoText={t('documentsList.modal-refuse-button')}/>
                <CropDialog open={openCropDialog}
                            handleAttach={attachCroppedImage}
                            handleClose={() => {
                                setOpenCropDialog(false)
                                setIsLoadingImg(false)
                            }}
                            isLoading={isLoadingImg}
                            onPendingImage={onPendingImage}
                            cropperRef={cropperRef}
                            setCropper={(data) => setCropper(data)}/>
            </Fragment>
    )
}

export default withSize()(AttachImagesToClaimForm)
