import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/it';
import toRead from '../../images/toRead-icon.png';
import toReadGray from '../../images/toReadGray-icon.png';
import document from '../../images/documents-icon.svg';
import payment from '../../images/payment-icon.svg';
import calendar from '../../images/calendar-icon.svg';
import back from '../../images/back-icon.svg';
import nothing from '../../images/nothing-icon.svg';
import {ClientJS} from 'clientjs';
import {SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType,} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import {
    ActionContent,
    ItemColumn,
    ItemContent,
    ItemInfoLine,
    ItemNameLine,
    ItemRow,
} from '../middleware/StyleMiddleware.js';
import {onMessageListener} from "../middleware/Firebase.js";
import {getElementFromIndexedDB, isIpad, isMac, putElementFromIndexedDB} from "../service/UtilityService.js";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";

const useStyles = makeStyles({
    root: {
        backgroundColor: "white",
        width: "100%",
    },
    labelContainer: {
        paddingLeft: 0,
        paddingRight: 0
    }
});

export default function Messages(styles) {
    const [countLoadingSX, setCountLoadingSX] = useState(0);
    const [isLoadingDX, setIsLoadingDX] = useState(false);
    const [serviceError, setServiceError] = useState(false);

    useEffect(() => {
        setCountLoadingSX(0);
        if (!navigator.onLine) {
            getAllNormalOffline();
        } else {
            getAllNormal();
        }
        getAllArchived();
        getAllImportant();
    }, []);

    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const [tab, setTab] = useState(sessionStorage.getItem('tab_messages') ? parseInt(sessionStorage.getItem('tab_messages')) : 0 );
    const classes = useStyles();

    let tabStyle = {
        borderBottom: `${themeContext.spacing.borders.border1} ${themeContext.color.neutral100}`,
        width: '100%',
    };

    let tabClass = {};

    let labelStyle = {
        textTransform: "none",
        fontWeight: "700",
        fontSize: "12px",
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        width: '100%',
        justifyContent: 'center'
    };

    const getAllNormalUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/normal`;
    const archivedUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/archive`;
    const getAllImportantUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/important`;
    const updateUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/principal`;
    const resumeUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/resume`;
    const [isNothingFirst, setIsNothingFirst] = useState(0);
    const [isNothingSecond, setIsNothingSecond] = useState();
    const [normalArray, setNormalArray] = useState([]);
    const [archivedArray, setArchivedArray] = useState([]);
    const [importantArray, setImportantArray] = useState([]);
    const [normalIsRead, setNormalIsRead] = useState(0);
    const [importantIsRead, setImportantIsRead] = useState(0);
    const [archiveIsRead, setArchiveIsRead] = useState(0);
    const isFilter = useRef(false);
    const token = sessionStorage.getItem('token');
    const {t, i18n} = useTranslation();


    const client = new ClientJS();

    if (client.isMobileIOS() || isIpad() || isMac()) {
        //console.log('notification not allowed')
    } else {
        onMessageListener().then(payload => {
            setCountLoadingSX(0)
            getAllNormal();
            getAllImportant();
        }).catch(err => console.log('failed: ', err));
    }

    function turnBack() {
        sessionStorage.removeItem('tab_messages');
        return navigate(-1);
    }

    function handleTabs(event, value) {
        setTab(value);
        sessionStorage.setItem('tab_messages', value);
    }

    function getAllNormal() {
        let count = 0;
        let filter = "";
        if (isFilter.current) {
            filter = "?type=notRead";
        }
        let url = `${getAllNormalUrl}${filter}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length) {
                setIsNothingFirst(0);
            } else {
                setIsNothingFirst(current => current + 1);
            }
            setNormalArray([]);
            for (let i = 0; i < length; i++) {
                if (!normalArray.includes(data[i])) {
                    setNormalArray(current => [...current, data[i]])
                    if (!data[i].isRead) {
                        count++;
                    }
                }
            }
            if (count > 0) {
                setNormalIsRead(count)
            }
            setCountLoadingSX(current => current + 1)
            putElementFromIndexedDB('notifications', data)
            return normalArray;
        }).catch(() => {
            setServiceError(true)
        })
    }

    function getAllNormalOffline() {
        let count = 0;
        getElementFromIndexedDB('notifications').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length) {
                setIsNothingFirst(0);
            } else {
                setIsNothingFirst(current => current + 1);
            }
            setNormalArray([]);
            for (let i = 0; i < length; i++) {
                if (!normalArray.includes(data[i])) {
                    setNormalArray(current => [...current, data[i]])
                    if (!data[i].isRead) {
                        count++;
                    }
                }
            }
            if (count > 0) {
                setNormalIsRead(count)
            }
            setCountLoadingSX(current => current + 1)
            putElementFromIndexedDB('notifications', data)
            return normalArray;
        })
    }

    function getAllArchived() {
        setIsLoadingDX(true)
        let count = 0;
        let filter = "";
        if (isFilter.current) {
            filter = "?type=notRead";
        }
        let url = `${archivedUrl}${filter}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length) {
                setIsNothingSecond(false);
            } else {
                setIsNothingSecond(true);
            }
            setArchivedArray([])
            for (let i = 0; i < length; i++) {
                if (!archivedArray.includes(data[i])) {
                    setArchivedArray(current => [...current, data[i]])
                    if (!data[i].isRead) {
                        count++;
                    }
                }
            }
            if (count > 0) {
                setArchiveIsRead(count)
            }
            setIsLoadingDX(false)
            return archivedArray;
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getAllImportant() {
        let count = 0;
        let filter = "";
        if (isFilter.current) {
            filter = "?type=notRead";
        }
        let url = `${getAllImportantUrl}${filter}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length) {
                setIsNothingFirst(0);
            } else {
                setIsNothingFirst(current => current + 1);
            }
            setImportantArray([]);
            for (let i = 0; i < length; i++) {
                if (!importantArray.includes(data[i])) {
                    setImportantArray(current => [...current, data[i]])
                    if (!data[i].isRead) {
                        count++;
                    }
                }
            }
            if (count > 0) {
                setImportantIsRead(count)
            }
            setCountLoadingSX(current => current + 1)
            return importantArray;
        }).catch(() => {
            setServiceError(true)
        })
    }

    const trailingActions = (element) => (
        <TrailingActions>
            <SwipeAction style={{'overflow': 'unset'}} onClick={() => {
                if (element.isImportant === true) {
                    handleNotImportant(element)
                } else {
                    handleImportant(element);
                }
            }}>
                <ActionContent style={{backgroundColor: themeContext.color.mainActive}}>
                    {
                        element.isImportant === true ? t('messages.not-important-scrollable-button') : t('messages.important-scrollable-button')
                    }
                </ActionContent>
            </SwipeAction>
            <SwipeAction destructive={true} onClick={() => {
                handleArchived(element);
            }}>
                <ActionContent style={{backgroundColor: themeContext.color.red}}>
                    {t('messages.archived-scrollable-button')}
                </ActionContent>
            </SwipeAction>
        </TrailingActions>
    );

    const trailingActionsArchived = (element) => (
        <TrailingActions>
            <SwipeAction
                destructive={true}
                style={{'overflow': 'unset'}}
                onClick={() => {
                    handleResume(element)
                }}>
                <ActionContent style={{backgroundColor: themeContext.color.mainActive}}>
                    {t('messages.restore-scrollable-button')}
                </ActionContent>
            </SwipeAction>
        </TrailingActions>
    );

    const handleImportant = (element) => {
        fetch(`${updateUrl}/${element.id}`, {
            method: 'PUT',
            body: JSON.stringify({
                isImportant: true,
                isRead: element.isRead,
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response;
        }).then(() => {
            setCountLoadingSX(0)
            getAllNormal();
            getAllImportant();
        });
    }

    const handleNotImportant = (element) => {
        fetch(`${updateUrl}/${element.id}`, {
            method: 'PUT',
            body: JSON.stringify({
                isImportant: false,
                isRead: element.isRead,
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response;
        }).then(() => {
            setCountLoadingSX(0)
            getAllNormal();
            getAllImportant();
        });
    }

    const handleArchived = (element) => {
        fetch(`${archivedUrl}/${element.id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then(() => {
            setCountLoadingSX(0)
            getAllNormal();
            getAllArchived();
            getAllImportant();
        })
    }

    const handleResume = (element) => {
        fetch(`${resumeUrl}/${element.id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then(() => {
            setCountLoadingSX(0)
            getAllNormal();
            getAllArchived();
            getAllImportant();
        })
    }

    const onClickMessage = (id, type) => {
        return navigate(`/messages/${type}/${id}`);
    }

    const handleIsFilter = (value) => {
        isFilter.current = value;
        setCountLoadingSX(0)
        getAllNormal();
        getAllImportant();
        getAllArchived();
    }

    const nothingFunction = () => {
    }

    function reload() {
        window.location.reload();
    }


    return (
        serviceError ?
            <Container className="pt-5 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Container style={{'paddingTop': '10%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container" style={{
                        'paddingLeft': '0',
                        'paddingRight': '0',
                        //'borderLeft': '1px solid #EEE9E5',
                        //'borderRight': '1px solid #EEE9E5'
                    }}>
                        <Col>
                            <Row className="first-nav-top-row-shadow">
                                <Col className='col-6 float-left'>
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px" alt="back"/>
                                </Col>
                            </Row>
                            <Row style={{
                                background: themeContext.color.white,
                                paddingTop: '11px'
                            }}>
                                <Col className="col-4">
                                    <h2 className="float-left title-page">{t('messages.title')}</h2>
                                </Col>
                                <Col className="col-8 mt-1">
                                    {isFilter.current &&
                                        <Button className="filter-btn float-right" color="primary"
                                                style={{'color': 'themeContext.color.main'}}
                                                onClick={() => handleIsFilter(false)}><span
                                            className="material-symbols-outlined"
                                            style={{
                                                'fontSize': '22px',
                                                'paddingRight': '2px',
                                                'paddingBottom': '3px'
                                            }}>filter_list</span>{t('messages.filter-active')}
                                        </Button>}

                                    {!isFilter.current &&
                                        <Button className="filter-btn float-right"
                                                style={{'color': themeContext.color.neutral400}}
                                                onClick={() => handleIsFilter(true)}><span
                                            className="material-symbols-outlined"
                                            style={{'fontSize': '22px'}}>filter_list</span>{t('messages.filter-inactive')} {tab === 0 ? normalIsRead + importantIsRead : archiveIsRead}
                                        </Button>}
                                </Col>
                            </Row>
                            <Row>
                                <div className={classes.root}>
                                    <Tabs
                                        value={tab}
                                        onChange={handleTabs}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth">
                                        <Tab
                                            key="Ricevuti"
                                            label={<span style={labelStyle}>{(normalIsRead + importantIsRead > 0) &&
                                                <img alt="to-read" src={tab === 0 ? toRead : toReadGray}
                                                     width="12px"/>}{t('messages.tab-active')}</span>}
                                            classes={tabClass}
                                            style={tabStyle}
                                        />
                                        <Tab
                                            key="Archiviati"
                                            label={<span style={labelStyle}>{archiveIsRead > 0 &&
                                                <img alt="to-read" src={tab === 1 ? toRead : toReadGray}
                                                     width="12px"/>}{t('messages.tab-inactive')}</span>}
                                            classes={tabClass}
                                            style={tabStyle}
                                        />
                                    </Tabs>
                                </div>
                            </Row>
                        </Col>
                    </Row>

                    <Container className="top-of-the-messages"></Container>
                    <Container style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingRight': '0px',
                        'paddingLeft': '0px',
                    }}>
                        {countLoadingSX < 2 && tab === 0 &&
                            <div style={{'height': '70vh'}} className="background-loading-spinner"></div>
                        }
                        {isLoadingDX && tab === 1 &&
                            <div style={{'height': '70vh'}} className="background-loading-spinner"></div>
                        }
                        {
                            countLoadingSX === 2 && isNothingFirst > 1 && tab === 0 &&
                            <Container>
                                <Row>
                                    <img src={nothing} className="noMessages"
                                         alt={t('messages.nothing-text-1')}
                                    ></img>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className="no-messages-text">{t('messages.nothing-text-1')}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className="no-messages-text">{t('messages.nothing-text-2')}</span>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        {
                            !isLoadingDX && isNothingSecond && tab === 1 &&
                            <Container>
                                <Row>
                                    <img src={nothing} className="noMessages"
                                         alt={t('messages.nothing-text-1')}
                                    ></img>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className="no-messages-text">{t('messages.nothing-text-1')}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className="no-messages-text">{t('messages.nothing-text-2')}</span>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        <Row>
                            {
                                tab === 0 && countLoadingSX === 2 &&
                                <SwipeableList
                                    type={ListType.IOS}
                                    fullSwipe={true}>
                                    {
                                        importantArray.map((element) => (
                                                <SwipeableListItem
                                                    key={element.id}
                                                    //leadingActions={leadingActions()}
                                                    trailingActions={navigator.onLine ? trailingActions(element) : nothingFunction()}
                                                    className="list-item w-100">
                                                    <ItemContent
                                                        onClick={() => onClickMessage(element.id, 'principal')}>
                                                        <ItemRow>
                                                            <ItemColumn className="col-pin">
                                                            <span
                                                                className="material-symbols-outlined pin-icon">push_pin</span>
                                                            </ItemColumn>
                                                            <ItemColumn style={{'marginTop': '14px'}}>
                                                                <ItemNameLine
                                                                    className="ellipsis-1 black">{element.title}</ItemNameLine>
                                                                <ItemInfoLine className="ellipsis-1 black">
                                                                    {!element.isRead &&
                                                                        <img alt="to-read" src={toRead} width="12px"
                                                                             className="mb-2 mr-2"/>}{element.content}
                                                                </ItemInfoLine>
                                                            </ItemColumn>
                                                            <ItemColumn className="col-min mr-0 align-items-end">
                                                                <ItemNameLine className="black">
                                                                    <Moment locale="it"
                                                                            format="D MMM">{element.originalDate}</Moment>
                                                                </ItemNameLine>
                                                                <ItemInfoLine>
                                                                    {element.type === 'DOCUMENT' &&
                                                                        <img src={document} height={'33px'}
                                                                             alt="document"/>}
                                                                    {element.type === 'PAYMENT' &&
                                                                        <img src={payment} height={'33px'} alt="payment"/>}
                                                                    {element.type === 'CALENDAR' &&
                                                                        <img src={calendar} height={'33px'}
                                                                             alt="calendar"/>}
                                                                </ItemInfoLine>
                                                            </ItemColumn>
                                                        </ItemRow>
                                                    </ItemContent>
                                                </SwipeableListItem>
                                            )
                                        )}
                                    {
                                        normalArray.map((element) => (
                                                <SwipeableListItem
                                                    key={element.id}
                                                    //leadingActions={leadingActions()}
                                                    trailingActions={navigator.onLine ? trailingActions(element) : nothingFunction()}
                                                    className="list-item w-100">
                                                    <ItemContent
                                                        onClick={() => onClickMessage(element.id, 'principal')}>
                                                        <ItemRow>
                                                            <ItemColumn style={{'marginTop': '14px'}}>
                                                                <ItemNameLine
                                                                    className="ellipsis-1 black">{element.title}</ItemNameLine>
                                                                <ItemInfoLine className="ellipsis-1 black">
                                                                    {!element.isRead &&
                                                                        <img alt="to-read" src={toRead} width="12px"
                                                                             className="mb-2 mr-2"/>}{element.content}
                                                                </ItemInfoLine>
                                                            </ItemColumn>
                                                            <ItemColumn className="col-min mr-0 align-items-end">
                                                                <ItemNameLine className="black">
                                                                    <Moment locale="it"
                                                                            format="D MMM">{element.originalDate}</Moment>
                                                                </ItemNameLine>
                                                                <ItemInfoLine>
                                                                    {element.type === 'DOCUMENT' &&
                                                                        <img src={document} height={'33px'}
                                                                             alt="document"/>}
                                                                    {element.type === 'PAYMENT' &&
                                                                        <img src={payment} height={'33px'} alt="payment"/>}
                                                                    {element.type === 'CALENDAR' &&
                                                                        <img src={calendar} height={'33px'}
                                                                             alt="calendar"/>}
                                                                </ItemInfoLine>
                                                            </ItemColumn>
                                                        </ItemRow>
                                                    </ItemContent>
                                                </SwipeableListItem>
                                            )
                                        )}
                                </SwipeableList>
                            }
                            {
                                !isLoadingDX && tab === 1 &&
                                <SwipeableList
                                    type={ListType.IOS}
                                    fullSwipe={true}>
                                    {
                                        archivedArray.map((element) => (
                                                <SwipeableListItem
                                                    key={element.id}
                                                    //leadingActions={leadingActions()}
                                                    trailingActions={navigator.onLine ? trailingActionsArchived(element) : nothingFunction()}
                                                    className="list-item w-100">
                                                    <ItemContent
                                                        onClick={() => onClickMessage(element.id, 'archive')}>
                                                        <ItemRow>
                                                            <ItemColumn style={{'marginTop': '14px'}}>
                                                                <ItemNameLine
                                                                    className="ellipsis-1 black">{element.title}</ItemNameLine>
                                                                <ItemInfoLine className="ellipsis-1 black">
                                                                    {!element.isRead &&
                                                                        <img alt="to-read" src={toRead} width="12px"
                                                                             className="mb-2 mr-2"/>}{element.content}
                                                                </ItemInfoLine>
                                                            </ItemColumn>
                                                            <ItemColumn className="col-min mr-0 align-items-end">
                                                                <ItemNameLine className="black">
                                                                    <Moment locale="it"
                                                                            format="D MMM">{element.originalDate}</Moment>
                                                                </ItemNameLine>
                                                                <ItemInfoLine>
                                                                    {element.type === 'DOCUMENT' &&
                                                                        <img src={document} height={'33px'}
                                                                             alt="document"/>}
                                                                    {element.type === 'PAYMENT' &&
                                                                        <img src={payment} height={'33px'} alt="payment"/>}
                                                                    {element.type === 'CALENDAR' &&
                                                                        <img src={calendar} height={'33px'}
                                                                             alt="calendar"/>}
                                                                </ItemInfoLine>
                                                            </ItemColumn>
                                                        </ItemRow>
                                                    </ItemContent>
                                                </SwipeableListItem>
                                            )
                                        )}
                                </SwipeableList>
                            }
                        </Row>
                        <Container className="bottom-with-navbar"></Container>
                    </Container>
                    <Container className="mb-2 fixed-bottom">
                    </Container>
                </Container>
            </Fragment>
    )
}