import React, {Fragment, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/it';
import back from '../../images/back-icon.svg';
import nothing from '../../images/nothing-icon.svg';
import {ClientJS} from 'clientjs';
import {SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType,} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import {
    ActionContent,
    ItemColumn,
    ItemContent,
    ItemInfoLine,
    ItemNameLine,
    ItemRow,
} from '../middleware/StyleMiddleware.js';
import {onMessageListener} from "../middleware/Firebase.js";
import {getElementFromIndexedDB, isIpad, isMac} from "../service/UtilityService.js";
import {t} from "i18next";
import Chips from "../components/Chips.js";
import themeContext from "../components/ThemeContext.js";

const useStyles = makeStyles({
    root: {
        backgroundColor: "white",
        width: "100%",
    },
    labelContainer: {
        paddingLeft: 0,
        paddingRight: 0
    }
});

export default function ClaimsHistory(styles) {
    const root = 'claimsHistory'
    const [countLoadingSX, setCountLoadingSX] = useState(false);
    const [isLoadingDX, setIsLoadingDX] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const chips = [{value: 0, label: t(`${root}.claims-history`)}, {value: 1, label: t(`${root}.archived`)}];

    useEffect(() => {
        setCountLoadingSX(false);
        if (!navigator.onLine) {
            getAllNormalOffline();
        } else {
            getAllNormal();
        }
        getAllArchived();
    }, []);

    const navigate = useNavigate();
    const [tab, setTab] = useState(sessionStorage.getItem('tab_claimsHistory') ? parseInt(sessionStorage.getItem('tab_claimsHistory')) : 0);
    const classes = useStyles();

    const getAllNormalUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/claims/normal`;
    const notifyUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/archive`;
    const archivedUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/claims/archive`;
    const resumeUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/resume`;
    const [isNothingFirst, setIsNothingFirst] = useState(0);
    const [isNothingSecond, setIsNothingSecond] = useState();
    const [normalArray, setNormalArray] = useState([]);
    const [archivedArray, setArchivedArray] = useState([]);
    const token = sessionStorage.getItem('token');

    const client = new ClientJS();

    if (client.isMobileIOS() || isIpad() || isMac()) {
        //console.log('notification not allowed')
    } else {
        onMessageListener().then(payload => {
            setCountLoadingSX(false)
            getAllNormal();
        }).catch(err => console.log('failed: ', err));
    }

    function turnBack() {
        sessionStorage.removeItem('tab_claimsHistory');
        return navigate(-1);
    }

    function handleTabs(value) {
        setTab(value);
        sessionStorage.setItem('tab_claimsHistory', value);
    }

    function getAllNormal() {
        fetch(getAllNormalUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length) {
                setIsNothingFirst(current => current + 1);
            } else {
                setIsNothingFirst(0);
            }
            setNormalArray(data)
            setCountLoadingSX(true)
        }).catch(() => {
            setServiceError(true)
        })
    }

    function getAllNormalOffline() {
        getElementFromIndexedDB('notifications').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length) {
                setIsNothingFirst(0);
            } else {
                setIsNothingFirst(current => current + 1);
            }
            setNormalArray([]);
            for (let i = 0; i < length; i++) {
                if (!normalArray.includes(data[i])) {
                    setNormalArray(current => [...current, data[i]])
                }
            }
            setCountLoadingSX(true)
            return normalArray;
        })
    }

    function getAllArchived() {
        setIsLoadingDX(true)
        fetch(archivedUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length) {
                setIsNothingSecond(false);
            } else {
                setIsNothingSecond(true);
            }
            setArchivedArray(data)
            setIsLoadingDX(false)
        }).catch(() => {
            setServiceError(true)
        });
    }


    const trailingActions = (element) => (
        <TrailingActions>
            <SwipeAction destructive={true} onClick={() => {
                handleArchived(element);
            }}>
                <ActionContent style={{backgroundColor: themeContext.color.red}}>
                    {t('messages.archived-scrollable-button')}
                </ActionContent>
            </SwipeAction>
        </TrailingActions>
    );

    const trailingActionsArchived = (element) => (
        <TrailingActions>
            <SwipeAction
                destructive={true}
                style={{'overflow': 'unset'}}
                onClick={() => {
                    handleResume(element)
                }}>
                <ActionContent style={{backgroundColor: themeContext.color.mainActive}}>
                    {t('messages.restore-scrollable-button')}
                </ActionContent>
            </SwipeAction>
        </TrailingActions>
    );

    const handleArchived = (element) => {
        fetch(`${notifyUrl}/${element.id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then(() => {
            setCountLoadingSX(false)
            getAllNormal();
            getAllArchived();
        })
    }

    const handleResume = (element) => {
        fetch(`${resumeUrl}/${element.id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then(() => {
            setCountLoadingSX(false)
            getAllNormal();
            getAllArchived();

        })
    }

    const onClickMessage = (id, type) => {
        return navigate(`/messages/${type}/${id}`);
    }

    function reload() {
        window.location.reload();
    }


    return (
        serviceError ?
            <Container className="pt-5 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Container style={{'paddingTop': '10%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container px-0">
                        <Col>
                            <div className={'nav-top'} style={{background: "none"}}>
                                <Row className="container px-0" style={{
                                    'backgroundColor': themeContext.color.white,
                                    'paddingTop': '19.5px',
                                }}>
                                    <Col className="col-2 float-left">
                                        <img src={back} onClick={turnBack}
                                             className="nav-top-logo float-left cursor-pointer"
                                             height="27px"
                                             alt="back"/>
                                    </Col>
                                    <Col className="col-8">
                                        <h6 className="header-title ellipsis-1-v2">{t('claimsHistory.title')}</h6>
                                    </Col>
                                    <Col className="col-2">

                                    </Col>
                                </Row>
                                <Chips chips={chips} selected={sessionStorage.getItem('tab_claimsHistory')}
                                       onChange={(value) => {
                                           handleTabs(value)
                                       }}/>
                            </div>
                        </Col>
                    </Row>
                    <Container className="top-of-the-claimsHistory"></Container>
                    <Container className={'px-0'} style={{
                        'backgroundColor': themeContext.color.white,
                    }}>
                        {!countLoadingSX && tab === 0 &&
                            <div style={{'height': '70vh'}} className="background-loading-spinner"></div>
                        }
                        {isLoadingDX && tab === 1 &&
                            <div style={{'height': '70vh'}} className="background-loading-spinner"></div>
                        }
                        {
                            countLoadingSX && isNothingFirst === 0 && tab === 0 &&
                            <Container>
                                <Row>
                                    <img src={nothing} className="noMessages"
                                         alt={t('messages.nothing-text-1')}
                                    ></img>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className="no-messages-text">{t('messages.nothing-text-1')}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className="no-messages-text">{t('messages.nothing-text-2')}</span>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        {
                            !isLoadingDX && isNothingSecond && tab === 1 &&
                            <Container>
                                <Row>
                                    <img src={nothing} className="noMessages"
                                         alt={t('messages.nothing-text-1')}
                                    ></img>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className="no-messages-text">{t('messages.nothing-text-1')}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className="no-messages-text">{t('messages.nothing-text-2')}</span>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        <Row>
                            {
                                tab === 0 && countLoadingSX &&
                                <SwipeableList
                                    type={ListType.IOS}
                                    fullSwipe={true}>
                                    {
                                        normalArray.map((element) => (
                                                <SwipeableListItem
                                                    key={element.id}
                                                    trailingActions={navigator.onLine ? trailingActions(element) : {}}
                                                    className="list-item w-100">
                                                    <ItemContent
                                                        onClick={() => onClickMessage(element.id, 'principal')}>
                                                        <ItemRow>
                                                            <ItemColumn style={{'marginTop': '14px'}}>
                                                                <ItemNameLine
                                                                    className="ellipsis-1 black">{element.title}</ItemNameLine>
                                                                <ItemInfoLine className="ellipsis-1 black">
                                                                    {element.content}
                                                                </ItemInfoLine>
                                                            </ItemColumn>
                                                            <ItemColumn className="col-min mr-0 align-items-end">
                                                                <ItemNameLine className="black">
                                                                    <Moment locale="it"
                                                                            format="D MMM">{element.originalDate}</Moment>
                                                                </ItemNameLine>
                                                            </ItemColumn>
                                                        </ItemRow>
                                                    </ItemContent>
                                                </SwipeableListItem>
                                            )
                                        )}
                                </SwipeableList>
                            }
                            {
                                !isLoadingDX && tab === 1 &&
                                <SwipeableList
                                    type={ListType.IOS}
                                    fullSwipe={true}>
                                    {
                                        archivedArray.map((element) => (
                                                <SwipeableListItem
                                                    key={element.id}
                                                    trailingActions={navigator.onLine ? trailingActionsArchived(element) : {}}
                                                    className="list-item w-100">
                                                    <ItemContent
                                                        onClick={() => onClickMessage(element.id, 'archive')}>
                                                        <ItemRow>
                                                            <ItemColumn style={{'marginTop': '14px'}}>
                                                                <ItemNameLine
                                                                    className="ellipsis-1 black">{element.title}</ItemNameLine>
                                                                <ItemInfoLine className="ellipsis-1 black">
                                                                    {element.content}
                                                                </ItemInfoLine>
                                                            </ItemColumn>
                                                            <ItemColumn className="col-min mr-0 align-items-end">
                                                                <ItemNameLine className="black">
                                                                    <Moment locale="it"
                                                                            format="D MMM">{element.originalDate}</Moment>
                                                                </ItemNameLine>
                                                            </ItemColumn>
                                                        </ItemRow>
                                                    </ItemContent>
                                                </SwipeableListItem>
                                            )
                                        )}
                                </SwipeableList>
                            }
                        </Row>
                    </Container>
                    <Container className="mb-2 fixed-bottom">
                    </Container>
                </Container>
            </Fragment>
    )
}