import React, {useState} from 'react'
import Dialog from "@material-ui/core/Dialog";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import rotateIcon from "../../images/rotate-icon.svg";
import flipIcon from "../../images/flip-icon.svg";
import DialogContent from "@material-ui/core/DialogContent";
import Cropper from "react-cropper";
import DialogActions from "@material-ui/core/DialogActions";
import spinner from "../../images/spinner.svg";
import {useTranslation} from "react-i18next";
import "cropperjs/dist/cropper.css";
import themeContext from "./ThemeContext.js";

const useStyles = makeStyles(() => ({
    backDrop: {
        backdropFilter: "blur(16px)",
        backgroundColor: themeContext.color.shadowBlack,
        opacity: 1
    },
}));

const CropDialog = ({open, handleAttach, handleClose, isLoading, onPendingImage, setCropper, cropperRef}) => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);

    const rotate = (method) => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        switch (method) {
            case 'left':
                cropper.rotate(-90);
                break;
            case 'right':
                cropper.rotate(90);
                break;
            case 'flip-x':
                cropper.scaleX(scaleX === 1 ? -1 : 1);
                setScaleX(scaleX === 1 ? -1 : 1);
                break;
            case 'flip-y':
                cropper.scaleY(scaleY === 1 ? -1 : 1);
                setScaleY(scaleY === 1 ? -1 : 1);
                break;
        }
    }

    return (
        <Dialog
            BackdropProps={{
                classes: {
                    root: classes.backDrop,
                },
            }}
            PaperProps={{
                style: {
                    'color': themeContext.color.neutral600,
                    'backgroundColor': 'transparent',
                    'boxShadow': 'none',
                    'width': '100%',
                    'margin': '0',
                }
            }}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <div className={'d-flex justify-content-between'}>
                <div className={'d-flex justify-content-start'}>
                    <DialogTitle id="alert-dialog-title" className="dialog-title justify-content-start"
                                 style={{'color': themeContext.color.white}}>
                        {t('personalDocuments.modal-crop-title')}
                    </DialogTitle>
                </div>
                <div className={'d-flex justify-content-end pr-2'}>
                    <Button onClick={() => rotate('left')}
                            style={{'color': themeContext.color.white}}>
                        <img src={rotateIcon} alt="rotate-icon"/>
                    </Button>
                    <Button onClick={() => rotate('flip-x')}
                            style={{'color': themeContext.color.white}}>
                        <img src={flipIcon} alt="rotate-icon"/>
                    </Button>
                </div>
            </div>
            <DialogContent>
                <Cropper
                    src={onPendingImage}
                    style={{width: "100%"}}
                    initialAspectRatio={16 / 9}
                    guides={true}
                    onInitialized={(instance) => {
                        setCropper(instance)
                    }}
                    background={false}
                    responsive={true}
                    zoomable={false}
                    ref={cropperRef}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} style={{'color': themeContext.color.white}}>
                    {t('personalDocuments.modal-refuse-button')}
                </Button>
                <Button style={{'color': themeContext.color.white}} onClick={() => handleAttach()}>
                    {
                        isLoading &&
                        <img src={spinner} className="spinner-img" alt="spinner"/>
                    }
                    {!isLoading && t('personalDocuments.modal-confirm-button')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default CropDialog;