import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import Moment from "react-moment";
import document from "../../images/documents-icon.svg";
import payment from "../../images/payment-icon.svg";
import calendar from '../../images/calendar-icon.svg';
import back from "../../images/back-icon.svg";
import {getElementFromIndexedDB, putElementFromIndexedDB} from "../service/UtilityService.js";
import DOMPurify from "dompurify";
import ReactHtmlParser from 'react-html-parser';
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";

export default function MessageDetails() {
    useEffect(() => {
        if (navigator.onLine) {
            handleRead(id, type);
            if (sessionStorage.getItem("notificationId") != null) {
                sessionStorage.removeItem("notificationId")
            }
        } else {
            handleReadOffline(id, type);
        }
    }, []);

    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const params = useParams();
    const id = params.id;
    const type = params.type;
    const getDetailsUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/${type}`;
    const authCtx = useContext(AuthContextMiddleware);
    const [message, setMessage] = useState([]);
    const [details] = useState([]);
    const [isCached, setIsCached] = useState(false);
    const [serviceError, setServiceError] = useState(false);


    const handleRead = (id) => {
        setIsCached(true)
        fetch(`${getDetailsUrl}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setMessage(data)
            getElementFromIndexedDB('notificationDetails').then((res) => {
                if (res) return res.value;
                else return [];
            }).then((dat) => {
                let items = dat;
                let test = findArrayElementByTitle(items, data.id)
                if (!test) {
                    details.push(...items, data)
                } else {
                    details.push(...items)
                }
                putElementFromIndexedDB('notificationDetails', details)
            }).catch(() => {
                setServiceError(true)
            })
            fetch(`${getDetailsUrl}/${id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    isImportant: data.isImportant,
                    isRead: true,
                }),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                return response.json();
            }).catch(() => {
                setServiceError(true)
            });
        })
    }

    function findArrayElementByTitle(array, id) {
        return array.find((element) => {
            return element.id === id;
        })
    }

    const handleReadOffline = (id) => {
        getElementFromIndexedDB('notificationDetails').then((response) => {
            return response.value;
        }).then((data) => {
            let message = findArrayElementByTitle(data, id)
            if (message) {
                setIsCached(true);
                setMessage(message);
            }
        })
    }

    function turnBack() {
        return navigate(-1);
    }

    function reload() {
        window.location.reload();
    }


    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2">{t('messageDetail.title')}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Row className="first-nav-top-row-shadow">
                                <Col className="col-4 float-left">
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px" alt="back"/>
                                </Col>
                                <Col className="col-4">
                                    <h6 className="header-title ellipsis-1-v2">{t('messageDetail.title')}</h6>
                                </Col>
                                {
                                    /*
                                    <Col className="col-4 float-right">
                                    <img src={dots} className="nav-top-logo float-right cursor-pointer" height="27px" alt="dots"/>
                                </Col>
                                    */
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Container className="top-of-the-message-details"></Container>
                    <Row style={{
                        background: themeContext.color.white,
                        paddingBottom: '30px'
                    }}>
                        <Col>
                            {
                                !isCached && <h3>{t('messages.offline-text')}</h3>
                            }

                            {
                                isCached &&
                                <Fragment>
                                    <Row>
                                        <Col>
                                            <Container>
                                                <Row>
                                                    {
                                                        isCached && message.type === null &&
                                                        <Col>
                                                            <h6 className="font-weight-bold message-original-date"
                                                                style={{'textAlign': 'left'}}>
                                                                <Moment
                                                                    locale="it"
                                                                    format="DD/MM/YYYY">{message.originalDate}</Moment>
                                                            </h6>
                                                        </Col>
                                                    }
                                                    {
                                                        isCached && message.type !== null &&
                                                        <Col className="col-10">
                                                            <Row className="float-left w-100">
                                                                <Col>
                                                                    {message.type === 'DOCUMENT' &&
                                                                        <h6 className="message-type-title"
                                                                            style={{'textAlign': 'left'}}>{t('messageDetail.document-label')}</h6>}
                                                                    {message.type === 'PAYMENT' &&
                                                                        <h6 className="message-type-title"
                                                                            style={{'textAlign': 'left'}}>{t('messageDetail.payment-label')}</h6>}
                                                                    {message.type === 'CALENDAR' &&
                                                                        <h6 className="message-type-title"
                                                                            style={{'textAlign': 'left'}}>{t('messageDetail.reminder-label')}</h6>}

                                                                </Col>
                                                            </Row>
                                                            <Row className="float-left w-100">
                                                                <Col>
                                                                    <h6 className="font-weight-bold message-original-date"
                                                                        style={{'textAlign': 'left'}}>
                                                                        <Moment locale="it"
                                                                                format="DD/MM/YYYY">{message.originalDate}</Moment>
                                                                    </h6>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    }
                                                    <Col className="col-2 float-right">
                                                        {message.type === 'DOCUMENT' &&
                                                            <img src={document} height={'50px'}
                                                                 style={{'float': 'right'}}
                                                                 alt="document"/>}
                                                        {message.type === 'PAYMENT' &&
                                                            <img src={payment} height={'50px'} alt="payment"/>}
                                                        {message.type === 'CALENDAR' &&
                                                            <img src={calendar} height={'50px'}
                                                                 alt="calendar"/>}
                                                    </Col>
                                                </Row>
                                                <Row className="pt-4 w-100">
                                                    <Col>
                                                        <h2 className="message-title">{message.title}</h2>
                                                    </Col>
                                                </Row>
                                                <Row className="pt-4 w-100">
                                                    <Col>
                                                        <h6 className="message-content">
                                                            {ReactHtmlParser(DOMPurify.sanitize(message.html))}
                                                        </h6>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                    </Row>
                                </Fragment>
                            }
                        </Col>
                    </Row>
                </Container>
            </Fragment>
    )
}
