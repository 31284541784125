import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import goTo from "../../images/goto-icon.svg";
import Divider from '@material-ui/core/Divider';
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import baseMaleAvatar from "../../images/male-icon.svg";
import carIcon from "../../images/car-icon.svg";
import motorbikeIcon from "../../images/motorbike-icon.svg";
import baseFemaleAvatar from "../../images/female-icon.svg";
import baseCompanyAvatar from "../../images/company-avatar.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";


const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: "0 0 0 0",
    },
    listItem: {
        color: themeContext.color.neutral400,
        paddingTop: '14px',
        paddingBottom: '14px',
    },
    listItemName: {
        paddingTop: '18px',
        paddingBottom: '0px',
    },
    listItemIcon: {
        marginLeft: '18px',
        marginRight: '-10px',
    },
    listTextSelected: {
        color: themeContext.color.neutral600,
        fontSize: '16px !important'
    },
    listText: {
        fontSize: '16px !important'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    avatarList: {
        width: "40px",
        height: "40px",
        // border: '3px solid red'
    },
    personAvatar: {
        width: "30px",
        height: "30px",
        marginBottom: "5px",
        marginLeft: "5px",
        marginRight: "10px"
    },
    primary: {
        color: themeContext.color.main,
    },
    peopleName: {
        textTransform: "uppercase",
        textAlign: "left",
        font: "normal normal bold 14px/29px Inter",
        letterSpacing: "0px",
        color: themeContext.color.neutral400,
        opacity: "1",
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

export default function Vehicles() {
    const [isLoadingPersonal, setIsLoadingPersonal] = useState(false);
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {

        if (navigator.onLine) {
            if (!searchMode) {
                getPeopleVehicles();
            }

            if (searchMode && searchValue !== "") {
                const delayDebounceFn = setTimeout(() => {
                    searchVehicle(searchValue)
                }, 1000)
                return () => clearTimeout(delayDebounceFn)
            } else if (searchMode && searchValue === "") {
                getPeopleVehicles();
            }

        }
    }, [searchValue]);

    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const [personalVehicles, setPersonalVehicles] = useState([]);
    const [peopleVehicles, setPeopleVehicles] = useState([]);
    const [searchedVehicles, setSearchedVehicles] = useState([]);
    const [people, setPeople] = useState([]);
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const searchVehiclesUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/searchVehicle`;
    const [personalSearchedVehicles, setPersonalSearchedVehicles] = useState([]);
    const [searchedPeople, setSearchedPeople] = useState([]);
    const [personalId, setPersonalId] = useState("");
    const [serviceError, setServiceError] = useState(false);
    const [searchMode, setSearchMode] = useState(false);
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();
    const token = sessionStorage.getItem('token');

    const getPeopleVehicles = () => {
        setPeople([])
        setIsLoadingPeople(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            data.map((item, index) => {
                let checkActiveVehicle = false;
                if (item.status !== -1) {
                    if (item.vehicles.length > 0) {
                        item.vehicles.map((vehicle, index) => {
                            if (vehicle.status !== 1) {
                                checkActiveVehicle = true
                            }
                        })
                        if (checkActiveVehicle === true) {
                            setPeople(current => [...current, item])
                        }
                    }
                }
            })
            setIsLoadingPeople(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const onClickVehicles = (id) => {
        return navigate(`/settings/vehicles/${id}`);
    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
            case 5:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    const handleSearchMode = () => {
        if (searchMode && searchValue !== "") {
            setSearchValue("")
            document.querySelector('#search-input').value = "";
            document.querySelector('#search-input').focus();
        } else {
            setSearchMode(current => !current);
            getPeopleVehicles()
        }
    }

    const searchVehicle = (text) => {
        setIsLoadingPersonal(true)
        setIsLoadingPeople(true)
        setSearchedVehicles([])
        setPersonalSearchedVehicles([])
        setSearchedPeople([])

        fetch(`${searchVehiclesUrl}?text=${text}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            //setSearchedVehicles(data);
            data.map((item, index) => {
                if (item.personId === personalId) {
                    setPersonalSearchedVehicles(current => [...current, item.id])
                } else {
                    setSearchedVehicles(current => [...current, item.id]);
                }
                setSearchedPeople(current => [...current, item.personId])
            })
            return data;
        }).then((data) => {
            setPeopleVehicles([]);
            setPersonalVehicles([]);
            setIsLoadingPersonal(false)
            setIsLoadingPeople(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const getPeopleById = (id) => {
        for (let i = 0; i < people.length; i++) {
            if (people[i].id === id) {
                return people[i];
            }
        }
    }

    function turnBack() {
        return navigate(-1);
    }

    const setGrayScale = (value) => {
        switch (value) {
            case 1:
                return 'grayscale(1)';
            case 2:
            case 3:
            case 4:
                return '';
        }
    }

    function reload() {
        window.location.reload();
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2">{t('vehicles.title')}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container border-bottom" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingBottom': '20px',
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col className="col-2 float-left">
                            <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                                 height="27px"
                                 alt="back"/>
                        </Col>
                        {
                            !searchMode && <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2">{t('vehicles.title')}</h6>
                            </Col>
                        }
                    </Row>
                    <Container className="top-of-the-vehicles"></Container>
                    {
                        /*
                        isLoadingPersonal &&
                        <Row style={{'marginTop': '40vh', 'height': '94px'}}>
                            <Col>

                                <RotatingLines
                                    style={{'marginTop': '15px'}}
                                    strokeColor="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="40"
                                    visible={true}
                                />

                            </Col>
                        </Row>

                         */
                    }
                    {
                        /*

                        (!searchMode || searchValue === "") && !isLoadingPersonal &&
                        <Row style={{
                            'paddingLeft': '0',
                            'paddingRight': '0px',
                            //'borderLeft': '1px solid #EEE9E5',
                            //'borderRight': '1px solid #EEE9E5'
                        }}>
                            <Col style={{'paddingLeft': '0', 'paddingRight': '0px'}}>
                                <div className={classes.rootList}>
                                    <List component="nav" aria-label="main mailbox folders">
                                        {
                                            personalVehicles.map((value, index) => (
                                                <Fragment key={index}>
                                                    {index === 0 &&
                                                        <Fragment>
                                                            <ListItem className={classes.listItemName}>
                                                                {
                                                                    user.firstName === null && user.lastName === null ?
                                                                        <span className="ellipsis-1">
                                                                    <h6 className={classes.peopleName}>{user.name}</h6>
                                                                </span>
                                                                        :
                                                                        <span className="ellipsis-1">
                                                                    <h6 className={classes.peopleName}>{user.firstName} {user.lastName}</h6>
                                                                </span>
                                                                }
                                                            </ListItem>
                                                        </Fragment>
                                                    }
                                                    <Fragment>
                                                        <Divider/>
                                                        <ListItem className={classes.listItem} key={index} button
                                                                  onClick={() => onClickVehicles(value.id)}>
                                                            <ListItemIcon>
                                                                {
                                                                    (value.image === null && value.vehicleType !== "CICLOMOTORE" && value.vehicleType !== "MOTOCICLO") &&
                                                                    <Avatar src={carIcon}
                                                                            className={classes.avatarList}
                                                                            style={{
                                                                                filter: `${setGrayScale(value.status)}`,
                                                                                'border': `3px solid ${setStatusColor(value.status)}`
                                                                            }}/>
                                                                }
                                                                {
                                                                    (value.image === null && (value.vehicleType === "CICLOMOTORE" || value.vehicleType === "MOTOCICLO")) &&
                                                                    <Avatar src={motorbikeIcon}
                                                                            className={classes.avatarList}
                                                                            style={{
                                                                                filter: `${setGrayScale(value.status)}`,
                                                                                'border': `3px solid ${setStatusColor(value.status)}`
                                                                            }}/>
                                                                }
                                                                {
                                                                    value.image !== null &&
                                                                    <Avatar src={value.image}
                                                                            className={classes.avatarList}
                                                                            style={{
                                                                                filter: `${setGrayScale(value.status)}`,
                                                                                'border': `3px solid ${setStatusColor(value.status)}`
                                                                            }}/>
                                                                }
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                className="ellipsis-1"><span
                                                                className="black font-weight-bold">{value.plate}</span> - {value.modellocostruttore}
                                                            </ListItemText>
                                                            <ListItemIcon className="justify-content-end">
                                                                <img src={goTo} alt="image" height="20px"/>
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    </Fragment>
                                                </Fragment>
                                            ))
                                        }
                                    </List>
                                </div>
                            </Col>
                        </Row>

                             */
                    }

                    {
                        isLoadingPeople &&
                        <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                    }
                    {(!searchMode || searchValue === "") && !isLoadingPeople &&
                        <Row className="col-list">
                            <Col className="col-list">
                                <div className={classes.rootList}>
                                    <List component="nav" aria-label="main mailbox folders">
                                        {
                                            people.map((value, index) => (
                                                <Fragment key={index}>
                                                    <ListItem className={classes.listItemName}>
                                                        {
                                                            value.image === null && value.gender === 'M' &&
                                                            <Avatar className={classes.personAvatar}
                                                                    src={baseMaleAvatar} id="user-image"
                                                                    alt="avatar-image"/>
                                                        }
                                                        {
                                                            value.image === null && value.gender === 'F' &&
                                                            <Avatar className={classes.personAvatar}
                                                                    src={baseFemaleAvatar} id="user-image"
                                                                    alt="avatar-image"/>
                                                        }
                                                        {
                                                            value.image === null && value.gender === 'C' &&
                                                            <Avatar className={classes.personAvatar}
                                                                    src={baseCompanyAvatar} id="user-image"
                                                                    alt="avatar-image"/>
                                                        }
                                                        {
                                                            value.image === null && value.gender === null &&
                                                            <Avatar className={classes.personAvatar}
                                                                    src={baseMaleAvatar} id="user-image"
                                                                    alt="avatar-image"/>
                                                        }
                                                        {
                                                            value.image &&
                                                            <Avatar className={classes.personAvatar} src={value.image}
                                                                    id="user-image"
                                                                    alt="avatar-image"/>
                                                        }
                                                        {
                                                            ((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                                                <span className="ellipsis-1">
                                                                    <h6 className={classes.peopleName}>{value.name}</h6>
                                                                </span>
                                                                :
                                                                <span className="ellipsis-1">
                                                                    <h6 className={classes.peopleName}>{value.firstName} {value.lastName}</h6>
                                                                </span>
                                                        }
                                                    </ListItem>
                                                    {
                                                        people[index].vehicles.map((value, index) => (
                                                            value.status !== 1 &&
                                                            <Fragment key={index}>
                                                                <Divider/>
                                                                <ListItem className={classes.listItem} button
                                                                          onClick={() => onClickVehicles(value.id)}>
                                                                    <ListItemIcon className={classes.listItemIcon}>
                                                                        {
                                                                            (value.image === null && value.vehicleType !== "CICLOMOTORE" && value.vehicleType !== "MOTOCICLO") &&
                                                                            <Avatar src={carIcon}
                                                                                    className={classes.avatarList}
                                                                                    style={{
                                                                                        filter: `${setGrayScale(value.status)}`,
                                                                                        'border': `${themeContext.spacing.borders.border3} ${setStatusColor(value.status)}`
                                                                                    }}/>
                                                                        }
                                                                        {
                                                                            (value.image === null && (value.vehicleType === "CICLOMOTORE" || value.vehicleType === "MOTOCICLO")) &&
                                                                            <Avatar src={motorbikeIcon}
                                                                                    className={classes.avatarList}
                                                                                    style={{
                                                                                        filter: `${setGrayScale(value.status)}`,
                                                                                        'border': `${themeContext.spacing.borders.border3} ${setStatusColor(value.status)}`
                                                                                    }}/>
                                                                        }
                                                                        {
                                                                            value.image !== null &&
                                                                            <Avatar src={value.image}
                                                                                    className={classes.avatarList}
                                                                                    style={{
                                                                                        filter: `${setGrayScale(value.status)}`,
                                                                                        'border': `${themeContext.spacing.borders.border3} ${setStatusColor(value.status)}`
                                                                                    }}/>
                                                                        }
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        className="ellipsis-1"><span
                                                                        className="black font-weight-bold">{value.plate}</span> - {value.modellocostruttore}
                                                                    </ListItemText>
                                                                    <ListItemIcon className="justify-content-end">
                                                                        <img src={goTo} alt="image" height="20px"/>
                                                                    </ListItemIcon>
                                                                </ListItem>
                                                            </Fragment>
                                                        ))
                                                    }
                                                </Fragment>
                                            ))
                                        }
                                    </List>
                                </div>
                            </Col>
                        </Row>
                    }
                    {searchMode && searchValue !== "" && !isLoadingPeople && !isLoadingPersonal &&
                        <Row className="col-list">
                            <Col className="col-list">
                                <div className={classes.rootList}>
                                    <List component="nav" aria-label="main mailbox folders"
                                          style={{'marginTop': '0px'}}>
                                        {
                                            people.map((value, index) => (
                                                <Fragment key={index}>
                                                    <ListItem className={classes.listItemName}>
                                                        {
                                                            ((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                                                <span className="ellipsis-1">
                                                                    <h6 className={classes.peopleName}>{value.name}</h6>
                                                                </span>
                                                                :
                                                                <span className="ellipsis-1">
                                                                    <h6 className={classes.peopleName}>{value.firstName} {value.lastName}</h6>
                                                                </span>
                                                        }
                                                    </ListItem>
                                                    {
                                                        people[index].vehicles.map((value, index) => (
                                                            searchedVehicles.includes(value.id) &&
                                                            <Fragment key={index}>
                                                                <Divider/>
                                                                <ListItem className={classes.listItem} button
                                                                          onClick={() => onClickVehicles(value.id)}>
                                                                    <ListItemIcon>
                                                                        {
                                                                            (value.image === null && value.vehicleType !== "CICLOMOTORE" && value.vehicleType !== "MOTOCICLO") &&
                                                                            <Avatar src={carIcon}
                                                                                    className={classes.avatarList}
                                                                                    style={{
                                                                                        filter: `${setGrayScale(value.status)}`,
                                                                                        'border': `${themeContext.spacing.borders.border3} ${setStatusColor(value.status)}`
                                                                                    }}/>
                                                                        }
                                                                        {
                                                                            (value.image === null && (value.vehicleType === "CICLOMOTORE" || value.vehicleType === "MOTOCICLO")) &&
                                                                            <Avatar src={motorbikeIcon}
                                                                                    className={classes.avatarList}
                                                                                    style={{
                                                                                        filter: `${setGrayScale(value.status)}`,
                                                                                        'border': `${themeContext.spacing.borders.border3} ${setStatusColor(value.status)}`
                                                                                    }}/>
                                                                        }
                                                                        {
                                                                            value.image !== null &&
                                                                            <Avatar src={value.image}
                                                                                    className={classes.avatarList}
                                                                                    style={{
                                                                                        filter: `${setGrayScale(value.status)}`,
                                                                                        'border': `${themeContext.spacing.borders.border3} ${setStatusColor(value.status)}`
                                                                                    }}/>
                                                                        }
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        className="ellipsis-1"><span
                                                                        className="black font-weight-bold">{value.plate}</span> - {value.modellocostruttore}
                                                                    </ListItemText>
                                                                    <ListItemIcon className="justify-content-end">
                                                                        <img src={goTo} alt="image" height="20px"/>
                                                                    </ListItemIcon>
                                                                </ListItem>
                                                            </Fragment>
                                                        ))
                                                    }
                                                </Fragment>
                                            ))
                                        }
                                    </List>
                                </div>
                            </Col>
                        </Row>
                    }
                    {
                        /*
                        <Container className="end-of-the-page" style={{
                        'background': themeContext.color.white,
                        'borderLeft': '1px solid #EEE9E5',
                        'borderRight': '1px solid #EEE9E5',
                        'height': '100%',
                        'position': 'fixed',
                        'right': '0',
                        'left': '0'
                    }}></Container>
                         */
                    }

                </Container>
            </Fragment>
    )
}
