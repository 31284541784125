import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next'
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import notCarClaimCard from "../../images/not-car-claim-card.svg";
import carClaimCard from "../../images/car-claim-card.svg";
import themeContext from "../components/ThemeContext.js";


const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: "0 0 0 0",
    },
    listItem: {
        color: themeContext.color.neutral400,
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    listTextSelected: {
        color: themeContext.color.neutral600,
        fontSize: '16px !important'
    },
    listText: {
        fontSize: '16px !important'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    primary: {
        color: themeContext.color.main,
    },
    card: {
        margin: "0 auto",
        marginTop: "10px",
        marginBottom: "18px",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow}`,
        borderRadius: "10px",
        opacity: "1",
        minHeight: "140px",
        padding: "0"
    },
    cardTitle: {
        fontWeight: '700',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '1px',
        color: themeContext.color.main,
        textTransform: 'uppercase',
    }
}));

export default function OpenClaim() {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const [checkActiveVehicle, setCheckActiveVehicle] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getPeopleVehicles();
        }
    }, []);

    function turnBack() {
        if (sessionStorage.getItem('root') === '/claims') {
            return navigate('/claims');
        } else if (sessionStorage.getItem('root') === '/contacts') {
            return navigate('/contacts');
        }
    }

    function reload() {
        window.location.reload();
    }

    const getPeopleVehicles = () => {
        setIsLoadingData(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            data.map((item, index) => {
                if (item.status !== -1) {
                    if (item.vehicles.length > 0) {
                        item.vehicles.map((vehicle, index) => {
                            if (vehicle.status !== 1) {
                                setCheckActiveVehicle(true);
                            }
                        })
                    }
                }
            })
            setIsLoadingData(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingLeft': '0',
                    'paddingRight': '0'
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2">{t('openClaim.title')}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Row className="container nav-top border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingBottom': '20px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col className="col-2 float-left">
                        <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                             height="27px"
                             alt="back"/>
                    </Col>
                    <Col className="col-8">
                        <h6 className="header-title ellipsis-1-v2">{t('openClaim.title')}</h6>
                    </Col>
                    <Col className="col-2">
                    </Col>
                </Row>
                <Container className="top-of-the-payment-title-detail"></Container>
                {isLoadingData &&
                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                }
                {
                    !isLoadingData &&
                    <Fragment>
                        <Row className="mt-1" style={{
                            paddingLeft: '0',
                            paddingRight: '0',
                            textAlign: 'left',
                            fontSize: '14px',
                            color: themeContext.color.neutral400
                        }}>
                            <Col>
                                <span>{t('openClaim.payoff')}</span>
                            </Col>
                        </Row>
                        {checkActiveVehicle &&
                            <Card className={classes.card}
                                  onClick={() => navigate('/claims/open/carClaim')}>
                                <CardContent className="pb-0">
                                    <Row>
                                        <Col style={{
                                            textAlign: "left"
                                        }}>
                                            <img src={carClaimCard} alt="car-claim-card"/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{
                                            textAlign: "right"
                                        }}>
                                <span className={classes.cardTitle}>
                                    {t('openClaim.type-1')}
                                </span>
                                        </Col>
                                    </Row>
                                </CardContent>
                            </Card>
                        }
                        <Card className={classes.card}
                              onClick={() => navigate('/claims/open/genericClaim')}>
                            <CardContent className="pb-0">
                                <Row>
                                    <Col style={{
                                        textAlign: "left"
                                    }}>
                                        <img src={notCarClaimCard} alt="car-claim-card"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{
                                        textAlign: "right"
                                    }}>
                                <span className={classes.cardTitle}>
                                    {t('openClaim.type-2')}
                                </span>
                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                    </Fragment>
                }

            </Container>
    )
}
