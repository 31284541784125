import { useState } from 'react'

let CopiedValue = ""
let CopyFn; // Return success

function UseCopyToClipboard() {
    const [copiedText, setCopiedText] = useState(null)

    const copy: CopyFn = async text => {
        if (!navigator.clipboard) {
            console.warn('Clipboard not supported')
            return false
        }

        try {
            await navigator.clipboard.writeText(text)
            setCopiedText(text)
            return true
        } catch (error) {
            console.warn('Copy failed', error)
            setCopiedText(null)
            return false
        }
    }

    return [copiedText, copy]
}

export default UseCopyToClipboard