import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import AuthContextMiddleware from '../middleware/AuthContextMiddleware.js';
import Container from 'react-bootstrap/Container';
import {Col, Row} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {fetchToken} from "../middleware/Firebase.js";
import {Button, Menu, MenuItem, MenuList, Radio, withStyles} from "@material-ui/core";
import {
    base64url_decode,
    base64url_encode,
    getElementFromIndexedDB,
    isIpad,
    isMac,
    isStandalone,
    putElementFromIndexedDB
} from "../service/UtilityService.js";
import BellOff from '../../images/bell-icon.svg'
import BellOn from '../../images/bellOn-icon.svg'
import baseFemaleAvatar from '../../images/female-icon.svg'
import wideLogoLight from '../../images/wideLogo-icon.svg';
import {ClientJS} from 'clientjs';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Moment from "react-moment";
import baseMaleAvatar from "../../images/male-icon.svg";
import {upperCaseFirst} from "upper-case-first";
import searchIcon from "../../images/search-icon.svg";
import exitIcon from "../../images/exit-icon.svg";
import nothing from "../../images/nothing-icon.svg";
import Badge from "@material-ui/core/Badge";
import scrollbarUsersPlaceholder from "../../images/user-placeholder.svg";
import cardPlaceholder_min from "../../images/card-placeholder_min.svg";
import cardPlaceholder_678 from "../../images/card-placeholder_678.svg";
import cardPlaceholder_918 from "../../images/card-placeholder_918.svg";
import cardPlaceholder_full from "../../images/card-placeholder_full.svg";
import {useMediaQuery} from "react-responsive";
import slimpayLogo from "../../images/slimpay-logo-blue.svg";
import Header from "../components/Header.js";
import baseCompanyAvatar from "../../images/company-avatar.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";

const StyledBadge = withStyles((theme) => ({
    badge: {
        border: `${themeContext.spacing.borders.border2} ${themeContext.color.white}`,
        background: themeContext.color.red,
        color: themeContext.color.white,
        padding: '0 4px',
    },
}))(Badge);

const StyledBadgeZero = withStyles((theme) => ({
    badge: {
        border: `${themeContext.spacing.borders.border2} ${themeContext.color.white}`,
        background: themeContext.color.neutral200,
        color: themeContext.color.white,
        padding: '0 4px',
    },
}))(Badge);

const useStyles = makeStyles((theme) => ({
    card: {
        margin: "0 auto",
        marginTop: "10px",
        marginBottom: "25px",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow}`,
        borderRadius: "10px",
        opacity: "1",
        minHeight: "140px"
    },
    avatars: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    titles: {
        color: themeContext.color.neutral400,
        textAlign: 'left',
        lineHeight: '1',
        fontWeight: '500',
        fontSize: '12px',
        textTransform: 'uppercase',
    },
    cardTextHigh: {
        color: themeContext.color.white,
        textAlign: 'left',
        lineHeight: '1',
        fontWeight: '100',
        fontSize: '14px'
    },
    cardTextMid: {
        color: themeContext.color.neutral400,
        textAlign: 'left',
        lineHeight: '1',
        fontWeight: '400',
        fontSize: '12px',
        textTransform: 'uppercase',
        paddingTop: '10px',
        paddingBottom: '7px',
    },
    cardTextLow: {
        color: themeContext.color.neutral400,
        textAlign: 'left',
        lineHeight: '1',
        fontSize: '14px',
        paddingBottom: '6px'
    },
    cardAvatar: {
        float: "right",
        width: "35px",
        height: "35px",
        marginTop: "-8px"
    },
    avatarText: {
        textAlign: 'center',
        font: 'normal normal 500 10px/14px Inter',
        letterSpacing: '0px',
        color: themeContext.color.neutral600,
        opacity: '1',
        marginLeft: "0px",
        marginRight: "0px",
        display: 'flex',
    },
    avatarTextBig: {
        textAlign: 'center',
        font: 'normal normal 700 11px/14px Inter',
        letterSpacing: '0px',
        color: themeContext.color.neutral600,
        opacity: '1',
        marginLeft: "0px",
        marginRight: "0px",
        display: 'flex',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    avatar: {
        //  width: theme.spacing(7),
        //  height: theme.spacing(7),
        width: '56px',
        height: '56px',
        margin: '4px 7px 4px 7px',
    },
    bigAvatar: {
        width: '66px',
        height: '66px',
        margin: '0 0 0 5px',
        boxShadow: `0px 2px 5px ${themeContext.color.boxShadow}`,
    },
    detailButton: {
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '2.07px',
        color: themeContext.color.mainActive,
        textTransform: 'uppercase',
    }
}));

export default function Payments() {
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const checkReadUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/checkRead`;
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const webAuthNRegistrationUrl = `${process.env.REACT_APP_BASE_URL}/management/webauthn/registration`;
    const searchPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/search`;
    const getPeoplePaymentNotificationUrl = `${process.env.REACT_APP_BASE_URL}/payments/notifications/people?payableOnly=true`;
    const getPolicyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies`;
    const operationUrl = `${process.env.REACT_APP_BASE_URL}/management/operation`;

    const token = sessionStorage.getItem('token');
    const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
    const [isLoadingPersonalData, setIsLoadingPersonalData] = useState(false);
    const [isLoadingPeopleData, setIsLoadingPeopleData] = useState(false);
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [searchValue, setSearchValue] = useState("");
    const params = useParams();

    const width_1199 = useMediaQuery({
        query: '(min-width: 1199px)'
    })
    const width_991 = useMediaQuery({
        query: '(min-width: 991px)'
    })
    const width_767 = useMediaQuery({
        query: '(min-width: 766px)'
    })
    const width_min = useMediaQuery({
        query: '(min-width: 0px)'
    })


    useEffect(() => {
        sessionStorage.setItem('root', '/payments');
        if (navigator.onLine) {
            if (sessionStorage.getItem("notificationId") != null) {
                const url = sessionStorage.getItem("notificationId").replaceAll('"', '')
                navigateToMessageDetails(url);
            }
            getInfo();
            handleRinging();
            getPeoplePaymentNotification();

            if (!searchMode) {
                getPeople();
                //getPersonalPolicies();
                //getPeoplePolicies();
            }

            //getVehicles();

        } else {
            getInfoOffline();
            getPeopleOffline();
            //getPersonalPoliciesOffline();
            //getPeoplePoliciesOffline();
        }
        if (!localStorage.getItem('firstLogin') && localStorage.getItem('biometrics')) {
            setOpenDialog(true);
        }
        localStorage.setItem('firstLogin', true);
        if (searchMode) {
            const delayDebounceFn = setTimeout(() => {
                searchPeople(searchValue)
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchValue]);

    const [user, setUser] = useState("");
    const authCtx = useContext(AuthContextMiddleware);
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({
        title: '',
        body: ''
    });
    const client = new ClientJS();
    const [openDialog, setOpenDialog] = useState(false);
    const [bellRinging, setBellRinging] = useState(false);
    const classes = useStyles();
    const [personalPolicies, setPersonalPolicies] = useState([]);
    const [peoplePolicies, setPeoplePolicies] = useState([]);
    const [people, setPeople] = useState([]);
    //const [selectedAvatar, setSelectedAvatar] = useState("");
    const selectedAvatar = useRef("null");
    const [searchMode, setSearchMode] = useState(false);
    const [timeoutAvatar, setTimeoutAvatar] = useState(false);
    const [noPeople, setNoPeople] = useState(false);
    const [getPermission, setGetPermission] = useState(false);
    const [isNothingPersonal, setIsNothingPersonal] = useState(false);
    const [isNothingPeople, setIsNothingPeople] = useState(false);
    const [isNothingPaymentNotifications, setIsNothingPaymentNotifications] = useState(false);
    const filter = useRef('STATUS_DEFAULT');
    const [openMenu, setOpenMenu] = useState(null);
    const initialRadioValue = [{id: 0}];
    const [selectedRadio, setSelectedRadio] = useState('');
    const [serviceError, setServiceError] = useState(false);
    const initialValue = [{
        id: 0,
        policynr: "",
        typology: "",
        object: ""
    }];
    const [policies, setPolicies] = useState(initialValue);
    const [paymentNotification, setPaymentNotification] = useState([])
    const [requestedCents, setRequestedCents] = useState(0)
    const [currency, setCurrency] = useState("€");
    const [overallPremium, setOverallPremium] = useState(0);
    const [personId, setPersonId] = useState(null);
    const [slimpayPeople, setSlimpayPeople] = useState([])
    const [disablePaymentButton, setDisablePaymentButton] = useState(false)


    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone()) || (isMac() && client.getOSVersion() < "13")) {
        //console.log('notification not allowed')
    } else if (!getPermission) {
        requestPermission();
        setGetPermission(true);
    }
    fetchToken();

    function requestPermission() {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
            }
        })
    }

    if (((client.isMobileIOS() || isIpad()) && client.getOSVersion() < "16.4") || ((client.isMobileIOS() || isIpad()) && !(client.getOSVersion() < "16.4") && !isStandalone()) || (isMac() && client.getOSVersion() < "13")) {
        //console.log('notification not allowed')
    } else {
        const channel = new BroadcastChannel('sw-messages');
        channel.onmessage = (event) => {
            setBellRinging(true)
        };
        channel.onmessageerror = (event) => {
        };
    }

    const navigateToMessageDetails = (url) => {
        return navigate(`/messages/principal/${url}`);
    }

    function getInfo() {
        fetch(userInfoUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            i18n.changeLanguage(data.preferredLanguage).then(r => {
            })
            setUser(data)
            localStorage.setItem('wideAPP_userId', data.id)
            putElementFromIndexedDB('profileInfo', data)
        }).catch(() => {
            setServiceError(true)
        })
    }

    function getInfoOffline() {
        getElementFromIndexedDB('profileInfo').then((response) => {
            return response.value;
        }).then((data) => {
            setUser([])
            setUser(data)
        });
    }

    function getPeople() {
        setIsLoadingPeople(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                sessionStorage.setItem('noPeople', true);
                setNoPeople(true);
            } else {
                sessionStorage.removeItem('noPeople');
                setNoPeople(false);
            }
            setPeople(data)
            putElementFromIndexedDB('people', data)
            setIsLoadingPeople(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPeopleOffline() {
        getElementFromIndexedDB('people').then((response) => {
            return response.value;
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                sessionStorage.setItem('noPeople', true);
            } else {
                sessionStorage.removeItem('noPeople');
            }
            setPeople([])
            for (let i = 0; i < length; i++) {
                if (!personalPolicies.includes(data[i])) {
                    setPeople(current => [...current, data[i]])
                }
            }
        });
    }

    function registerCredentials() {
        let username = localStorage.getItem('username-wide');
        fetch(`${webAuthNRegistrationUrl}/start`, {
            method: 'POST',
            body: JSON.stringify({
                username: username,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            if (!response.ok) {
                return;
            }
            response.json().then((registrationResponse) => {
                let keyData = registrationResponse.publicKeyCredentialCreationOptions;
                let cred = navigator.credentials.create({
                    publicKey: {
                        rp: keyData.rp,
                        user: {
                            id: base64url_decode(keyData.user.id),
                            name: keyData.user.name,
                            displayName: keyData.user.displayName
                        },
                        challenge: base64url_decode(keyData.challenge),
                        pubKeyCredParams: keyData.pubKeyCredParams
                    },
                    timeout: keyData.timeout,
                    excludeCredentials: keyData.excludeCredentials,
                    authenticatorSelection: keyData.authenticatorSelection,
                    attestation: keyData.attestation,
                    extensions: keyData.extensions
                });
                cred.then((credData) => {
                    let finishRequest = {
                        credential: {
                            clientExtensionResults: {},
                            id: credData.id,
                            rawId: base64url_encode(credData.rawId),
                            response: {
                                attestationObject: base64url_encode(credData.response.attestationObject),
                                clientDataJSON: base64url_encode(credData.response.clientDataJSON)
                            },
                            type: "public-key"
                        },
                        registrationId: registrationResponse.registrationId,
                        userAgent: navigator.userAgent
                    };
                    fetch(`${webAuthNRegistrationUrl}/finish`, {
                        method: 'POST',
                        body: JSON.stringify(finishRequest),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).then((finishResponse) => {
                        finishResponse.json().then((r) => console.log("finish registration:", r));
                        localStorage.setItem('webauthn', credData.id);
                    }).catch(() => {
                        setServiceError(true)
                    })
                });
            });
        }).catch(() => {
            setServiceError(true)
        });
    }

    const handleRinging = () => {
        fetch(checkReadUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.toRead) {
                setBellRinging(true);
            } else {
                setBellRinging(false);
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    const setStatusColor = (value) => {
        switch (value) {
            case 1:
                return themeContext.color.neutral200;
            case 2:
                return themeContext.color.green;
            case 3:
                return themeContext.color.yellow;
            case 4:
                return themeContext.color.red;
        }
    }

    const setGrayScale = (value) => {
        switch (value) {
            case 1:
                return 'grayscale(1)';
            case 2:
            case 3:
            case 4:
                return '';
        }
    }

    const handleClickPerson = (id) => {
        setCurrency('')
        setOverallPremium(0)
        setTimeoutAvatar(true);
        setPaymentNotification([])
        if (selectedAvatar.current === id) {
            selectedAvatar.current = "null";
            getPeoplePaymentNotification();
        } else {
            selectedAvatar.current = id;
            getPeoplePaymentNotification();
        }
        setTimeout(function () {
            setTimeoutAvatar(false);
        }, 1000);
    }

    const handleSearchMode = () => {
        if (searchMode && searchValue !== "") {
            setSearchValue("")
            document.querySelector('#search-input').value = "";
            document.querySelector('#search-input').focus();
        } else {
            setSearchMode(current => !current);
            getPeople();
            //getPersonalPolicies();
            //getPeoplePolicies();
        }
    }

    const searchPeople = (text) => {
        setIsLoadingPeople(true)
        fetch(`${searchPeopleUrl}?text=${text}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPeople([]);
            setPeople(data);
            setIsLoadingPeople(false)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const nothingFunction = () => {
    }

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setOpenMenu(null);
    }

    const handleFilter = (selectedFilter) => {
        if (filter.current === selectedFilter) {
            filter.current = 'STATUS_DEFAULT';
        } else {
            filter.current = selectedFilter;
        }
        handleCloseMenu();
        if (selectedAvatar.current === "null") {
            //getPersonalPolicies();
            //getPeoplePolicies();
        } else if (selectedAvatar.current === "personal") {
            //getPersonalPolicies();
        } else {
            //getPersonPolicies(selectedAvatar.current);
        }
    }

    const stringFilter = () => {
        switch (filter.current) {
            case 'INSURANCE_END_ASC':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-ascending-date')}`;
            case 'INSURANCE_END_DESC':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-descending-date')}`;
            case 'STATUS_ACTIVE':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-active')}`;
            case 'STATUS_EXPIRING':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-expirity')}`;
            case 'STATUS_EXPIRED':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-expired')}`;
            case 'STATUS_INACTIVE':
                return `${t('dashboard.filter-title')} ${t('dashboard.filter-killed')}`;
        }
    }

    const getPeoplePaymentNotification = () => {
        setIsLoadingNotifications(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let arr = []
            data.map((person, index) => {
                if (person.slimpayIsPending === true) {
                    setSlimpayPeople(current => [...current, person.id])
                    arr.push(person.id)
                }
            })
            return arr;
        }).then((slimpayPeopleData) => {
            let url;
            if (selectedAvatar.current !== "null") {
                url = `${getPeoplePaymentNotificationUrl}&personId=${selectedAvatar.current}`;
            } else {
                url = `${getPeoplePaymentNotificationUrl}`;
            }
            fetch(`${url}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                return response.json();
            }).then((data) => {
                let count = 0;
                data.map((value, index) => {
                    let removeCurrency;
                    if (value.requestedCurrency === 'EUR') {
                        removeCurrency = value.requestedPremium.split('€')
                    } else if (value.requestedCurrency === 'USD') {
                        removeCurrency = value.requestedPremium.split('$')
                    } else if (value.requestedCurrency === 'GBD') {
                        removeCurrency = value.requestedPremium.split('£')
                    }
                    let currentValue = removeCurrency[0].replace(',', '.')
                    let floatCurrentValue = parseFloat(currentValue)
                    if (!slimpayPeopleData.includes(value.personId)) {
                        setOverallPremium(current => current + floatCurrentValue)
                    }

                    let minDate;
                    count = count + 1;
                    let policiesArr = []
                    value.titles.map((value, index) => {
                        if (index === 0) {
                            minDate = value.datebill
                        }
                        if (value.datebill < minDate) {
                            minDate = value.datebill
                        }
                        if (!policiesArr.includes(value.policy_id)) {
                            policiesArr.push(value.policy_id)
                        }
                    })
                    localStorage.setItem('policiesArray', JSON.stringify(policiesArr))
                    value.datebill = minDate
                    setPaymentNotification(current => [...current, value])
                    if (index === 0 && !slimpayPeopleData.includes(value.personId)) {
                        if (value.notificationType !== 'SDD') {
                            setPersonId(value.personId)
                            setSelectedRadio(value.id)
                            getPolicy(value.titles[0].policy_id)
                            setRequestedCents(value.requestedPremium)
                            if (value.requestedCurrency === 'EUR') {
                                setCurrency("€")
                            } else if (value.requestedCurrency === 'USD') {
                                setCurrency("$")
                            } else if (value.requestedCurrency === 'GBD') {
                                setCurrency("£")
                            }
                        } else {
                            setDisablePaymentButton(true)
                        }
                    } else if (index === 0 && slimpayPeopleData.includes(value.personId)) {
                        setDisablePaymentButton(true)
                    }
                    setIsLoadingNotifications(false)
                })
                setOverallPremium(current => current.toString().replace('.', ','))

                if (count === 0) {
                    setIsNothingPaymentNotifications(true)
                    setIsLoadingNotifications(false)
                }
            }).catch(() => {
                setServiceError(true)
            });
        }).catch(() => {
            setServiceError(true)
        });
    }

    const linkToPaymentSummaryWithCheckboxes = () => {
        let string = ``;
        const checkedNotifications = document.querySelectorAll('input[type="checkbox"]:checked');
        const arrayNotifications = Array.from(checkedNotifications, notification => notification.id);
        for (let i = 0; i < arrayNotifications.length; i++) {
            if (i === arrayNotifications.length - 1) {
                string += `${arrayNotifications[i]}`;
            } else {
                string += `${arrayNotifications[i]},`;
            }
        }
        return navigate(`/payments/payment-method?notificationIds=${string}`)
    }


    const getPolicy = (id) => {
        //setIsLoadingData(true);
        fetch(`${getPolicyUrl}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPolicies(current => [...current, {
                id: data.id,
                policynr: data.policynr,
                typology: data.typology,
                object: data.object,
            }])
        }).catch(() => {
            setServiceError(true)
        })
    }

    function reload() {
        window.location.reload();
    }

    const linkToPaymentMethod = () => {
        fetch(`${operationUrl}/createOrUpdate`, {
            method: 'POST',
            body: JSON.stringify({
                type: 'PAYMENT',
                entityId: selectedRadio
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(() => {
            return navigate(`/payments/payment-method?notificationIds=${selectedRadio}&personId=${personId}`)
        }).catch(() => {
            setServiceError(true);
        });
    }

    const onClickPaymentNotificationSummary = (id) => {
        return navigate(`/payments/${id}/summary`)
    }

    const onClickPaymentNotification = (id, premium, personId, policiesArr) => {
        setSelectedRadio(id)
        setRequestedCents(premium)
        setPersonId(personId)
        localStorage.setItem('policiesArray', JSON.stringify(policiesArr))
    }

    return (serviceError ? <Container className="pt-5 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
        <Container style={{'paddingTop': '10%'}}></Container>
        <Row>
            <Col>
                {<img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                      alt="service-error-image"></img>}
            </Col>
        </Row>
        <Row>
            <Col>
                <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
            </Col>
        </Row>
        <Row style={{'marginTop': '5%'}}>
            <Col>
                <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
            </Col>
        </Row>
    </Container> : <Fragment>
        <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
            <Row className="nav-top container" style={{
                'paddingLeft': '0',
                'paddingRight': '0',
            }}>
                <Col>
                    <Header iconSrc={wideLogoLight}
                            rightSrc={bellRinging ? BellOn : BellOff}
                            rightAction={() => navigate('/messages')}/>
                    <Row style={{
                        'paddingTop': '8px'
                    }}>
                        <Col className="col-auto float-left">
                            <h6 className={classes.titles}>{t('payment.select-payments-title')}
                            </h6>
                        </Col>
                    </Row>
                    <Row style={{'backgroundColor': themeContext.color.neutral10}}>
                        <Col style={{'textAlign': 'left'}}>
                            <Button
                                className={(isLoadingNotifications || disablePaymentButton) ? "btn-custom-disable text-uppercase" : "btn-custom text-uppercase"}
                                variant="outlined"
                                size="large"
                                style={{
                                    'color': themeContext.color.white,
                                    'float': 'left'
                                }} type="submit"
                                disabled={isLoadingNotifications || disablePaymentButton}
                                onClick={linkToPaymentMethod}
                            >{t('payment.payment-button')} {requestedCents}
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{'backgroundColor': themeContext.color.neutral10}}>
                        <Col>
                            <h6 style={{
                                textAlign: 'left',
                                fontStyle: 'italic',
                                color: themeContext.color.neutral200,
                                fontSize: '14px',
                                fontWeight: 'normal',
                                paddingTop: '8px',
                                marginBottom: '0px',
                            }}>{t('payment.overall-premium')} {currency} {overallPremium}</h6>
                        </Col>
                    </Row>
                    {!noPeople && <Fragment>
                        <Row style={{
                            'paddingTop': '20px',
                            'backgroundColor': themeContext.color.neutral10
                        }}>
                            {!searchMode && <Col className="col-auto float-left">
                                <h6 className={classes.titles}>{t('payment.beneficiaries-title')}
                                </h6>
                            </Col>}
                            {searchMode && <Col className="col-auto float-left">
                            </Col>}
                            <Col className="col float-right" style={{'marginTop': '-7px'}}>
                                {searchMode && <Row className="div-search" style={{'marginRight': '0'}}>
                                    <Col className="col-10">
                                        <input id="search-input" className="input-search"
                                               onChange={e => setSearchValue(e.target.value)}
                                               autoFocus></input>
                                    </Col>
                                    <Col className="col-2"
                                         style={{
                                             'paddingRight': '2px',
                                             'textAlign': 'right'
                                         }}>
                                        <img src={exitIcon} alt="close" onClick={handleSearchMode}
                                             height="25px" width="25px"/>
                                    </Col>
                                </Row>}
                                {!searchMode && <img src={searchIcon}
                                                     hidden
                                                     className="nav-top-logo float-right cursor-pointer"
                                                     height="27px"
                                                     alt="search-icon"
                                                     onClick={handleSearchMode}/>}

                            </Col>
                        </Row>
                        {isLoadingPeople && <Row className="pt-3" style={{'backgroundColor': themeContext.color.neutral10}}>
                            <Col style={{
                                textAlign: 'left',
                                'backgroundColor': themeContext.color.neutral10
                            }}>
                                <img src={scrollbarUsersPlaceholder} alt="card-scrollbar"
                                     className="mr-1"/>
                                <img src={scrollbarUsersPlaceholder} alt="card-scrollbar"
                                     className="mr-1"/>
                                <img src={scrollbarUsersPlaceholder} alt="card-scrollbar"
                                     className="mr-1"/>
                            </Col>
                        </Row>}
                        {!isLoadingPeople && <Row style={{
                            'paddingTop': '10px',
                            'backgroundColor': themeContext.color.neutral10
                        }}>
                            <Col className="col-list">
                                <Container className="people-scrollable-row">
                                    {/*
                                                        <div className="card card-avatar fixed-avatar">
                                                        <CardContent className="card-avatar-content"
                                                                     style={{'marginRight': '10px'}}>
                                                            <Row style={{'width': '50px'}}>
                                                                {
                                                                    user.image === null && user.gender === 'M' &&
                                                                    <Avatar alt="my-avatar" src={baseMaleAvatar}
                                                                            className={selectedAvatar.current === 'personal' ? classes.bigAvatar : classes.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                                {
                                                                    user.image === null && user.gender === 'F' &&
                                                                    <Avatar alt="my-avatar" src={baseFemaleAvatar}
                                                                            className={selectedAvatar.current === 'personal' ? classes.bigAvatar : classes.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                                {
                                                                    user.image === null && user.gender === 'C' &&
                                                                    <Avatar alt="my-avatar" src={baseMaleAvatar}
                                                                            className={selectedAvatar.current === 'personal' ? classes.bigAvatar : classes.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                                {
                                                                    user.image === null && user.gender === null &&
                                                                    <Avatar alt="my-avatar" src={baseMaleAvatar}
                                                                            className={selectedAvatar.current === 'personal' ? classes.bigAvatar : classes.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                                {
                                                                    user.image &&
                                                                    <Avatar alt="my-avatar" src={user.image}
                                                                            className={selectedAvatar.current === 'personal' ? classes.bigAvatar : classes.avatar}
                                                                            style={{
                                                                                filter: `${setGrayScale(user.status)}`,
                                                                                border: `3px solid ${setStatusColor(user.status)}`
                                                                            }}
                                                                            onClick={!timeoutAvatar ? handleClickPersonal : nothingFunction}/>
                                                                }
                                                            </Row>
                                                            <Row
                                                                style={selectedAvatar.current === 'personal' ? {'width': '73px'} : {'width': '67px'}}>
                                                                <h6 className={selectedAvatar.current === 'personal' ? "avatar-text-big text-uppercase" : "avatar-text text-uppercase"}
                                                                    style={selectedAvatar.current === 'personal' ? {color: `${setStatusColor(user.status)}`} : {}}>
                                                                    {user.firstName === null && user.lastName === null ?
                                                                        <span
                                                                            className="text-uppercase ellipsis-1">{user.name} </span> :
                                                                        <Fragment>
                                                                        <span
                                                                            className="text-uppercase ellipsis-1">{user.firstName} </span>
                                                                            <span
                                                                                className="text-uppercase ellipsis-1">{user.lastName}</span>
                                                                        </Fragment>
                                                                    }
                                                                </h6>
                                                            </Row>
                                                        </CardContent>
                                                    </div>
                                                         */}
                                    <Container className="scrolling-wrapper-flexbox no-scrollbar"
                                               id="scroll-container"
                                               style={{'backgroundColor': themeContext.color.neutral10}}>
                                        {people.map((person, index) => (
                                            <div className="card card-avatar" key={index}
                                                 style={{'backgroundColor': themeContext.color.neutral10}}>
                                                <CardContent className="card-avatar-content"
                                                             hidden={(person.payableNotificationsNr === 0 && (!person.slimpayIsPending || person.slimpayIsPending) && person.slimPayMandate)}>
                                                    <Row style={{'width': '50px'}}>
                                                        {person.image === null && person.gender === 'M' && person.payableNotificationsNr === 0 &&
                                                            <StyledBadgeZero
                                                                badgeContent={'0'}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseMaleAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                            </StyledBadgeZero>
                                                        }
                                                        {person.image === null && person.gender === 'M' && person.payableNotificationsNr !== 0 &&
                                                            <StyledBadge
                                                                badgeContent={person.payableNotificationsNr}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseMaleAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                            </StyledBadge>
                                                        }
                                                        {person.image === null && person.gender === 'F' && person.payableNotificationsNr === 0 &&
                                                            <StyledBadgeZero
                                                                badgeContent={'0'}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseFemaleAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                            </StyledBadgeZero>
                                                        }
                                                        {person.image === null && person.gender === 'F' && person.payableNotificationsNr !== 0 &&
                                                            <StyledBadge
                                                                badgeContent={person.payableNotificationsNr}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseFemaleAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                            </StyledBadge>
                                                        }
                                                        {person.image === null && person.gender === 'C' && person.payableNotificationsNr === 0 &&
                                                            <StyledBadgeZero
                                                                badgeContent={'0'}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseCompanyAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                            </StyledBadgeZero>
                                                        }
                                                        {person.image === null && person.gender === 'C' && person.payableNotificationsNr !== 0 &&
                                                            <StyledBadge
                                                                badgeContent={person.payableNotificationsNr}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseCompanyAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                            </StyledBadge>
                                                        }
                                                        {person.image === null && person.gender === null && person.payableNotificationsNr === 0 &&
                                                            <StyledBadgeZero
                                                                badgeContent={'0'}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseMaleAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                            </StyledBadgeZero>
                                                        }
                                                        {person.image === null && person.gender === null && person.payableNotificationsNr !== 0 &&
                                                            <StyledBadge
                                                                badgeContent={person.payableNotificationsNr}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={baseMaleAvatar}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                            </StyledBadge>
                                                        }
                                                        {person.image && person.payableNotificationsNr === 0 &&
                                                            <StyledBadgeZero
                                                                badgeContent={'0'}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={person.image}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                            </StyledBadgeZero>
                                                        }
                                                        {person.image && person.payableNotificationsNr !== 0 &&
                                                            <StyledBadge
                                                                badgeContent={person.payableNotificationsNr}
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}>
                                                                <Avatar alt="my-avatar"
                                                                        src={person.image}
                                                                        className={selectedAvatar.current === person.id ? classes.bigAvatar : classes.avatar}
                                                                        style={{
                                                                            filter: `${setGrayScale(person.status)}`,
                                                                            border: `${themeContext.spacing.borders.border3} ${setStatusColor(person.status)}`
                                                                        }}
                                                                        onClick={() => !timeoutAvatar ? handleClickPerson(person.id) : nothingFunction}/>
                                                            </StyledBadge>
                                                        }
                                                    </Row>
                                                    <Row
                                                        style={selectedAvatar.current === person.id ? {'width': '73px'} : {'width': '67px'}}>
                                                        <h6 className={selectedAvatar.current === person.id ? "avatar-text-big text-uppercase" : "avatar-text text-uppercase"}
                                                            style={selectedAvatar.current === person.id ? {color: `${setStatusColor(person.status)}`} : {}}>
                                                            {((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ? upperCaseFirst(`${person.name}`) :
                                                                <span><span
                                                                    className="ellipsis-1">{upperCaseFirst(`${person.firstName}`)} </span><span
                                                                    className="ellipsis-1">{upperCaseFirst(`${person.lastName}`)}</span></span>}
                                                        </h6>
                                                    </Row>
                                                </CardContent>
                                            </div>))}
                                    </Container>
                                </Container>
                            </Col>
                        </Row>}
                    </Fragment>}
                    <Row className="pb-2" style={{
                        'paddingTop': '20px',
                        'backgroundColor': themeContext.color.neutral10
                    }}>
                        <Col className="col-6">
                            <h6 className={classes.titles}>{t('payment.payment-alert-title')}</h6>
                        </Col>
                        <Col className="col-6">
                            {filter.current === 'STATUS_DEFAULT' &&
                                <Button hidden
                                        className="filter-btn filter-btn-2 float-right justify-content-end"
                                        style={{'color': themeContext.color.neutral400}}
                                        onClick={handleOpenMenu}>
                                    <span className="material-symbols-outlined"
                                          style={{'fontSize': '22px', 'paddingRight': '2px', 'paddingBottom': '3px'}}>
                                            filter_list
                                    </span>
                                </Button>
                            }
                            {filter.current !== 'STATUS_DEFAULT' &&
                                <Button hidden
                                        className="filter-btn filter-btn-2 float-right justify-content-end"
                                        style={{'color': themeContext.color.main}}
                                        onClick={handleOpenMenu}>
                                    <span className="material-symbols-outlined"
                                          style={{'fontSize': '22px', 'paddingRight': '2px', 'paddingBottom': '3px'}}>
                                        filter_list
                                    </span>
                                    {stringFilter(filter.current)}
                                </Button>
                            }
                            <Menu
                                id="simple-menu"
                                anchorEl={openMenu}
                                keepMounted
                                open={Boolean(openMenu)}
                                onClose={handleCloseMenu}
                                className="menu filter-menu-dashboard"
                                PaperProps={{
                                    style: {
                                        'maxWidth': '100%',
                                        'width': '200px',
                                    },
                                }}>
                                <MenuList className="title-filter-item">{t('dashboard.filter-title')}</MenuList>
                                <MenuItem className="filter-item" onClick={() => handleFilter('STATUS_ACTIVE')}
                                          style={filter.current === 'STATUS_ACTIVE' ? {
                                              'color': themeContext.color.green,
                                              'fontWeight': '800',
                                              'backgroundColor': themeContext.color.neutral10
                                          } : {'color': themeContext.color.green}}>
                                    {filter.current === 'STATUS_ACTIVE' ? <Fragment>
                                        {t('dashboard.filter-active')}<img src={exitIcon}
                                                                           className="ml-auto"
                                                                           alt="exit-icon"/>
                                    </Fragment> : t('dashboard.filter-active')}
                                </MenuItem>
                                <MenuItem className="filter-item"
                                          onClick={() => handleFilter('STATUS_EXPIRING')}
                                          style={filter.current === 'STATUS_EXPIRING' ? {
                                              'color': themeContext.color.yellow,
                                              'fontWeight': '800',
                                              'backgroundColor': themeContext.color.neutral10
                                          } : {'color': themeContext.color.yellow}}>
                                    {filter.current === 'STATUS_EXPIRING' ? <Fragment>
                                        {t('dashboard.filter-expirity')}<img src={exitIcon}
                                                                             className="ml-auto"
                                                                             alt="exit-icon"/>
                                    </Fragment> : t('dashboard.filter-expirity')}
                                </MenuItem>
                                <MenuItem className="filter-item" onClick={() => handleFilter('STATUS_EXPIRED')}
                                          style={filter.current === 'STATUS_EXPIRED' ? {
                                              'color': themeContext.color.red,
                                              'fontWeight': '800',
                                              'backgroundColor': themeContext.color.neutral10
                                          } : {'color': themeContext.color.red}}>
                                    {filter.current === 'STATUS_EXPIRED' ? <Fragment>
                                        {t('dashboard.filter-expired')}<img src={exitIcon}
                                                                            className="ml-auto"
                                                                            alt="exit-icon"/>
                                    </Fragment> : t('dashboard.filter-expired')}
                                </MenuItem>
                                <MenuItem className="filter-item"
                                          onClick={() => handleFilter('STATUS_INACTIVE')}
                                          style={filter.current === 'STATUS_INACTIVE' ? {
                                              'color': themeContext.color.neutral200,
                                              'fontWeight': '800',
                                              'backgroundColor': themeContext.color.neutral10
                                          } : {'color': themeContext.color.neutral200}}>
                                    {filter.current === 'STATUS_INACTIVE' ? <Fragment>
                                        {t('dashboard.filter-killed')}<img src={exitIcon}
                                                                           className="ml-auto"
                                                                           alt="exit-icon"/>
                                    </Fragment> : t('dashboard.filter-killed')}
                                </MenuItem>
                            </Menu>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {!noPeople ? <Container className="top-of-the-payment"></Container> :
                <Container className="top-of-the-payment" style={{'paddingTop': '212px'}}></Container>}
            <Row className="mt-2" style={{'backgroundColor': themeContext.color.neutral10}}>
                <Col>
                    {isLoadingNotifications && <Fragment>
                        <Row className="mt-2">
                            <Col className="col-list">
                                {width_min && !width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                {width_min && width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && width_1199 &&
                                    <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-list">
                                {width_min && !width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                {width_min && width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && width_1199 &&
                                    <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-list">
                                {width_min && !width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                {width_min && width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && width_1199 &&
                                    <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-list">
                                {width_min && !width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_min} width="100%" alt="card"/>}
                                {width_min && width_767 && !width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_678} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && !width_1199 &&
                                    <img src={cardPlaceholder_918} width="100%" alt="card"/>}
                                {width_min && width_767 && width_991 && width_1199 &&
                                    <img src={cardPlaceholder_full} width="100%" alt="card"/>}
                            </Col>
                        </Row>
                    </Fragment>}
                    {selectedAvatar.current !== 'null' && !isLoadingNotifications && paymentNotification.length === 0 &&
                        <Container>
                            <Row>
                                <Col>
                                    <img src={nothing}
                                         alt={t('payment.nothing-text-1')}
                                    ></img>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="no-messages-text">{t('payment.nothing-text-1')}</span>
                                </Col>
                            </Row>
                        </Container>
                    }
                    {selectedAvatar.current === 'null' && !isLoadingNotifications && paymentNotification.length === 0 &&
                        <Container>
                            <Row>
                                <Col>
                                    <img src={nothing}
                                         alt={t('payment.nothing-text-1')}
                                    ></img>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="no-messages-text">{t('payment.nothing-text-1')}</span>
                                </Col>
                            </Row>
                        </Container>
                    }
                    {!isLoadingNotifications && !isNothingPeople && paymentNotification.map((value, index) => (
                        <Card className={classes.card}
                              key={index}
                              onClick={(e) => {
                                  if (!slimpayPeople.includes(value.personId)) {
                                      let policiesArr = []
                                      if (value.length > 0) {
                                          value.map((title, indexTitle) => {
                                              if (!policiesArr.includes(title.policy_id)) {
                                                  policiesArr.push(title.policy_id)
                                              }
                                          })
                                      } else {
                                          if (!policiesArr.includes(value.policy_id)) {
                                              policiesArr.push(value.policy_id)
                                          }
                                      }
                                      e.stopPropagation();
                                      if (value.notificationType !== 'SDD') {
                                          onClickPaymentNotification(value.id, value.requestedPremium, value.personId, policiesArr)
                                      }
                                  }
                              }}>
                            {!slimpayPeople.includes(value.personId) ?
                                <CardContent style={{'paddingBottom': '0px'}}>
                                    <Row>
                                        <Col className="col-auto mr-0 pr-0">
                                            {value.notificationType !== 'SDD' && <Radio
                                                checked={parseInt(selectedRadio) === value.id}
                                                name={value.requestedPremium}
                                                value={value.id}
                                                inputProps={{'aria-label': 'radio'}}
                                                className="justify-content-end radio-custom"
                                                color="primary"
                                                size="small"
                                            />}
                                        </Col>
                                        <Col className="col-7 pl-1">
                                            <h6 className={classes.cardTextMid}>
                                                {people.map((person, index) => (person.policyHolderId === value.policyHolderId ? (((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                        <span key={index}>{person.name}</span> : <span
                                                            key={index}>{person.firstName} {person.lastName}</span>) :
                                                    <span key={index}></span>))}
                                            </h6>
                                        </Col>
                                        <Col className="col" style={{textAlign: 'end', paddingRight: '22px'}}>
                                            {value.notificationType === 'SDD' &&
                                                <img style={{marginTop: '-7px'}} height="40px"
                                                     src={slimpayLogo} alt="slimpay-logo"/>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h6 className={"card-text-content"}>
                                                    <span className="ellipsis-1">
                                                    {t('payment.advice-id')}
                                                        <span className="font-weight-bold black">
                                                            {value.nr}
                                                        </span>
                                                    </span>
                                            </h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h6 className={"card-text-content"}>
                                                    <span className="ellipsis-1">
                                                    {t('payment.expiry-date')}
                                                        <Moment locale="it" format="DD/MM/YYYY"
                                                                className="black font-weight-bold">
                                                            {value.datebill}
                                                        </Moment>
                                                    </span>
                                            </h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h6 className={"card-text-content"}
                                                style={{
                                                    'lineHeight': '1.3',
                                                    'textTransform': 'uppercase'
                                                }}>
                                                    <span className="ellipsis-1">
                                                    {t('payment.total')}
                                                        <span className="font-weight-bold blue" style={{
                                                            'fontSize': '18px'
                                                        }}>
                                                            {value.requestedPremium}
                                                    </span>
                                                </span>
                                            </h6>
                                        </Col>
                                        <Col style={{
                                            'textAlign': 'right',
                                            'paddingLeft': '0',
                                            'paddingBottom': '0px',
                                            'marginBottom': '8px',
                                            'marginTop': '8px'
                                        }}>
                                            <Row>
                                                <Col>
                                                    <Button style={{'marginTop': '0px'}}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onClickPaymentNotificationSummary(value.id)
                                                            }}
                                                            className={classes.detailButton}>{t('payment.detail-button')}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardContent> : <CardContent style={{'paddingBottom': '16px'}}>
                                    <Row>
                                        <Col className="col-9">
                                            <h6 className={classes.cardTextMid}>
                                                {people.map((person, index) => (person.policyHolderId === value.policyHolderId ? (((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                        <span key={index}>{person.name}</span> : <span
                                                            key={index}>{person.firstName} {person.lastName}</span>) :
                                                    <span key={index}></span>))}
                                            </h6>
                                        </Col>
                                        <Col className="col-3">
                                            <img style={{marginTop: '-7px'}} height="40px"
                                                 src={slimpayLogo} alt="slimpay-logo"/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span style={{
                                                float: 'left',
                                                textAlign: 'left',
                                                color: themeContext.color.neutral600,
                                                fontSize: '12px'
                                            }}>
                                                {t('paymentsStatus.slimpay-pending')}
                                            </span>
                                        </Col>
                                    </Row>
                                </CardContent>}
                        </Card>))}
                </Col>
            </Row>
            <Container className="bottom-with-navbar" style={{'backgroundColor': themeContext.color.neutral10}}></Container>
        </Container>
    </Fragment>)
}