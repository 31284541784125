import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import wideLogoLight from '../../images/wideLogo-icon.svg';
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import CryptoJS from 'crypto-js';
import * as idb from "idb";
import jwt_decode from 'jwt-decode';
import {useNavigate} from "react-router-dom";
import back from "../../images/back-icon.svg";
import {useTranslation} from "react-i18next";
import DialogSmall from "../components/DialogSmall.js";
import themeContext from "../components/ThemeContext.js";

export default function Pin() {

    useEffect(() => {
        setPinInputFocus(true);
        if (navigator.onLine) {
            getInfo();
        } else {
            const dbPromise = idb.openDB('appDB', 1, {});
            dbPromise.then((db) => {
                let tx = db.transaction('token', 'readwrite').objectStore('token');
                return tx.get("token")
            }).then((data) => {
                if (!!data.value) {
                    //il codice esiste
                    setOfflineToken(data.value);
                }
                let decoded = jwt_decode(data.value);
                let valid = (new Date(decoded.exp).getTime() * 1000) > new Date(Date.now()).getTime();
                if (valid) {
                    dbPromise.then((db) => {
                        let tx = db.transaction('pin', 'readwrite').objectStore('pin');
                        return tx.get("pin")
                    }).then((data) => {
                        if (!!data.value) {
                            //il codice esiste
                            setPin(data.value);
                            setIsPin(true);
                        } else {
                            //il codice non esiste e l'utente deve loggarsi online almeno una volta
                            setOpenDialog(true);
                        }
                    }).catch(() => {
                        setOpenDialog(true);
                    });
                } else {
                    // token scaduto
                    setTokenExpired(true)
                    setOpenDialog(true);
                }
            }).catch(() => {
                //console.log("catched")
            });
            if (!tokenExpired) {
                dbPromise.then((db) => {
                    let tx = db.transaction('pin', 'readwrite').objectStore('pin');
                    return tx.get("pin")
                }).then((data) => {
                    if (!!data.value) {
                        //il codice esiste
                        setPin(data.value);
                        setIsPin(true);
                        getOfflineInfo();
                    } else {
                        //il codice non esiste e l'utente deve loggarsi online almeno una volta
                        setOpenDialog(true);
                    }
                }).catch(() => {
                    setOpenDialog(true);
                });
            }
        }
    }, []);

    //const [input, setInput] = useState("");
    const authCtx = useContext(AuthContextMiddleware);
    const [arrayPin, setArrayPin] = useState([null, null, null, null, null, null]);
    const [selectedCell, setSelectedCell] = useState(0);
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const error = authCtx.error;
    //const token = authCtx.token;
    const token = sessionStorage.getItem('token');
    const editPin = sessionStorage.getItem('pin');
    const activePin = localStorage.getItem('pinIsActive');
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;

    const [isPin, setIsPin] = useState(authCtx.isSetFirstPin);
    const [pin, setPin] = useState();
    const [offlineToken, setOfflineToken] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);
    const countError = authCtx.countError;
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();


    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        //setOpenDialog(false);
    };

    window.addEventListener('online', () => {
        window.location.reload();
        return 0;
    });

    function getInfo() {
        fetch(userInfoUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setFirstName(data.firstName);
            setLastName(data.lastName);
        })
    }

    function getOfflineInfo() {
        const dbPromise = idb.openDB('appDB', 1, {
            upgrade(upgradeDb) {
                if (!upgradeDb.objectStoreNames.contains('profileInfo')) {
                    upgradeDb.createObjectStore('profileInfo', {keyPath: 'key'});
                }
            }
        });
        dbPromise.then((db) => {
            let tx = db.transaction('profileInfo', 'readwrite').objectStore('profileInfo');
            return tx.getAll();
        }).then((array) => {
            for (let i = 0; i < array.length; i++) {
                if (array[i].key === 'firstName') {
                    setFirstName(array[i].value)
                }
                if (array[i].key === 'lastName') {
                    setLastName(array[i].value)
                }
            }
        });
    }

    const onSubmitHandler = event => {
        if (navigator.onLine) {
            let strPin = "";
            for (let i = 0; i < arrayPin.length; i++) {
                strPin = strPin + arrayPin[i];
            }
            //cripto il pin dopo il primo inserimento
            let cipherPin = CryptoJS.AES.encrypt(strPin, process.env.REACT_APP_SECRET_KEY).toString();
            const dbPromise = idb.openDB('appDB', 1, {
                upgrade(upgradeDb) {
                    if (!upgradeDb.objectStoreNames.contains('pin')) {
                        upgradeDb.createObjectStore('pin', {keyPath: 'key'});
                    }
                }
            });
            dbPromise.then((db) => {
                let tx = db.transaction('pin', 'readwrite').objectStore('pin');
                tx.put({
                    key: 'pin',
                    value: cipherPin
                });
            }).then((data) => {
                authCtx.loginPin(cipherPin)
                return navigate('/settings/profile/code/confirm')
            });
        } else {
            if (isPin === true) {
                //login offline
                let strPin = "";
                for (let i = 0; i < arrayPin.length; i++) {
                    strPin = strPin + arrayPin[i];
                }
                let bytes = CryptoJS.AES.decrypt(pin, process.env.REACT_APP_SECRET_KEY);
                let pinDecrypted = bytes.toString(CryptoJS.enc.Utf8);
                if (pinDecrypted === strPin) {
                    authCtx.loginOffline(offlineToken, pin);
                    return navigate('/dashboard');
                } else {
                    authCtx.wrongOfflinePin();
                }
            }
        }
    };

    const handlePin = () => {
        setPinInputFocus(true);
        return null;
    }

    const setPinInputFocus = event => {
        const input = document.getElementById("pin-input");
        if (event && input) {
            input.focus();
        } else if (input) {
            input.blur();
        }
    }

    const handleInput = event => {
        if (!isNaN(event.key)) {
            //setArrayPin(arrayPin[selectedCell])
            if (selectedCell < 6) {
                arrayPin[selectedCell] = parseInt(event.key) // non scrive nella casella finale
                document.getElementById(`dot-${selectedCell}`).classList.remove('hidden');
            }
            if (selectedCell < 5) {
                setSelectedCell(selectedCell + 1);
            } else if (selectedCell >= 5) {
                setPinInputFocus(false)
                if (selectedCell === 5) {
                    setSelectedCell(selectedCell + 1);
                    onSubmitHandler();
                }
            }
        } else if (event.key === 'Backspace') {
            let actual = selectedCell;
            if (selectedCell > 0) {
                setSelectedCell(selectedCell - 1);
                actual--;
            }
            arrayPin[actual] = null;
            document.getElementById(`dot-${actual}`).classList.add('hidden');
        } else {
        }
    }

    const handleBack = () => {
        /*
        if (activePin) {
            authCtx.undoSetPin();
            navigate(-1)
        } else if (editPin) {
            //
        } else{
            authCtx.undoChangePin();
            navigate('/login');
        }
        */
        authCtx.undoSetPin();
        navigate(-1)
    }

    /*
    const AnimatedComponent = styled.div`
  animation: ${blinkingEffect} 1s linear infinite;
`*/

    return (
        <div className="container-fluid" style={{'backgroundColor': themeContext.color.white}}>
            <div className="row no-gutter">
                <div className="col-md-6 d-none d-md-flex bg-image"></div>
                <div className="col-md-6 ">
                    <Row className="nav-top container"
                         style={{
                             'background': 'none',
                             'paddingLeft': '0',
                             'paddingRight': '0'
                         }}>
                        <Col className="col-4 float-left">
                            <img src={back} onClick={handleBack} className="nav-top-logo float-left cursor-pointer"
                                 height="27px"
                                 alt="back"/>
                        </Col>
                    </Row>
                    <div className="login d-flex align-items-center">
                        <div className="container">
                            <Row className="d-flex justify-content-center align-items-center">
                                <Container className="d-flex justify-content-center align-items-center">
                                    <Row className="w-100">
                                        <img className="wide-logo" src={wideLogoLight} height="50px" alt="wide-logo"/>
                                    </Row>
                                </Container>
                                <Container>
                                    <Row className="mt-4 align-center">
                                        <Col>
                                            {/*editPin && <h6 className="pin-text-top font-weight-bold text-uppercase">{t('pin.text-top-edit')}</h6>*/}
                                            {!error /*&& !editPin*/ &&
                                                <h6 className="pin-text-top font-weight-bold">{t('pin.text-top')} {firstname}{t('pin.text-top-!')}</h6>}
                                            {navigator.onLine && error /*&& !editPin*/ &&
                                                <h6 className="pin-text-top font-weight-bold red text-uppercase">{t('pin.text-top-error-1')}
                                                </h6>}
                                            {!navigator.onLine && error/* && !editPin*/ &&
                                                <h6 className="pin-text-top font-weight-bold red text-uppercase">{t('pin.text-top-error-2')}</h6>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mt-1 align-center">
                                        <Col>
                                            {/*editPin && <h6 className="pin-text-top">{t('pin.text-mid-1')}</h6>*/}
                                            {navigator.onLine && !error /*&& !editPin*/ &&
                                                <h6 className="pin-text-top">{t('pin.text-mid-2')}</h6>}
                                            {!navigator.onLine && !error /*&& !editPin*/ &&
                                                <h6 className="pin-text-top">{t('pin.text-mid-3')}</h6>}
                                            {navigator.onLine && error /*&& !editPin*/ &&
                                                <h6 className="pin-text-top">{t('pin.text-mid-error-1')}</h6>}
                                            {!navigator.onLine && error /*&& !editPin*/ &&
                                                <h6 className="pin-text-top">{t('pin.text-mid-error-2')}</h6>}
                                        </Col>
                                    </Row>
                                    <Row className="mt-1 align-center">
                                        <Col>
                                            <h6 className="pin-text-top">{t('pin.text-criterias')}</h6>
                                        </Col>
                                    </Row>
                                    {!error &&
                                        <Row className="mt-4 align-center row-pin" onClick={handlePin}>

                                            <Col className="pin m-2">
                                                <span className="dot hidden" id="dot-0"></span>
                                            </Col>
                                            <Col className="pin m-2">
                                                <span className="dot hidden" id="dot-1"></span>
                                            </Col>
                                            <Col className="pin m-2">
                                                <span className="dot hidden" id="dot-2"></span>
                                            </Col>
                                            <Col className="pin m-2">
                                                <span className="dot hidden" id="dot-3"></span>
                                            </Col>
                                            <Col className="pin m-2">
                                                <span className="dot hidden" id="dot-4"></span>
                                            </Col>
                                            <Col className="pin m-2">
                                                <span className="dot hidden" id="dot-5"></span>
                                            </Col>
                                        </Row>
                                    }
                                    {error &&
                                        <Row className="mt-4 align-center row-pin" onClick={handlePin}>

                                            <Col className="pin m-2 pin-red">
                                                <span className="dot dot-red hidden" id="dot-0"></span>
                                            </Col>
                                            <Col className="pin m-2 pin-red">
                                                <span className="dot dot-red hidden" id="dot-1"></span>
                                            </Col>
                                            <Col className="pin m-2 pin-red">
                                                <span className="dot dot-red hidden" id="dot-2"></span>
                                            </Col>
                                            <Col className="pin m-2 pin-red">
                                                <span className="dot dot-red hidden" id="dot-3"></span>
                                            </Col>
                                            <Col className="pin m-2 pin-red">
                                                <span className="dot dot-red hidden" id="dot-4"></span>
                                            </Col>
                                            <Col className="pin m-2 pin-red">
                                                <span className="dot dot-red hidden" id="dot-5"></span>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className="div-pin mb-4">
                                        <Col>
                                            <input type="number" inputMode="numeric" id="pin-input"
                                                   onKeyDown={handleInput}/>
                                        </Col>
                                    </Row>
                                    <Container className="end-of-the-page"></Container>
                                    <Row className="mt-4 align-center">
                                        <Col>
                                            {navigator.onLine &&
                                                <h6 className="pin-text-bottom">{t('pin.online-info')}</h6>}
                                            {!navigator.onLine &&
                                                <h6 className="pin-text-bottom">{t('pin.offline-info')}</h6>}
                                        </Col>
                                    </Row>
                                </Container>
                                <Container className="end-of-the-page"></Container>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <DialogSmall open={openDialog}
                         title={tokenExpired ? t('pin.modal-token-title') : t('pin.modal-fist-access-title')}
                         description={tokenExpired ? t('pin.modal-token-description') : t('pin.modal-first-access-description')}
                         handleClose={handleClose}
                         buttonText={t('pin.modal-confirm-button')}/>
        </div>
    );
}

