import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import goTo from "../../images/goto-icon.svg";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next'
import Moment from "react-moment";
import themeContext from "../components/ThemeContext.js";


const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: "0 0 0 0",
    },
    listItem: {
        textDecoration: "none",
        fontSize: '14px !important',
        color: themeContext.color.neutral600,
        paddingTop: '12.5px',
        paddingBottom: '10.5px',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    primary: {
        color: themeContext.color.main,
    },
    cardTextMid: {
        color: themeContext.color.neutral200,
        textAlign: 'left',
        lineHeight: '1',
        fontWeight: '400',
        fontSize: '16px',
        textTransform: 'uppercase',

    },
    cardTextLow: {
        color: themeContext.color.neutral400,
        textAlign: 'left',
        lineHeight: '1',
        fontSize: '14px',
        paddingTop: '5px'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

export default function TitlesHistory() {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        t,
        i18n
    } = useTranslation();
    const params = useParams();
    const [titlesHistory, setTitlesHistory] = useState([])
    const [documentTitle, setDocumentTitle] = useState([])
    const policyId = params.policyId;
    const personId = params.personId;
    const vehicleId = params.vehicleId;
    const claimId = params.claimId;
    const getTitlesHistoryUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/titlesHistory`;
    const titleDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/title`;
    const [serviceError, setServiceError] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getTitlesHistory();
        }
    }, []);

    const getTitlesHistory = () => {
        setIsLoadingData(true)
        fetch(`${getTitlesHistoryUrl}/${policyId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            data.map((value, index) => {
                setTitlesHistory(current => [...current, value]);
            })
            return titlesHistory;
        }).then((titlesHistory) => {
            let length = Object.keys(titlesHistory).length
            for (let i = 0; i < length; i++) {
                fetch(`${titleDocumentUrl}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        titleId: titlesHistory[i].id
                    })
                }).then((res) => {
                    if (res) {
                        return res.json();
                    }
                }).then((res) => {
                    if (res !== []) {
                        setDocumentTitle(arr => [...arr, true])
                    } else {
                        setDocumentTitle(arr => [...arr, false])
                    }
                });
            }
            setIsLoadingData(false)
        }).catch(() => {
            setServiceError(true)
        })
    }

    function reload() {
        window.location.reload();
    }

    const setDocumentList = () => {
        for (let i = 0; i < titlesHistory.length; i++) {
            fetch(`${titleDocumentUrl}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    titleId: titlesHistory[i].id
                })
            }).then((res) => {
                if (res) {
                    return res.json();
                }
            }).then((res) => {
                if (res !== []) {
                    setDocumentTitle(arr => [...arr, true])
                } else {
                    setDocumentTitle(arr => [...arr, false])
                }
            });
        }
    }

    const turnBack = () => {
        return navigate(-1);
    }

    const onClickTitle = (titleId) => {
        if (window.location.href.includes('/dashboard')) {
            return `/dashboard/policies/${policyId}/titlesHistory/${titleId}`;
        } else if (window.location.href.includes('/people')) {
            return `/settings/people/${personId}/policies/${policyId}/titlesHistory/${titleId}`;
        } else if (window.location.href.includes('/vehicles')) {
            return `/settings/vehicles/${vehicleId}/policies/${policyId}/titlesHistory/${titleId}`;
        } else if (window.location.href.includes('/claims')) {
            return `/claims/claim/${claimId}/${policyId}/titlesHistory/${titleId}`;
        }
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2">{t('titlesHistory.title')}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingBottom': '20px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col className="col-2 float-left">
                        <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                             height="27px"
                             alt="back"/>
                    </Col>
                    <Col className="col-8">
                        <h6 className="header-title ellipsis-1-v2">{t('titlesHistory.title')}</h6>
                    </Col>
                    <Col className="col-2">

                    </Col>
                </Row>
                <Container className="top-of-the-language"></Container>
                {
                    isLoadingData &&
                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                }
                {
                    !isLoadingData &&
                    <Row style={{
                        'paddingLeft': '0',
                        'paddingRight': '0px',
                    }}>
                        <Col style={{
                            'paddingLeft': '0',
                            'paddingRight': '0px'
                        }}>
                            <div className={classes.rootList}>
                                <List component="nav" aria-label="main mailbox folders">
                                    {
                                        titlesHistory.map((title, index) => (
                                            <Fragment key={index}>
                                                {
                                                    index !== 0 && <Divider/>
                                                }
                                                <Link style={{'textDecoration': 'none'}} to={onClickTitle(title.id)}>
                                                    <ListItem className={classes.listItem} button>
                                                        <Container>
                                                            <Row>
                                                                <Col>
                                                                    <h6 className={'card-text-mid'}>{title.titletype}</h6>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <h6 className={"card-text-content"}>
                                                                    <span className="ellipsis-1"
                                                                          style={{'paddingBottom': '1px'}}>{t('titlesHistory.date-expirity')}
                                                                        <span className="font-weight-bold black">
                                                                            <Moment locale="it"
                                                                                    format="DD/MM/YYYY">{title.dateexpiry}</Moment>
                                                                        </span>
                                                                    </span>
                                                                    </h6>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <h6 className={"card-text-content"}>{t('titlesHistory.date-bill')}
                                                                        <span
                                                                            className="font-weight-bold black">
                                                                        <Moment locale="it"
                                                                                format="DD/MM/YYYY">{title.datebill}
                                                                        </Moment>
                                                                    </span>
                                                                    </h6>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <h6 className={"card-text-content"}>
                                                                    <span
                                                                        className="ellipsis-1"
                                                                        style={{'paddingBottom': '1px'}}>{t('titlesHistory.premium')}
                                                                        <span
                                                                            className="font-weight-bold black">{title.premium}
                                                                    </span>
                                                                </span>
                                                                    </h6>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                        <ListItemIcon className="justify-content-end">
                                                            <img src={goTo} alt="image" height="20px"/>
                                                        </ListItemIcon>
                                                    </ListItem>
                                                </Link>
                                            </Fragment>
                                        ))
                                    }
                                </List>
                            </div>
                        </Col>
                    </Row>
                }
                {
                    /*
                    <Container className="end-of-the-page" style={{
                    'background': themeContext.color.white,
                    'borderLeft': '1px solid #EEE9E5',
                    'borderRight': '1px solid #EEE9E5',
                    'height': '100%',
                    'position': 'fixed',
                    'right': '0',
                    'left': '0'
                }}></Container>
                     */
                }

            </Container>

    )
}