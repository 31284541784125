import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import Moment from "react-moment";
import back from "../../images/back-icon.svg";
import dots from "../../images/dots-icon.svg";
import {makeStyles} from "@material-ui/core/styles";
import legalProtectionIcon from "../../images/policy_branches/legal-protection-icon.svg";
import farmingIcon from "../../images/policy_branches/farming-icon.svg";
import autoCvtIcon from "../../images/policy_branches/auto-cvt-icon.svg";
import petInsuranceIcon from "../../images/policy_branches/pet-insurance-icon.svg";
import depositsIcon from "../../images/policy_branches/deposits-icon.svg";
import tradeCreditsIcon from "../../images/policy_branches/trade-credits-icon.svg";
import electronicIcon from "../../images/policy_branches/electronic-icon.svg";
import energyIcon from "../../images/policy_branches/energy-icon.svg";
import rcAdminIcon from "../../images/policy_branches/rc-admin-icon.svg";
import artCollectionIcon from "../../images/policy_branches/art-collections-icon.svg";
import theftIcon from "../../images/policy_branches/theft-icon.svg";
import jewellersIcon from "../../images/policy_branches/jewellers-icon.svg";
import buildingsIcon from "../../images/policy_branches/buildings-icon.svg";
import injuresIcon from "../../images/policy_branches/injures-icon.svg";
import illnessIcon from "../../images/policy_branches/illness-icon.svg";
import wheaterIcon from "../../images/policy_branches/wheater-icon.svg";
import travelBusinnessLineIcon from "../../images/policy_branches/travel-businnes-line-icon.svg";
import companiesIcon from "../../images/policy_branches/companies-icon.svg";
import horecaIcon from "../../images/policy_branches/horeca-icon.svg";
import thirdSectorIcon from "../../images/policy_branches/third-sector-icon.svg";
import civiliansIcon from "../../images/policy_branches/civilians-icon.svg";
import officeLaboratoriesIcon from "../../images/policy_branches/office-laboratories-icon.svg";
import rcPollutionIcon from "../../images/policy_branches/rc-pollution-icon.svg";
import rcMedicalMalpracticeIcon from "../../images/policy_branches/rc-medical-malpractice-icon.svg";
import rcIndividuals from "../../images/policy_branches/rc-individuals-icon.svg";
import rcProfessionalIcon from "../../images/policy_branches/rc-professional-icon.svg";
import rcClinicalTrialsIcon from "../../images/policy_branches/rc-clinical-trials-icon.svg";
import technologiesIcon from "../../images/policy_branches/technologies-icon.svg";
import tourOperatorIcon from "../../images/policy_branches/tour-operator-icon.svg";
import certifiedTransportationIcon from "../../images/policy_branches/certified-transportation-icon.svg";
import shipIcon from "../../images/policy_branches/ship-icon.svg";
import collectiveLivesIcon from "../../images/policy_branches/collective-lives-icon.svg";
import individualLivesIcon from "../../images/policy_branches/individual-lives-icon.svg";
import aviationIcon from "../../images/policy_branches/aviation-icon.svg";
import cablewayIcon from "../../images/policy_branches/cableway-icon.svg";
import vectorIcon from "../../images/policy_branches/vector.svg";
import {useTranslation} from "react-i18next";
import themeContext from "../components/ThemeContext.js";

const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: "0 0 0 0",
    },
    listItem: {
        color: themeContext.color.neutral400,
    },
    listItemDisable: {
        color: themeContext.color.neutral400,
        backgroundColor: themeContext.color.neutral200,
        paddingTop: '18px',
        paddingBottom: '18px',
    },
    listText: {
        fontSize: '18px !important'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    titles: {
        color: themeContext.color.neutral200,
        textAlign: 'left',
        lineHeight: '1',
        fontWeight: '500',
        fontSize: '12px',
        textTransform: 'uppercase',
    },
    card: {
        margin: "0 auto",
        marginTop: "0px",
        background: `${themeContext.color.white} 0% 0% no-repeat padding-box`,
        boxShadow: "2px 3px 10px #00000033",
        borderRadius: "10px",
        opacity: "1",
        marginBottom: "30px"
    },
    cardTextHigh: {
        color: themeContext.color.white,
        textAlign: 'left',
        lineHeight: '1',
        fontWeight: '100',
        fontSize: '14px'
    },
    cardTextMid: {
        color: themeContext.color.neutral200,
        textAlign: 'left',
        lineHeight: '1',
        fontWeight: '400',
        fontSize: '16px',
        textTransform: 'uppercase',
        paddingTop: '7px',
        paddingBottom: '7px',
    },
    text: {
        color: themeContext.color.neutral600,
        textAlign: 'left',
        lineHeight: '2',
        fontSize: '16px',
    },
    textBold: {
        color: themeContext.color.neutral600,
        textAlign: 'left',
        lineHeight: '2',
        fontSize: '16px',
        fontWeight: 'bold',
    },
}));

export default function PaymentsTitleDetail() {
    const [isLoadingData, setIsLoadingData] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getTitleById();
        } else {
            //handleReadOffline(id, type);
        }
    }, []);

    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const params = useParams();
    const titleId = params.titleId;
    const getPolicyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies`;
    const getTitleByIdUrl = `${process.env.REACT_APP_BASE_URL}/payments/title`;
    const authCtx = useContext(AuthContextMiddleware);
    const [policy, setPolicy] = useState([]);
    const classes = useStyles();
    const [selectedRadio, setSelectedRadio] = useState([]);
    const [person, setPerson] = useState([]);
    const [paymentNotification, setPaymentNotification] = useState([]);
    const [paymentNotificationTitles, setPaymentNotificationTitles] = useState([]);
    const [requestedCents, setRequestedCents] = useState(0)
    const [serviceError, setServiceError] = useState(false);
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const [title, setTitle] = useState([]);



    const getTitleById = () => {
        fetch(`${getTitleByIdUrl}/${titleId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setTitle(data)
        }).catch(() => {
            setServiceError(true)
        });
    }

    const turnBack = () => {
        return navigate(-1);
    }

    function reload() {
        window.location.reload();
    }

    const getBranchIcon = (policyType) => {
        switch (policyType) {
            case "TUTELA LEGALE":
                return legalProtectionIcon;
            case "AGRICOLTURA":
                return farmingIcon;
            case "AUTO (RCA - CVT)":
            case "AUTO (CVT)":
            case "CVT AUTO D'EPOCA E SPORTIVE":
            case "CVT SOCCORSO":
            case "KASKO CHILOMETRICA":
                return autoCvtIcon;
            case "PET INSURANCE":
                return petInsuranceIcon;
            case "CAUZIONI":
                return depositsIcon;
            case "CREDITI COMMERCIALI":
                return tradeCreditsIcon;
            case "ELETTRONICA":
                return electronicIcon;
            case "ENERGY":
                return energyIcon;
            case "RC AMMINISTRATORI (D&O)":
            case "RC COLPA GRAVE":
                return rcAdminIcon;
            case "FINE ART E COLLEZIONI":
                return artCollectionIcon;
            case "FURTO":
            case "FURTO PRIVATI":
                return theftIcon;
            case "GLOBALE GIOIELLERIE":
                return jewellersIcon;
            case "GLOBALE FABBRICATI":
                return buildingsIcon;
            case "INFORTUNI":
            case "INFORTUNI COLLETTIVA":
            case "INFORTUNI CONDUCENTI":
            case "INFORTUNI E MALATTIA":
            case "INFORTUNI TERZO SETTORE":
            case "PRESTAZIONI DI ASSISTENZA SANITARIA":
                return injuresIcon;
            case "MALATTIA":
                return illnessIcon;
            case "METEO":
                return wheaterIcon;
            case "VIAGGI LINEA BUSINESS":
            case "VIAGGI LINEA PERSONA":
                return travelBusinnessLineIcon;
            case "MULTIRISCHI AZIENDE":
            case "PROPERTY AZIENDE":
            case "RC AZIENDE":
                return companiesIcon;
            case "MULTIRISCHI HO.RE.CA.":
                return horecaIcon;
            case "MULTIRISCHI TERZO SETTORE":
            case "RC TERZO SETTORE":
                return thirdSectorIcon;
            case "MULTIRISCHI CIVILI":
            case "PROPERTY RISCHI CIVILI":
                return civiliansIcon;
            case "MULTIRISCHI UFFICI/LABORATORI":
                return officeLaboratoriesIcon;
            case "RC INQUINAMENTO":
                return rcPollutionIcon;
            case "RC MEDICAL MALPRACTICE":
                return rcMedicalMalpracticeIcon;
            case "RC PRIVATI":
                return rcIndividuals;
            case "RC PROFESSIONALE":
                return rcProfessionalIcon;
            case "RC SPERIMENTAZIONE CLINICA":
                return rcClinicalTrialsIcon;
            case "TECNOLOGICI":
                return technologiesIcon;
            case "AGENZIE VIAGGI":
            case "TOUR OPERATOR / AGENZIA VIAGGI":
                return tourOperatorIcon;
            case "TRASPORTI CERTIFICATI IN ABBONAMENTO":
            case "TRASPORTO MERCI":
            case "STOCK & TRANSIT":
            case "SPEDIZIONIERI E VETTORI":
                return certifiedTransportationIcon;
            case "CORPI":
            case "RC E CORPI NATANTI":
            case "P&I PROTECTION & INDEMNITY":
                return shipIcon;
            case "VITA COLLETTIVE":
            case "RC PATRIMONIALE COLPA LIEVE":
                return collectiveLivesIcon;
            case "VITA INDIVIDUALI":
                return individualLivesIcon;
            case "AVIAZIONE":
                return aviationIcon;
            case "PERDITE PECUNIARIE":
                return depositsIcon;
            case "CYBER RISK":
                //icona mancante
                return theftIcon;
            case "MULTIRISCHI IMPIANTI A FUNE":
                return cablewayIcon;
            default:
                return vectorIcon;
        }
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container" style={{
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2">{t('paymentsTitleDetail.title')}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container" style={{
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Row className="first-nav-top-row-shadow">
                                <Col className="col-2 float-left">
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px"
                                         alt="back"/>
                                </Col>
                                <Col className="col-8">
                                    <h6 className="header-title ellipsis-1-v2">{t('paymentsTitleDetail.title')}</h6>
                                </Col>
                                <Col className="col-2 float-right">
                                    <img hidden src={dots} className="nav-top-logo float-right cursor-pointer"
                                         height="27px"
                                         alt="dots"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        isLoadingData &&
                                        <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Container className="top-of-the-payment-title-detail"></Container>
                    <Container style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingLeft': '0',
                        'paddingRight': '0px',
                    }}>
                        <Row>
                            <Col style={{'textAlign': 'left'}}>
                                <Row>
                                    <Col className="col-1 mr-0 pr-0">
                                        <img src={getBranchIcon(title.branchName)} alt="branch-icon"
                                             className="branch-icon"/>
                                    </Col>
                                    <Col className="col-auto float-left">
                                        <h6 className={classes.cardTextMid}>{title.branchName}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className={classes.text}>
                                            {t('paymentsTitleDetail.sent')}
                                            <Moment
                                                locale="it"
                                                format="DD/MM/YYYY" className={classes.textBold}>{title.sent}</Moment>
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className={classes.textBold}>
                                            {title.titletype}
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className={classes.text}>
                                            {t('paymentsTitleDetail.effect-date')}
                                            <Moment className={classes.textBold}
                                                    locale="it"
                                                    format="DD/MM/YYYY">{title.datebill}</Moment>
                                        </span>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className={classes.text}>
                                            {t('paymentsTitleDetail.expiry-date')}
                                            <Moment className={classes.textBold}
                                                    locale="it"
                                                    format="DD/MM/YYYY">{title.dateexpiry}</Moment>
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className={classes.textBold}>
                                            {title.premium}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
    )
}