import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next'
import Iframe from 'react-iframe'
import Divider from "@material-ui/core/Divider";
import UseCopyToClipboard from "../middleware/UseCopyToClipboard.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import copyIcon from "../../images/copy-icon.svg";
import infoPaymentIcon from "../../images/payment-info-icon.svg";
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {publish} from "../service/EventService.js";
import themeContext from "../components/ThemeContext.js";

const useStyles = makeStyles((theme) => ({
    listTextSelected: {
        color: themeContext.color.neutral600,
        fontSize: '16px !important'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    primary: {
        color: themeContext.color.main,
    },
    cardTextLow: {
        color: themeContext.color.neutral400,
        textAlign: 'left',
        lineHeight: '1',
        fontSize: '14px',
        paddingTop: '12px',
        paddingBottom: '6px'
    },
    infoText: {
        fontSize: '12px',
        fontWeight: 'normal',
        color: themeContext.color.neutral400,
    },
    rootList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: "0 0 0 0",
    },
    listItem: {
        color: themeContext.color.neutral400,
        paddingTop: '18px',
        paddingBottom: '18px',
    },
    listTextTip: {
        textTransform: 'uppercase',
        color: themeContext.color.neutral400,
        fontSize: '12px !important',
    },
    listTextDescr: {
        color: themeContext.color.neutral400,
        fontSize: '18px !important',
        fontWeight: 'bold'
    },
}));

export default function PaymentsCheckout() {
    const generatePaymentUrlUrl = `${process.env.REACT_APP_BASE_URL}/payments/notifications/getPaymentUrl`;
    const getSlimpayOrderInfoUrl = `${process.env.REACT_APP_BASE_URL}/payments/notifications/getSlimpayOrderStatus`;
    const setSlimpayIsPendingUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/setSlimpayPendingStatus`;
    const isNexiOrderPaidUrl = `${process.env.REACT_APP_BASE_URL}/payments/notifications/isNexiOrderPaid`;
    const sendPaymentReceivedUrl = `${process.env.REACT_APP_BASE_URL}/payments/sendPaymentReceived`;
    const sendUpdatePassUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/sendUpdatePass`;
    const operationUrl = `${process.env.REACT_APP_BASE_URL}/management/operation`;
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const [serviceError, setServiceError] = useState(false);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const paymentType = query.get('paymentType');
    const notificationId = query.get('notificationId');
    const personId = query.get('personId');
    const [url, setUrl] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const reason = sessionStorage.getItem('reason')
    const total = sessionStorage.getItem('total')
    const [value, copy] = UseCopyToClipboard()
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [slimpayOrderId, setSlimpayOrderId] = useState('');
    const [isSentNotification, setIsSentNotification] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            if (paymentType !== 'bankTransfer') {
                generatePaymentUrl(paymentType, notificationId)
            }
        }
    }, []);

    function turnBack() {
        reloadMenu(token);
        if (paymentType === 'bankTransfer') {
            return navigate(-1);
        } else {
            if (slimpayOrderId !== '') {
                getSlimpayOrderInfo();
            }
            if (paymentType === 'nexi') {
                getNexiOrderInfo();
            }
            return navigate('/payments');
        }

    }

    function reload() {
        window.location.reload();
    }

    const generatePaymentUrl = (paymentType, notificationId) => {
        setIsLoading(true)
        fetch(`${generatePaymentUrlUrl}?paymentType=${paymentType}&notificationId=${notificationId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            console.log("data generatePaymentUrl:", data)
            if (data.ok) {
                setUrl(data.message)
                if (paymentType === 'sdd') {
                    setSlimpayOrderId(data.orderId)
                }
                setIsLoading(false)
            } else {
                setServiceError(true)
                console.log('service error 1');
                setIsLoading(false)
            }
        }).catch(() => {
            setServiceError(true)
            console.log('service error 2');
            setIsLoading(false)
        });
    }

    const getSlimpayOrderInfo = () => {
        fetch(`${getSlimpayOrderInfoUrl}?orderId=${slimpayOrderId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            console.log("data getSlimpayOrderInfo:", data)
            if (data.state === 'closed.completed') {
                setSlimpayIsPending();
                if (!isSentNotification) {
                    sendPaymentReceived(paymentType);
                }
            }
        }).catch(() => {
            setServiceError(true)
            console.log('service error 3');
        });
    }

    const sendPaymentReceived = (paymentType) => {
        fetch(`${operationUrl}/complete`, {
            method: 'POST',
            body: JSON.stringify({
                type: 'PAYMENT',
                entityId: notificationId
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }).then((resp) => {
            console.log('operation',resp)
        });

        fetch(sendPaymentReceivedUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                userId: localStorage.getItem('wideAPP_userId'),
                type: paymentType === 'sdd' ? 'slimpay' : paymentType,
                notificationId: notificationId
            }),
        }).then((res) => {
            console.log("data sendPaymentReceived:", res)
            JSON.parse(localStorage.getItem('policiesArray')).map((policyId) => {
                fetch(sendUpdatePassUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        userId: localStorage.getItem('wideAPP_userId'),
                        policyId: policyId,
                    }),
                }).then((resp) => {
                    console.log(resp)
                });
            })
            setIsSentNotification(true)
            return res.json();
        }).catch(() => {
            setServiceError(true)
            console.log('service error 4');
        })
    }

    const getNexiOrderInfo = () => {
        fetch(`${isNexiOrderPaidUrl}?notificationId=${notificationId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            console.log("data getNexiOrderInfo:", data)
            if (data.ok) {
                if (!isSentNotification) {
                    sendPaymentReceived(paymentType);

                }
            }
        }).catch(() => {
            setServiceError(true)
            console.log('service error 5');
        });
    }

    const setSlimpayIsPending = () => {
        fetch(`${setSlimpayIsPendingUrl}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                personId: personId,
                slimpayIsPending: true
            }),
        }).then((response) => {
            console.log("data setSlimpayIsPending:", response)
            return response.json();
        }).catch(() => {
            setServiceError(true)
            console.log('service error 6');
        });
    }

    const handleTooltipClose1 = () => {
        setOpen1(false);
    };

    const handleTooltipOpen1 = () => {
        setOpen1(true);
        setTimeout(function () {
            handleTooltipClose1();
        }, 3000);
    };

    const handleTooltipClose2 = () => {
        setOpen2(false);
    };

    const handleTooltipOpen2 = () => {
        setOpen2(true);
        setTimeout(function () {
            handleTooltipClose2();
        }, 3000);
    };

    const handleTooltipClose3 = () => {
        setOpen3(false);
    };

    const handleTooltipOpen3 = () => {
        setOpen3(true);
        setTimeout(function () {
            handleTooltipClose3();
        }, 3000);
    };

    const handleTooltipClose4 = () => {
        setOpen4(false);
    };

    const handleTooltipOpen4 = () => {
        setOpen4(true);
        setTimeout(function () {
            handleTooltipClose4();
        }, 3000);
    };

    const handleTooltipClose5 = () => {
        setOpen5(false);
    };

    const handleTooltipOpen5 = () => {
        setOpen5(true);
        setTimeout(function () {
            handleTooltipClose5();
        }, 3000);
    };

    const reloadMenu = (token) => {
        publish('reloadMenu', token);
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="container nav-top border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                {paymentType === 'nexi' &&
                                    <h6 className="header-title ellipsis-1-v2">{t('paymentsCheckout.title-nexi')}</h6>}
                                {paymentType === 'sdd' &&
                                    <h6 className="header-title ellipsis-1-v2">{t('paymentsCheckout.title-sdd')}</h6>}
                                {paymentType === 'bankTransfer' &&
                                    <h6 className="header-title ellipsis-1-v2">{t('paymentsCheckout.title-bankTransfer')}</h6>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="container nav-top border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingBottom': '20px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col className="col-2 float-left">
                        <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                             height="27px"
                             alt="back"/>
                    </Col>
                    <Col className="col-8">
                        {paymentType === 'nexi' && <h6 className="header-title ellipsis-1-v2">{t('paymentsCheckout.title-nexi')}</h6>}
                        {paymentType === 'sdd' && <h6 className="header-title ellipsis-1-v2">{t('paymentsCheckout.title-sdd')}</h6>}
                        {paymentType === 'bankTransfer' &&
                            <h6 className="header-title ellipsis-1-v2">{t('paymentsCheckout.title-bankTransfer')}</h6>}
                    </Col>
                    <Col className="col-2">
                    </Col>
                </Row>
                {
                    isLoading &&
                    <div style={{'height': '100vh'}} className="background-loading-spinner"></div>
                }
                {
                    !isLoading && paymentType === 'sdd' &&
                    <div style={{'height': '100vh'}} className="background-loading-spinner">
                        <Iframe
                            className="responsive-iframe"
                            url={url}
                            display="block"
                            position="relative"
                            id="iframe_id"
                            onLoad={e => {
                                console.log('onload sdd', e)
                                getSlimpayOrderInfo()
                            }}
                        />
                    </div>
                }
                {
                    !isLoading && paymentType === 'nexi' &&
                    <div style={{'height': '100vh'}} className="background-loading-spinner">
                        <Iframe
                            className="responsive-iframe"
                            url={url}
                            display="block"
                            position="relative"
                            id="iframe_id"
                            onLoad={e => {
                                console.log('onload nexi', e)
                                getNexiOrderInfo()
                            }}
                        />
                    </div>
                }
                {
                    !isLoading && paymentType === 'bankTransfer' &&
                    <Fragment>
                        <Container className="top-of-the-checkout"></Container>
                        <Container style={{
                            'padding': '0'
                        }}>
                            <Row>
                                <Col style={{
                                    'paddingLeft': '0',
                                    'paddingRight': '0',
                                }}>
                                    <div className={classes.rootList}>
                                        <List component="nav" style={{'padding': '0'}}>
                                            <ClickAwayListener onClickAway={handleTooltipClose1}>
                                                <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={handleTooltipClose1}
                                                    open={open1}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={t('paymentsCheckout.copied-text')}
                                                    placement="left"
                                                >
                                                    <ListItem className={classes.listItem} button
                                                              onClick={() => {
                                                                  copy(`${reason}`);
                                                                  handleTooltipOpen1()
                                                              }}>
                                                        <ListItemText>
                                                            <Row className="mb-1">
                                                                <Col>
                                                        <span className={classes.listTextTip}>
                                                            {t('paymentsMethod.bankTransfer-reason')}
                                                        </span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                        <span className={classes.listTextDescr}>
                                                            {reason}
                                                        </span>
                                                                    <img src={copyIcon} alt="copy" style={{
                                                                        'height': '18px',
                                                                        'paddingLeft': '7px',
                                                                        'paddingBottom': '2px'
                                                                    }}/>
                                                                </Col>
                                                            </Row>
                                                        </ListItemText>
                                                    </ListItem>
                                                </Tooltip>
                                            </ClickAwayListener>
                                            <Divider/>
                                            <ClickAwayListener onClickAway={handleTooltipClose2}>
                                                <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={handleTooltipClose2}
                                                    open={open2}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={t('paymentsCheckout.copied-text')}
                                                    placement="left"
                                                >
                                                    <ListItem className={classes.listItem} button
                                                              onClick={() => {
                                                                  copy(`${t('paymentsMethod.bankTransfer-contractor-1')}`)
                                                                  handleTooltipOpen2()
                                                              }}>
                                                        <ListItemText>
                                                            <Row className="mb-1">
                                                                <Col>
                                                        <span className={classes.listTextTip}>
                                                            {t('paymentsMethod.bankTransfer-contractor')}
                                                        </span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                        <span className={classes.listTextDescr}>
                                                            {t('paymentsMethod.bankTransfer-contractor-1')}
                                                        </span>
                                                                    <img src={copyIcon} alt="copy" style={{
                                                                        'height': '18px',
                                                                        'paddingLeft': '7px',
                                                                        'paddingBottom': '2px'
                                                                    }}/>
                                                                </Col>
                                                            </Row>
                                                        </ListItemText>
                                                    </ListItem>
                                                </Tooltip>
                                            </ClickAwayListener>
                                            <Divider/>
                                            <ClickAwayListener onClickAway={handleTooltipClose3}>
                                                <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={handleTooltipClose3}
                                                    open={open3}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={t('paymentsCheckout.copied-text')}
                                                    placement="left"
                                                >
                                                    <ListItem className={classes.listItem} button
                                                              onClick={() => {
                                                                  copy(`${t('paymentsMethod.bankTransfer-iban-1')}`)
                                                                  handleTooltipOpen3()
                                                              }}>
                                                        <ListItemText>
                                                            <Row className="mb-1">
                                                                <Col>
                                                        <span className={classes.listTextTip}>
                                                            {t('paymentsMethod.bankTransfer-iban')}
                                                        </span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                        <span className={classes.listTextDescr}>
                                                            {t('paymentsMethod.bankTransfer-iban-1')}
                                                        </span>
                                                                    <img src={copyIcon} alt="copy" style={{
                                                                        'height': '18px',
                                                                        'paddingLeft': '7px',
                                                                        'paddingBottom': '2px'
                                                                    }}/>
                                                                </Col>
                                                            </Row>
                                                        </ListItemText>
                                                    </ListItem>
                                                </Tooltip>
                                            </ClickAwayListener>
                                            <Divider/>
                                            <ClickAwayListener onClickAway={handleTooltipClose4}>
                                                <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={handleTooltipClose4}
                                                    open={open4}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={t('paymentsCheckout.copied-text')}
                                                    placement="left"
                                                >
                                                    <ListItem className={classes.listItem} button
                                                              onClick={() => {
                                                                  copy(`${total.split('€')[0]}`)
                                                                  handleTooltipOpen4()
                                                              }}>
                                                        <ListItemText>
                                                            <Row className="mb-1">
                                                                <Col>
                                                        <span className={classes.listTextTip}>
                                                            {t('paymentsMethod.bankTransfer-total')}
                                                        </span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                        <span className={classes.listTextDescr}>
                                                            {total}
                                                        </span>
                                                                    <img src={copyIcon} alt="copy" style={{
                                                                        'height': '18px',
                                                                        'paddingLeft': '7px',
                                                                        'paddingBottom': '2px'
                                                                    }}/>
                                                                </Col>
                                                            </Row>
                                                        </ListItemText>
                                                    </ListItem>
                                                </Tooltip>
                                            </ClickAwayListener>
                                            <Divider/>
                                            <ClickAwayListener onClickAway={handleTooltipClose5}>
                                                <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={handleTooltipClose5}
                                                    open={open5}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={t('paymentsCheckout.copied-text')}
                                                    placement="left"
                                                >
                                                    <ListItem className={classes.listItem} button
                                                              onClick={() => {
                                                                  copy(`${t('paymentsMethod.bankTransfer-bic-swift-')}`)
                                                                  handleTooltipOpen5()
                                                              }}>
                                                        <ListItemText>
                                                            <Row className="mb-1">
                                                                <Col>
                                                        <span className={classes.listTextTip}>
                                                            {t('paymentsMethod.bankTransfer-bic-swift')}
                                                        </span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                        <span className={classes.listTextDescr}>
                                                            {t('paymentsMethod.bankTransfer-bic-swift-')}
                                                        </span>
                                                                    <img src={copyIcon} alt="copy" style={{
                                                                        'height': '18px',
                                                                        'paddingLeft': '7px',
                                                                        'paddingBottom': '2px'
                                                                    }}/>
                                                                </Col>
                                                            </Row>
                                                        </ListItemText>
                                                    </ListItem>
                                                </Tooltip>
                                            </ClickAwayListener>
                                            <Divider/>
                                            <ListItem>
                                                <Row className="mt-3">
                                                    <Col className="col-auto" style={{'paddingRight': '0'}}>
                                                        <img src={infoPaymentIcon} alt="info-payment-icon"/>
                                                    </Col>
                                                    <Col style={{
                                                        'textAlign': 'left',
                                                        'paddingLeft': '3px'
                                                    }}>
                                                    <span
                                                        className={classes.infoText}>{t('paymentsMethod.bankTransfer-info')}</span>
                                                    </Col>
                                                </Row>
                                            </ListItem>
                                        </List>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Fragment>
                }
            </Container>
    )
}
