import React, {Fragment} from 'react'
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import themeContext from "./ThemeContext.js";

const useStyles = makeStyles(() => ({
    backDrop: {
        backdropFilter: "blur(16px)",
        backgroundColor: themeContext.color.shadowBlack,
        opacity: 1
    },
    dialogTitle: {
        'textAlign': 'center',
        'paddingTop': '10px',
        'paddingBottom': '2px',
    },
    dialogTitle2: {
        'textAlign': 'center',
        'lineHeight': '1.3',
        'padding': '16px 24px',
        'fontSize': '15px',
        'fontWeight': '500'
    },
    dialogDescription: {
        'textAlign': 'center',
        'paddingTop': '0px',
        'paddingBottom': '15px',
    }
}));

/*
* isLoadingData: bool param showing a loader
* title: title of the screen, if passed an Array of String -> each item will wrap
* description: description of the screen, if passed an Array of String -> each item will wrap
* placeholder: an image center placed (src)
* buttonText: text of the button
* buttonAction: action of the button
* */
const PlaceholderScreen = ({isLoadingData, title, description, placeholder, buttonText, buttonAction}) => {
    const classes = useStyles();
    return (
        <Fragment>
            <Row className="pr-0 pl-0">
                <Col className="col-list">
                    {
                        isLoadingData &&
                        <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                    }
                    {
                        !isLoadingData &&
                        <Container>
                            {!Array.isArray(title) && <Row style={{'marginTop': '43.5px'}}>
                                <Col>
                                     <span style={{
                                         'fontSize': '16px',
                                         'color': themeContext.color.neutral600,
                                         'fontWeight': 'bold'
                                     }}>{title}</span>
                                </Col>
                            </Row>
                            }
                            {Array.isArray(title) ? title.map((item, index) => (
                                <Fragment key={index}>
                                    {index === 0 && <Row style={{
                                        'marginTop': '43.5px',
                                    }}></Row>}
                                    <Row className={'mt-2 mb-2'}>
                                        <Col>
                                            <span style={{
                                                'fontSize': '16px',
                                                'color': themeContext.color.neutral600,
                                                'fontWeight': 'bold'
                                            }}>{item}</span>
                                        </Col>
                                    </Row>
                                    {((index + 1) === title.length) && <Row style={{
                                        'marginBottom': '24.5px'
                                    }}></Row>}
                                </Fragment>
                            )) : null
                            }
                            {description && !Array.isArray(description) && <Row style={{
                                'marginTop': '12px',
                                'marginBottom': '44.5px'
                            }}>
                                <Col>
                                    <span style={{
                                        'fontSize': '14px',
                                        'color': themeContext.color.neutral600,
                                        'fontWeight': 'normal'
                                    }}>{description}</span>
                                </Col>
                            </Row>
                            }
                            {description && Array.isArray(description) ? description.map((item, index) => (
                                <Fragment key={index}>
                                    {index === 0 && <Row style={{
                                        'marginTop': '12px',
                                    }}></Row>}
                                    <Row className={'mt-2 mb-2'}>
                                        <Col>
                                            <span style={{
                                                'fontSize': '14px',
                                                'color': themeContext.color.neutral600,
                                                'fontWeight': 'normal'
                                            }}>{item}</span>
                                        </Col>
                                    </Row>
                                    {((index + 1) === description.length) && <Row style={{
                                        'marginBottom': '44.5px'
                                    }}></Row>}
                                </Fragment>
                            )) : null
                            }
                            <Row>
                                <Col>
                                    <img src={placeholder} alt="document-placeholder" width="100%"
                                         style={{'maxHeight': '340px'}}/>
                                </Col>
                            </Row>
                        </Container>
                    }
                </Col>
            </Row>
            <Container className="fixed-bottom"
                       style={{
                           'background': 'transparent',
                           'paddingBottom': "40px"
                       }}>
                <Row>
                    <Col>
                        <Button className="btn-custom text-capitalize"
                                variant="outlined"
                                size="large"
                                style={{'color': themeContext.color.white}} type="submit"
                                onClick={buttonAction}
                        >{buttonText}</Button>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
export default PlaceholderScreen;