import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import {Radio} from "@material-ui/core";
import {useTranslation} from 'react-i18next'
import themeContext from "../components/ThemeContext.js";


const useStyles = makeStyles((theme) => ({
    rootList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: "0 0 0 0",
    },
    listItem: {
        color: themeContext.color.neutral400,
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    listTextSelected: {
        color: themeContext.color.neutral600,
        fontSize: '16px !important'
    },
    listText: {
        fontSize: '16px !important'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    primary: {
        color: themeContext.color.main,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

export default function Language() {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedRadio, setSelectedRadio] = useState('');
    const { t, i18n } = useTranslation();
    const userInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/info`;
    const [serviceError, setServiceError] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getInfo();
        }
    }, []);


    function turnBack() {
        return navigate(-1);
    }

    function handleRadio(event) {
        setSelectedRadio(event.target.value)
        i18n.changeLanguage(event.target.value).then(r => {
            updateInfo(i18n.language);
        })
    }

    function handleListItem(lang) {
        setSelectedRadio(lang)
        i18n.changeLanguage(lang).then(r => {
            updateInfo(i18n.language);
        })
    }

    function getInfo() {
        fetch(userInfoUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            i18n.language = data.preferredLanguage
            setSelectedRadio(data.preferredLanguage)
        }).catch(() => {
            setServiceError(true)
        })
    }

    function updateInfo(language) {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({preferredLanguage: language})
        };
        fetch(userInfoUrl, requestOptions)
            .then(r => {
            }).catch(() => {
            setServiceError(true)
        });
    }

    function reload() {
        window.location.reload();
    }

    return (
        serviceError ?
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingLeft': '0',
                    'paddingRight': '0'
                }}>
                    <Col>
                        <Row className="first-nav-top-row-shadow">
                            <Col className="col-2 float-left">
                                <img src={back} onClick={turnBack}
                                     className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-8">
                                <h6 className="header-title ellipsis-1-v2">{t('language.title')}</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container style={{'paddingTop': '25%'}}></Container>
                <Row>
                    {
                        <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                             alt="service-error-image"></img>
                    }
                </Row>
                <Row>
                    <Col>
                        <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                    </Col>
                </Row>
                <Row style={{'marginTop': '5%'}}>
                    <Col>
                        <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                    </Col>
                </Row>
            </Container> :
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="container nav-top border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingBottom': '20px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col className="col-2 float-left">
                        <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                             height="27px"
                             alt="back"/>
                    </Col>
                    <Col className="col-8">
                        <h6 className="header-title ellipsis-1-v2">{t('language.title')}</h6>
                    </Col>
                    <Col className="col-2">

                    </Col>

                </Row>


                <Container className="top-of-the-language"></Container>
                <Row style={{
                    'paddingLeft': '0',
                    'paddingRight': '0px',
                    //'borderLeft': '1px solid #EEE9E5',
                    //'borderRight': '1px solid #EEE9E5'
                }}>
                    <Col style={{
                        'paddingLeft': '0',
                        'paddingRight': '0px'
                    }}>
                        <div className={classes.rootList}>
                            <List component="nav" aria-label="main mailbox folders">
                                <Divider/>
                                <ListItem className={classes.listItem} button>

                                    <ListItemText onClick={() => handleListItem('it')}
                                                  primary={t('language.italian-radio')}
                                                  className={selectedRadio === 'it' ? classes.listTextSelected : classes.listText}/>
                                    <Radio
                                        checked={selectedRadio === 'it'}
                                        onChange={handleRadio}
                                        value="it"
                                        name="radio-buttons"
                                        inputProps={{'aria-label': 'it'}}
                                        className="justify-content-end"
                                        color="primary"
                                        size="small"
                                    />
                                </ListItem>
                                <Divider/>
                                <ListItem className={classes.listItem} button>
                                    <ListItemText onClick={() => handleListItem('en')}
                                                  primary={t('language.english-radio')}
                                                  className={selectedRadio === 'en' ? classes.listTextSelected : classes.listText}/>
                                    <Radio
                                        checked={selectedRadio === 'en'}
                                        onChange={handleRadio}
                                        value="en"
                                        name="radio-buttons"
                                        inputProps={{'aria-label': 'en'}}
                                        className="justify-content-end"
                                        color="primary"
                                        size="small"
                                    />
                                </ListItem>
                                <Divider/>
                                <ListItem className={classes.listItem} button>
                                    <ListItemText onClick={() => handleListItem('de')}
                                        primary={t('language.german-radio')}
                                        className={selectedRadio === 'de' ? classes.listTextSelected : classes.listText}/>

                                    <Radio
                                        checked={selectedRadio === 'de'}
                                        onChange={handleRadio}
                                        value="de"
                                        name="radio-buttons"
                                        inputProps={{'aria-label': 'de'}}
                                        className="justify-content-end"
                                        color="primary"
                                        size="small"
                                    />
                                </ListItem>
                            </List>
                        </div>
                    </Col>
                </Row>
                {
                    /*
                    <Container className="end-of-the-page" style={{
                    'background': themeContext.color.white,
                    'borderLeft': '1px solid #EEE9E5',
                    'borderRight': '1px solid #EEE9E5',
                    'height': '100%',
                    'position': 'fixed',
                    'right': '0',
                    'left': '0'
                }}></Container>
                     */
                }
            </Container>

    )
}