import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useContext} from "react";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";

export default function CIEAuthorize() {
    const [searchParam, setSearchParam] = useSearchParams();
    const saml_cache_id = searchParam.get("saml_cache_id");
    const authCtx = useContext(AuthContextMiddleware);
    const navigate = useNavigate();

    const url = `${process.env.REACT_APP_BASE_URL}/management/spid/getSamlCache?id=${saml_cache_id}`;
    const getUsersByFiscalCodeUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/login/verify/cf`;

    React.useEffect(
        () => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }).then((response) => {
                return response.json();
            }).then((res) => {
                let data = new FormData();
                data.append("SAMLResponse", res.samlResponse);
                data.append("RelayState", res.relayState);
                data.append("final", true);
                sessionStorage.setItem('SAMLResponse', res.samlResponse)
                sessionStorage.setItem('RelayState', res.samlResponse)
                sessionStorage.setItem('final', true)
                const firstStepUrl = `${process.env.REACT_APP_BASE_URL}/management/spid/authorize`;
                fetch(firstStepUrl, {
                    method: 'POST',
                    body: data,
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        if (response.status === 401) {
                            sessionStorage.setItem('spid-not-enable', true)
                            navigate('/login');
                        } else if (response.status === 400) {
                            return response.text().then(text => {
                                throw new Error(text)
                            })
                        } else if (response.status === 500) {
                            sessionStorage.setItem('spid-not-available', true)
                            navigate('/login');
                        }
                    }
                }).catch((err) => {
                    sessionStorage.setItem('spid-bad-request', err.message);
                    navigate('/login');
                }).then((res) => {
                    if (res) {
                        if (res.isLegacy === true) {
                            postRedirect(`${process.env.REACT_APP_MY_WIDE_URL}`, {
                                accessToken: res.accessToken,
                                expiresIn: res.expiresIn,
                                isLegacy: res.isLegacy,
                                pinCode: res.pinCode,
                                refreshExpiresIn: res.pinCode,
                                refreshToken: res.refreshToken,
                                userId: res.userId
                            })
                        } else {
                            sessionStorage.setItem('spid-or-cie-token', res.token)
                            // find users with fiscal code
                            fetch(`${getUsersByFiscalCodeUrl}`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    cf: res.cf
                                }),
                            }).then((response) => {
                                if (response.ok) {
                                    return response.json();
                                }
                            }).then((res) => {
                                if(res.userList === []){
                                    // sessionStorage.setItem('spid-bad-request', err.message);
                                    if(res.exist === true) {
                                        postRedirect(`${process.env.REACT_APP_MY_WIDE_URL}`, {
                                            accessToken: '',
                                            expiresIn:'',
                                            isLegacy:'',
                                            pinCode: '',
                                            refreshExpiresIn: '',
                                            refreshToken: '',
                                            userId: ''
                                        })
                                    } else {
                                        navigate('/login');
                                    }
                                }else{
                                    sessionStorage.setItem('cie-usersList', JSON.stringify(res.userList))
                                    navigate('/login');
                                }
                            }).catch(err => {
                                sessionStorage.setItem('spid-bad-request', err.message);
                                navigate('/login');
                            });
                        }
                    }
                });
            }).catch((e) => {
                sessionStorage.setItem('spid-not-available', true)
                navigate('/login');
            });
        }, []
    );

    function postRedirect(path, params, method = 'post') {
        const form = document.createElement('form');
        form.method = method;
        form.action = path;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
    }

    return null;
}