import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import React from "react";
import {useTranslation} from 'react-i18next';
import themeContext from "../components/ThemeContext.js";

export default function OfflineError() {
    const {
        t,
        i18n
    } = useTranslation();

    return (
        <Container className="pt-5 full-height" style={{'backgroundColor': themeContext.color.white}}>
            <Container style={{'paddingTop': '10%'}}></Container>
            <Row>
                {
                    <img src={process.env.PUBLIC_URL + '/offline-icon.svg'} className="noMessages"
                         alt="offline-image"></img>
                }
            </Row>
            <Row>
                <Col>
                    <span className="no-messages-text">{t('serviceError.text')}</span>
                </Col>
            </Row>
        </Container>
    )
}