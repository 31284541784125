import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next'
import {Button, Link, Slide} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import documentPlaceholder from '../../images/document-photo-placeholder.svg';
import backdocumentPlaceholder from '../../images/placeholder-back-document.svg';
import Divider from "@material-ui/core/Divider";
import Resizer from "react-image-file-resizer";
import exit from "../../images/exit-icon.svg";
import takingResponsibilityPlaceholder from "../../images/takingResponsibilityPlaceholder.svg";
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import checkboxUnselectedIcon from "../../images/checkbox-unselected.svg";
import checkboxSelectedIcon from "../../images/checkbox-selected.svg";
import {useMediaQuery} from "react-responsive";
import PlaceholderScreen from "../components/PlaceholderScreen.js";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import DialogSmall from "../components/DialogSmall.js";
import CropDialog from "../components/CropDialog.js";
import themeContext from "../components/ThemeContext.js";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    backDrop: {
        backdropFilter: "blur(16px)",
        backgroundColor: themeContext.color.shadowBlack,
        opacity: 1
    },
    dialogTitle: {
        'textAlign': 'center',
        'paddingTop': '10px',
        'paddingBottom': '2px',
    },
    dialogTitle2: {
        'textAlign': 'center',
        'lineHeight': '1.3',
        'padding': '16px 24px',
        'fontSize': '15px',
        'fontWeight': '500'
    },
    rootList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: "0 0 0 0",
    },
    listItem: {
        color: themeContext.color.neutral400,
        paddingTop: '18px',
        paddingBottom: '18px',
    },
    listItemName: {
        paddingTop: '18px',
        paddingBottom: '0px',
    },
    listItemIcon: {
        marginLeft: '18px',
        marginRight: '-10px',
    },
    listTextSelected: {
        color: themeContext.color.neutral600,
        fontSize: '16px !important'
    },
    listText: {
        fontSize: '16px !important'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    primary: {
        color: themeContext.color.main,
    },
    peopleName: {
        textTransform: "uppercase",
        textAlign: "left",
        font: "normal normal bold 14px/29px Inter",
        letterSpacing: "0px",
        color: themeContext.color.neutral400,
        opacity: "1",
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    avatar: {
        margin: '0 auto',
        width: '74px',
        height: '74px',
    },
    personAvatar: {
        width: "30px",
        height: "30px",
        marginBottom: "5px",
        marginLeft: "5px",
        marginRight: "10px"
    },
    listTextRight: {
        textAlign: "right",
        font: "normal normal bold 12px/22px Inter",
        letterSpacing: "0px",
        color: themeContext.color.neutral400,
        opacity: "1",
        justifyContent: "end"
    },
}));

export default function UploadDocumentToVerify() {
    const getGroupDocumentByPersonIdUrl = `${process.env.REACT_APP_BASE_URL}/media/personal/group/person`;
    const personInfoUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/person`;
    const getDocumentsForTakingResponsibilityUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/documentForSign`;
    const getPolicyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies`;
    const titleDocumentUrl = `${process.env.REACT_APP_BASE_URL}/media/policy/title`;
    const vidiValidateUrl = `https://vidi.empireos.eu/api/v1/core/validate`;
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const classes = useStyles();
    const {t} = useTranslation();
    const params = useParams();
    const personId = params.personId;
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const documentId = query.get('documentId');
    const policyId = query.get('policyId');
    const titleId = query.get('titleId');
    const isCie = query.get('isCie');
    const skipTakingResponsibility = query.get('skipTakingResponsibility');
    const [openUploadDocumentDialog, setOpenUploadDocumentDialog] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingDialog, setIsLoadingDialog] = useState(false);
    const [isDocument, setIsDocument] = useState(false);
    const [serviceError, setServiceError] = useState(false);
    const [isPersonalDocuments, setIsPersonalDocuments] = useState(false);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [cropper, setCropper] = useState(null);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [openExitDialog, setOpenExitDialog] = useState(false);
    const cropperRef = useRef(null);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    const authCtx = useContext(AuthContextMiddleware);
    const [takingResponsibility, setTakingResponsibility] = useState(!(window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')));
    const [person, setPerson] = useState([]);
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox3, setCheckbox3] = useState(false);
    const [checkbox4, setCheckbox4] = useState(false);
    const [takingResponsibilityIdentifyString, setTakingResponsibilityIdentifyString] = useState("")
    const [documents, setDocuments] = useState([])
    const [policyDocument, setPolicyDocument] = useState([])
    const width_min = useMediaQuery({
        query: '(max-width: 522px)'
    })
    const [openFallbackDialog, setOpenFallbackDialog] = useState(false);
    const [openSizeExceededDialog, setOpenSizeExceededDialog] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            getPerson();
            getDocumentPolicy();
            //getGroupDocumentByPersonId();
            if (skipTakingResponsibility === 'true') {
                setTakingResponsibility(false)
            }
            if (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')) {
                if (authCtx.backReqImageBase64 && authCtx.backReqImage) {
                    //console.log('immagini presenti')
                } else {
                    //console.log('immagini non presenti')
                    setOpenFallbackDialog(true)
                }
            }
        }
    }, []);

    const getGroupDocumentByPersonId = () => {
        setIsLoadingDialog(true)
        fetch(`${getGroupDocumentByPersonIdUrl}/${personId}`, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(res => {
                    throw new Error(res)
                })
            }
        }).then((data) => {
            setIsPersonalDocuments(true);
            setIsLoadingDialog(false)
        }).catch((e) => {
            if (e.status !== 500) {
                setIsPersonalDocuments(false);
                setIsLoadingDialog(false)
            } else {
                setServiceError(true)
            }
        });
    }

    const getDocumentPolicy = () => {
        fetch(`${getPolicyUrl}/${policyId}`, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((res) => {
            if (res) {
                return res.json();
            }
        }).then((res) => {
            if (res !== null) {
                getTitleDocument(titleId)
            }
        }).catch(() => {
            setServiceError(true)
        })
    }

    const getTitleDocument = (titleId) => {
        fetch(titleDocumentUrl, {
            method: 'POST', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({titleId: titleId})
        }).then((res) => {
            if (res) {
                return res.json();
            }
        }).then((res) => {
            if (res !== null) {
                res.map((value) => {
                    if (value.type === 'copia_da_restituire') {
                        setPolicyDocument(current => [...current, value])
                    }
                })
            }
        }).catch(() => {
            setServiceError(true)
        });
    }

    function getPerson() {
        setIsLoadingData(true)
        fetch(`${personInfoUrl}/${personId}`, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.status !== -1) {
                setPerson(data);
                if (data.firstName === null && data.lastName === null) {
                    setTakingResponsibilityIdentifyString(`${t('uploadDocumentToVerify.taking-responsibility-identity-1')} ${data.name} ${t('uploadDocumentToVerify.taking-responsibility-identity-2')}`)
                } else {
                    setTakingResponsibilityIdentifyString(`${t('uploadDocumentToVerify.taking-responsibility-identity-1')} ${data.firstName} ${data.lastName} ${t('uploadDocumentToVerify.taking-responsibility-identity-2')}`)
                }
            }
            getDocumentsForTakingResponsibility();
        }).catch(() => {
            setServiceError(true)
        })
    }

    const getDocumentsForTakingResponsibility = () => {
        fetch(getDocumentsForTakingResponsibilityUrl, {
            method: 'GET', headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'},
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setDocuments(data);
            setIsLoadingData(false)
        }).catch(() => {
            setServiceError(true)
        })
    }

    function reload() {
        window.location.reload();
    }

    const turnBack = () => {
        if (!window.location.href.includes('uploadFront')) {
            if (takingResponsibility === false) {
                setTakingResponsibility(true)
            } else {
                return navigate('/signing');
            }
        } else {
            return navigate('/signing');
        }
    }

    const handleCloseUploadDocumentDialog = () => {
        setOpenUploadDocumentDialog(false)
    }

    const handleInputImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    const navigateToDocumentSelection = () => {
        return navigate(`/signing/${personId}/upload/personalDocuments?documentId=${documentId}`)
    }

    const navigateToPersonalDocuments = () => {
        return navigate(`/signing/${personId}/upload/personalDocuments?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}`);
    }

    const handleOnPendingImage = async (e) => {
        try {
            e.preventDefault();
            let file = e.target.files[0];
            if (file && file.size > 20000000) {
                throw t('Maximum upload size exceeded');
            }
            const imageResized = await resizeFile(file)
            setOnPendingImage(imageResized);
            setOpenCropDialog(true)
        } catch (err) {
            console.log(err)
            setOpenSizeExceededDialog(true)
        }
    };

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCroppedCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            if (window.location.href.includes('uploadBack') && !window.location.href.includes('uploadFront')) {
                //chiamata OCR
                setOpenCropDialog(false)
                setIsLoadingData(true)
                vidiValidate(base64_string)
            } else {
                authCtx.storeReqImage(cropImage)
                authCtx.storeReqImageBase64(base64_string)
                if (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront')) {
                    return navigate(`/signing/${personId}/uploadBack/uploadFront/identify?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=${isCie}`)
                } else {
                    return navigate(`/signing/${personId}/upload/identify?documentId=${documentId}`)
                }
            }
        }
    }

    const base64toBlob = (b64Data, contentType = 'image/jpeg', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {type: contentType});
    }

    const vidiValidate = (document) => {
        authCtx.storeBackReqImageBase64(document)
        authCtx.storeBackReqImage(base64toBlob(document))
        const formData = new FormData();
        sessionStorage.setItem('ocr_result', JSON.stringify({'codice_fiscale': 'ND'}));
        formData.append("image", base64toBlob(document))
        fetch(vidiValidateUrl, {
            method: 'POST',
            headers: {
                'api-token': process.env.REACT_APP_VIDI_AUTH_TOKEN,
            },
            body: formData,
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                console.log('error')
                setIsLoadingData(false)
                return navigate(`/signing/${personId}/uploadBack/uploadFront?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=false`)
            }
        }).then((res) => {
            console.log(res.status)
            setIsLoadingData(false)
            //todo assegnare response in sessionstorage
            //sessionStorage.setItem('ocr_result', JSON.stringify(json));

            //if (res.text === 'ID found') {
            return navigate(`/signing/${personId}/uploadBack/uploadFront?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=true`)
        }).catch((e) => {
            setIsLoadingData(false)
            return navigate(`/signing/${personId}/uploadBack/uploadFront?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&isCie=false`)
        });
    }

    const handleClickCheckboxes = (index) => {
        switch (index) {
            case 0:
                setCheckbox1(current => !current)
                break;
            case 2:
                setCheckbox2(current => !current)
                break;
        }
    }

    return (
        <Fragment>
            {
                serviceError &&
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container border-bottom" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Col>
                            <Row className="first-nav-top-row-shadow">
                                <Col className="col-2 float-left">
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px"
                                         alt="back"/>
                                </Col>
                                <Col className="col-8">
                                    <h6 className="header-title ellipsis-1-v2">{t('personalDocuments.title')}</h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Container style={{'paddingTop': '25%'}}></Container>
                    <Row>
                        {
                            <img src={process.env.PUBLIC_URL + '/service_error-icon.svg'} className="noMessages"
                                 alt="service-error-image"></img>
                        }
                    </Row>
                    <Row>
                        <Col>
                            <span className="no-messages-text">{t('serviceError.service-error-text')}</span>
                        </Col>
                    </Row>
                    <Row style={{'marginTop': '5%'}}>
                        <Col>
                            <a className="reload-btn" onClick={reload}>{t('serviceError.reload')}</a>
                        </Col>
                    </Row>
                </Container>
            }
            {
                !serviceError && takingResponsibility &&
                <Container className="mt-0 full-height linear-gradient">
                    <div style={{'opacity': '1'}}>
                        <Row className="nav-top container border-bottom"
                             style={{
                                 'backgroundColor': themeContext.color.white,
                                 'paddingTop': '19.5px',
                                 'paddingBottom': '20px',
                                 'paddingLeft': '0',
                                 'paddingRight': '0',
                             }}>
                            <Col className="col-1 float-left">
                            </Col>
                            <Col className="col-10">
                                <h6 className="header-title ellipsis-1-v2">{t('uploadDocumentToVerify.taking-responsibility-title')}</h6>
                            </Col>
                            <Col className="col-1">
                                <img src={exit} onClick={() => setOpenExitDialog(true)}
                                     className="nav-top-logo float-right cursor-pointer" alt="exit-icon" height="27px"/>
                            </Col>
                        </Row>
                        <Container className="top-of-the-preferences"></Container>

                        <Row className="pr-0 pl-0">
                            <Col className="col-list">
                                {
                                    isLoadingData &&
                                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                                }
                                {
                                    !isLoadingData &&
                                    <Container>
                                        <Row style={{'marginTop': '43.5px'}}>
                                            <Col className="pl-5 pr-5">
                                                {
                                                    ((person.firstName === null || person.firstName === "") && (person.lastName === null || person.lastName === "")) ?
                                                        <span style={{
                                                            'fontSize': '16px',
                                                            'color': themeContext.color.neutral600,
                                                            'fontWeight': 'bold'
                                                        }}>{person.name},</span>
                                                        :
                                                        <span style={{
                                                            'fontSize': '16px',
                                                            'color': themeContext.color.neutral600,
                                                            'fontWeight': 'bold'
                                                        }}>{person.firstName} {person.lastName},</span>
                                                }
                                                <br/>
                                                <span style={{
                                                    'fontSize': '16px',
                                                    'color': themeContext.color.neutral600,
                                                    'fontWeight': 'bold'
                                                }}>{t('uploadDocumentToVerify.taking-responsibility-subtitle')}</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-0">
                                                <img src={takingResponsibilityPlaceholder}
                                                     alt="taking-responsibility-placeholder" width="100%"
                                                     style={{'maxHeight': '340px'}}/>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col style={width_min ? {} : {marginLeft: '30px'}}>
                                                <span style={{
                                                    fontWeight: '700',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                    color: themeContext.color.neutral400,
                                                    fontSize: '12px'
                                                }}>
                                                    {t('uploadDocumentToVerify.taking-responsibility-info')}
                                                </span>
                                            </Col>
                                        </Row>
                                        {
                                            documents.map((document, index) => (
                                                index !== 1 &&
                                                <Row key={index}>
                                                    <Col className={width_min ? "col-1" : "col-1 pr-0"}
                                                         style={{marginTop: '13px'}}>
                                                        {
                                                            index === 0 && <img
                                                                onClick={() => handleClickCheckboxes(index)}
                                                                src={checkbox1 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                                                alt="checkbox" height="20px"/>
                                                        }
                                                        {
                                                            index === 2 && <img
                                                                onClick={() => handleClickCheckboxes(index)}
                                                                src={checkbox2 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                                                alt="checkbox" height="20px"/>
                                                        }
                                                    </Col>
                                                    {
                                                        index !== 1 &&
                                                        <Col className={width_min ? "col-11" : "col-11 pl-0"}>
                                                            <Link href={document.url} target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  className="mt-3 float-left login-subtitle cursor-pointer"
                                                                  color="primary"
                                                                  style={{
                                                                      'textDecoration': 'none',
                                                                      'color': themeContext.color.main
                                                                  }}>
                                                                {document.name}
                                                            </Link>
                                                            {index === 0 && <Fragment>
                                                                <span
                                                                    className="mt-3 float-left mr-1 ml-1 font-weight-bold"
                                                                    style={{
                                                                        textDecoration: "none",
                                                                        color: themeContext.color.neutral600,
                                                                        textAlign: 'left',
                                                                        fontSize: '14px'
                                                                    }}>
                                                                    {t('uploadDocumentToVerify.and')}
                                                                </span>
                                                                {
                                                                    width_min ?
                                                                        <Link href={documents[1].url} target="_blank"
                                                                              rel="noopener noreferrer"
                                                                              className="float-left login-subtitle cursor-pointer"
                                                                              color="primary"
                                                                              style={{
                                                                                  'textDecoration': 'none',
                                                                                  'color': themeContext.color.main
                                                                              }}>
                                                                            {documents[1].name}
                                                                        </Link>
                                                                        :
                                                                        <Link href={documents[1].url} target="_blank"
                                                                              rel="noopener noreferrer"
                                                                              className="mt-3 float-left login-subtitle cursor-pointer"
                                                                              color="primary"
                                                                              style={{
                                                                                  'textDecoration': 'none',
                                                                                  'color': themeContext.color.main
                                                                              }}>
                                                                            {documents[1].name}
                                                                        </Link>
                                                                }
                                                            </Fragment>
                                                            }
                                                        </Col>
                                                    }
                                                </Row>
                                            ))
                                        }
                                        <Row className="mb-2">
                                            <Col className={width_min ? "col-1" : "col-1 pr-0"}
                                                 style={{marginTop: '13px'}}>
                                                <img
                                                    onClick={() => setCheckbox2(current => !current)}
                                                    src={checkbox2 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                                    alt="checkbox-unselected" height="20px"/>
                                            </Col>
                                            <Col className={width_min ? "col-11" : "col-11 pl-0"}>
                                                {
                                                    policyDocument.map((policyDoc, index) => (
                                                        index === 0 &&
                                                        <Link key={index} href={policyDoc.url} target="_blank"
                                                              rel="noopener noreferrer"
                                                              className="mt-3 float-left login-subtitle cursor-pointer"
                                                              color="primary"
                                                              style={{
                                                                  'textDecoration': 'none',
                                                                  'color': themeContext.color.main
                                                              }}>
                                                            {t('uploadDocumentToVerify.taking-responsibility-checkbox-2')}
                                                        </Link>
                                                    ))
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col className={width_min ? "col-1" : "col-1 pr-0"}
                                                 style={{marginTop: '13px'}}>
                                                <img
                                                    onClick={() => setCheckbox3(current => !current)}
                                                    src={checkbox3 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                                    alt="checkbox-unselected" height="20px"/>
                                            </Col>
                                            <Col className={width_min ? "col-11" : "col-11 pl-0"}>
                                                <h6 className="mt-3 float-left font-weight-bold" style={{
                                                    textDecoration: "none",
                                                    color: themeContext.color.neutral600,
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {t('uploadDocumentToVerify.taking-responsibility-checkbox-3')}
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col className={width_min ? "col-1" : "col-1 pr-0"}
                                                 style={{marginTop: '13px'}}>
                                                <img
                                                    onClick={() => setCheckbox4(current => !current)}
                                                    src={checkbox4 ? checkboxSelectedIcon : checkboxUnselectedIcon}
                                                    alt="checkbox-unselected" height="20px"/>
                                            </Col>
                                            <Col className={width_min ? "col-11" : "col-11 pl-0"}>
                                                <h6 className="mt-3 float-left font-weight-bold" style={{
                                                    textDecoration: "none",
                                                    color: themeContext.color.neutral600,
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {t('uploadDocumentToVerify.taking-responsibility-checkbox-4')}
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingBottom: '30px'}}>
                                            <Col>
                                                <Button
                                                    className={checkbox1 && checkbox2 && checkbox3 && checkbox4 ? "btn-custom text-transform-none" : "btn-custom-disable text-transform-none"}
                                                    variant="outlined"
                                                    size="large"
                                                    style={{'color': themeContext.color.white}} type="submit"
                                                    onClick={() => {
                                                        if (checkbox1 && checkbox2 && checkbox3 && checkbox4) {
                                                            setTakingResponsibility(false)
                                                        }
                                                    }}
                                                >{t('uploadDocumentToVerify.taking-responsibility-button')}</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                }
                            </Col>
                        </Row>
                    </div>
                </Container>
            }
            {
                !serviceError && !takingResponsibility &&
                <Container className="mt-0 full-height linear-gradient">
                    <div style={{'opacity': '1'}}>
                        <Row className="nav-top container border-bottom"
                             style={{
                                 'backgroundColor': themeContext.color.white,
                                 'paddingTop': '19.5px',
                                 'paddingBottom': '20px',
                                 'paddingLeft': '0',
                                 'paddingRight': '0',
                             }}>
                            <Col className="col-1 float-left">
                                <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                                     height="27px"
                                     alt="back"/>
                            </Col>
                            <Col className="col-10">
                                <h6 className="header-title ellipsis-1-v2">{t('uploadDocumentToVerify.title')}</h6>
                            </Col>
                            <Col className="col-1">
                                <img src={exit} onClick={() => setOpenExitDialog(true)}
                                     className="nav-top-logo float-right cursor-pointer" alt="exit-icon" height="27px"/>
                            </Col>
                        </Row>
                        <Container className="top-of-the-preferences"></Container>
                        {
                            (window.location.href.includes('uploadBack') && !window.location.href.includes('uploadFront')) ?
                                <PlaceholderScreen isLoadingData={isLoadingData}
                                                   title={t('uploadDocumentToVerify.title-back')}
                                                   description={t('uploadDocumentToVerify.description-back')}
                                                   placeholder={backdocumentPlaceholder}
                                                   buttonText={t('uploadDocumentToVerify.upload-button')}
                                                   buttonAction={handleInputImage}/> :
                                (window.location.href.includes('uploadBack') && window.location.href.includes('uploadFront') ?
                                        <PlaceholderScreen isLoadingData={isLoadingData}
                                                           title={t('uploadDocumentToVerify.subtitle')}
                                                           description={t('uploadDocumentToVerify.description-front')}
                                                           placeholder={documentPlaceholder}
                                                           buttonText={t('uploadDocumentToVerify.upload-button')}
                                                           buttonAction={handleInputImage}/> :
                                        <PlaceholderScreen isLoadingData={isLoadingData}
                                                           title={t('uploadDocumentToVerify.subtitle')}
                                                           description={t('uploadDocumentToVerify.description-front')}
                                                           placeholder={documentPlaceholder}
                                                           buttonText={t('uploadDocumentToVerify.button')}
                                                           buttonAction={navigateToPersonalDocuments}/>
                                )

                        }
                    </div>
                </Container>
            }
            <Dialog
                BackdropProps={{
                    classes: {
                        root: classes.backDrop,
                    },
                }}
                open={openUploadDocumentDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseUploadDocumentDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        'position': 'absolute',
                        'bottom': '0',
                        'left': 'auto',
                        'right': 'auto',
                        'width': '95%',
                        'borderRadius': '10px',
                        'color': themeContext.color.neutral600
                    },
                }}>
                        <span className={classes.dialogTitle2}>
                        {t('uploadDocumentToVerify.dialog-title')}
                        </span>
                <Divider/>
                {
                    isLoadingDialog &&
                    <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                }
                {
                    !isLoadingDialog &&
                    <Fragment>
                        {isPersonalDocuments &&
                            <Fragment>
                                <DialogActions style={{'textTransform': 'none'}}>
                                    <Button
                                        onClick={navigateToDocumentSelection}
                                        style={{
                                            'color': themeContext.color.main,
                                            'textTransform': 'none'
                                        }}
                                        fullWidth>
                                        {t('uploadDocumentToVerify.personal-document')}
                                    </Button>
                                </DialogActions>
                                <Divider/>
                            </Fragment>
                        }
                        <DialogActions>
                            <Button
                                id="attachImage"
                                style={{
                                    'color': themeContext.color.main,
                                    'textTransform': 'none'
                                }}
                                fullWidth
                                onClick={handleInputImage}
                            >
                                {t('uploadDocumentToVerify.upload')}
                            </Button>
                            <input hidden id="image-file" type="file" onChange={handleOnPendingImage}
                                   accept="image/*"/>
                        </DialogActions>
                        <Divider/>
                        <DialogActions>
                            <Button onClick={handleCloseUploadDocumentDialog}
                                    style={{
                                        'color': themeContext.color.neutral400,
                                        'textTransform': 'none'
                                    }}
                                    fullWidth>
                                {t('uploadDocumentToVerify.refuse-button')}
                            </Button>
                        </DialogActions>
                    </Fragment>
                }
            </Dialog>
            <CropDialog open={openCropDialog}
                        handleAttach={attachCroppedImage}
                        handleClose={() => {
                            setOpenCropDialog(false)
                            setIsLoadingImg(false)
                        }}
                        isLoading={isLoadingImg}
                        onPendingImage={onPendingImage}
                        cropperRef={cropperRef}
                        setCropper={(data) => setCropper(data)}/>
            <DialogDoubleAction
                open={openExitDialog}
                handleClose={() => setOpenExitDialog(false)}
                title={t('dialog.interruption-title')}
                description={t('dialog.interruption-description')}
                buttonYesText={t('dialog.button-yes')}
                handleYes={() => {
                    navigate(`/signing`);
                }}
                buttonNoText={t('dialog.button-no')}
                handleNo={() => setOpenExitDialog(false)}/>
            <DialogSmall buttonText={t('uploadDocumentToVerify.fallback-dialog-button')}
                         title={t('uploadDocumentToVerify.fallback-dialog-title')}
                         description={t('uploadDocumentToVerify.fallback-dialog-description')}
                         handleClose={() => {
                             if (window.location.href.includes('uploadBack')) {
                                 navigate(`/signing/${personId}/uploadBack?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&skipTakingResponsibility=true`)
                             } else {
                                 navigate(`/signing/${personId}/upload?documentId=${documentId}&policyId=${policyId}&titleId=${titleId}&skipTakingResponsibility=true`)
                             }
                             setOpenFallbackDialog(false)
                         }}
                         open={openFallbackDialog}/>
            <DialogSmall open={openSizeExceededDialog} title={t('Maximum upload size exceeded')}
                         buttonText={t('dialog.button-ok')}
                         handleClose={() => {
                             setOpenSizeExceededDialog(false)
                         }} description={t('The maximum image upload size is 20mb')}/>
        </Fragment>
    )
}