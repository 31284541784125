import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Link} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next'
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import pdfIcon from "../../images/file-png-icon.png";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import takingResponsibilityPlaceholder from "../../images/takingResponsibilityPlaceholder.svg";
import checkboxSelectedIcon from "../../images/checkbox-selected.svg";
import checkboxUnselectedIcon from "../../images/checkbox-unselected.svg";
import Dialog from "@material-ui/core/Dialog";
import {useMediaQuery} from "react-responsive";
import goTo from "../../images/goto-icon.svg";
import themeContext from "../components/ThemeContext.js";


const useStyles = makeStyles((theme) => ({
    backDrop: {
        backdropFilter: "blur(16px)",
        backgroundColor: themeContext.color.shadowBlack,
        opacity: 1
    },
    rootList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: "0 0 0 0",
    },
    list: {
        paddingTop: '0',
        paddingBottom: '30px',
    },
    listItem: {
        color: themeContext.color.neutral400,
        padding: '16px 0 0 0',
        fontSize: '18px'
    },
    listFullItem: {
        color: themeContext.color.neutral400,
        paddingTop: '16px',
        paddingBottom: '16px',
        fontSize: '18px'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    primary: {
        color: themeContext.color.main,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
}));

export default function MessagePreferences() {
    const root = 'messagePreferences';
    const navigate = useNavigate();
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (navigator.onLine) {

        }
    }, []);


    function turnBack() {
        return navigate(-1);
    }

    return (
        <Fragment>
            <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                <Row className="nav-top container border-bottom" style={{
                    'backgroundColor': themeContext.color.white,
                    'paddingTop': '19.5px',
                    'paddingBottom': '20px',
                    'paddingLeft': '0',
                    'paddingRight': '0',
                }}>
                    <Col className="col-2 float-left">
                        <img src={back} onClick={turnBack} className="nav-top-logo float-left cursor-pointer"
                             height="27px"
                             alt="back"/>
                    </Col>
                    <Col className="col-8">
                        <h6 className="header-title ellipsis-1-v2">{t(`${root}.title`)}</h6>
                    </Col>
                    <Col className="col-2">
                    </Col>
                </Row>
                <Container className="top-of-the-preferences"></Container>
                <Row>
                    <Col className="col-list">
                        <div>
                            <List style={{'paddingTop': '0px'}}>
                                <Fragment>
                                    <ListItem className={classes.listFullItem}>
                                        <ListItemText id="switch-list-label-all-messages"
                                                      primary={t(`${root}.list-all-messages`)}/>
                                        <ListItemSecondaryAction className={classes.ListItem}>
                                            <label className="form-switch" style={{'opacity': '0.5'}}>
                                                <input
                                                    id="webauthn-switch"
                                                    type="checkbox"
                                                    /*
                                                    onChange={handleBiometrics}
                                                    checked={isBiometricsChecked}
                                                    */
                                                    disabled
                                                    defaultChecked
                                                />
                                                <i></i>
                                            </label>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {process.env.REACT_APP_ENV !== "PROD" &&
                                        <Fragment>
                                            <Divider/>
                                            <ListItem className={classes.listFullItem}>
                                                <ListItemText id="switch-list-label-marketing-messages"
                                                              primary={t(`${root}.list-marketing-messages`)}/>
                                                <ListItemSecondaryAction className={classes.ListItem}>
                                                    <label className="form-switch" style={{'opacity': '0.5'}}>
                                                        <input
                                                            id="webauthn-switch"
                                                            type="checkbox"
                                                            /*
                                                            onChange={handleBiometrics}
                                                            checked={isBiometricsChecked}
                                                            */
                                                            disabled
                                                            checked={false}
                                                        />
                                                        <i></i>
                                                    </label>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </Fragment>}
                                </Fragment>
                            </List>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
